import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClipLoader } from "react-spinners";
import AppleIcon from "../../../assets/icons/apple-icon.svg";
import FacebookIcon from "../../../assets/icons/fb-icon.svg";
import GoogleIcon from "../../../assets/icons/google-icon.svg";
export var AuthenticateButtonType;
(function (AuthenticateButtonType) {
    AuthenticateButtonType["GOOGLE"] = "Google";
    AuthenticateButtonType["FACEBOOK"] = "Facebook";
    AuthenticateButtonType["APPLE"] = "Apple";
})(AuthenticateButtonType || (AuthenticateButtonType = {}));
export default function AuthenticateButton({ type, onClick, showSpinner, }) {
    return (_jsxs("div", { className: "min-h-[48px] py-2 px-[16px] sm:pr-0 sm:py-0 cursor-pointer flex-grow flex flex-row items-center gap-[12px] sm:gap-[17px] sm:pl-[20px] rounded-full border-[#D1D5DB] border", onClick: onClick, children: [!showSpinner && type == AuthenticateButtonType.GOOGLE && (_jsx(GoogleIcon, { className: "inline-block overflow-visible" })), !showSpinner && type == AuthenticateButtonType.FACEBOOK && (_jsx(FacebookIcon, { className: "inline-block overflow-visible" })), !showSpinner && type == AuthenticateButtonType.APPLE && (_jsx(AppleIcon, { className: "inline-block overflow-visible" })), showSpinner && _jsx(ClipLoader, { color: "black", size: "24px", speedMultiplier: 0.75 }), _jsxs("span", { className: "font-bold text-[20px] text-black", children: ["Continue with ", type] })] }));
}
