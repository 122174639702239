import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tick from "../../../assets/icons/small-tick.svg";
export default function Checkbox({ rightChild, onClick, isSelected, square, }) {
    if (square) {
        return (_jsxs("div", { className: "flex flex-row gap-[9px] items-center cursor-pointer", onClick: onClick, children: [_jsx("div", { className: "flex flex-row justify-center items-center rounded-[4px] w-[16px] aspect-square " +
                        (isSelected ? "bg-black" : "border-[1.5px] border-[#D1D5DB]"), children: _jsx(Tick, { className: isSelected ? "" : "hidden" }) }), rightChild] }));
    }
    return (_jsxs("div", { className: "flex flex-row gap-[9px] items-center cursor-pointer", onClick: onClick, children: [_jsx("div", { className: "mt-[1px] rounded-full " +
                    (isSelected
                        ? "w-[16px] h-[16px] border-black border-4"
                        : "border-2 w-[16px] h-[16px] border-[#D1D5DB]") }), rightChild] }));
}
