import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import Button from "../../global/Button";
import ForChefsLogo from "../../global/ForChefsLogo";
import { startConfetti, stopConfetti } from "../../../lib/js/confetti";
export default function CongratulationsOnDish({ name }) {
    useEffect(() => {
        setTimeout(() => {
            startConfetti();
        }, 1000);
        return () => {
            stopConfetti();
        };
    }, []);
    return (_jsxs("div", { className: "bg-black relative items-center py-16 text-white flex flex-col justify-center text-center px-8 min-h-screen min-w-screen", children: [_jsx("div", { className: "absolute top-12", children: _jsx(ForChefsLogo, { white: true }) }), _jsxs("div", { className: "flex items-center flex-col", children: [_jsxs("h1", { className: "text-2xl mb-3 md:mb-8 md:text-6xl font-bold", children: ["Congratulations, ", name] }), _jsxs("span", { className: "text-sm md:text-3xl md:leading-10", children: ["You\u2019ve listed your first dish! Now let\u2019s list the rest of your menu.", _jsx("br", { className: "hidden md:block" }), "We\u2019re excited for you to experience the magic of cooking on Neighbors."] }), _jsx("div", { className: "w-44 hidden md:block mt-12", children: _jsx(Button, { to: "/vendor/menu", children: "Continue" }) })] }), _jsx("div", { className: "w-44 absolute md:hidden bottom-24", children: _jsx(Button, { to: "/vendor/menu", children: "Continue" }) })] }));
}
