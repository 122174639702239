import { consumeToken } from "../lib/util/session";
export function parseAllMyDishes(res) {
    const error = res.error;
    const dishes = res.data?.allMyDishes?.dishes;
    return { error: error, dishes: dishes };
}
export function parseServiceableDishes(res) {
    const error = res.error;
    const dishes = res.data?.serviceableDishes?.dishes;
    return { error: error, dishes: dishes };
}
export function parseWeeklyDeliveryDay(res) {
    const error = res.error;
    const day = res.data?.weeklyDeliveryDay?.day;
    return { error: error, deliveryDay: day };
}
export function parseRegisterUser(res) {
    const error = res.error;
    const data = res.data?.registerUser;
    const user = data?.user;
    if (!error)
        consumeToken(data?.auth?.accessToken);
    return { error: error, user: user };
}
export function parseAuthenticateUser(res) {
    const error = res.error;
    const data = res.data?.authenticateUser;
    const user = data?.user;
    if (!error)
        consumeToken(data?.auth?.accessToken);
    return { error: error, user: user };
}
export function parseCurrentMerchant(res) {
    const error = res.error;
    const merchant = res.data?.currentMerchant?.merchant;
    return { error: error, merchant: merchant };
}
export function parseUserSubscriptionStatus(res) {
    const error = res.error;
    const subscribed = res.data?.userSubscriptionStatus?.subscribed;
    const canceled = res.data?.userSubscriptionStatus?.canceled;
    return { error: error, subscribed, canceled, loading: res.loading };
}
export function parseCurrentUser(res) {
    const error = res.error;
    const user = res.data?.currentUser?.user;
    return { error: error, user: user, loading: res.loading };
}
export function parseDish(res) {
    const error = res.error;
    const dish = res.data?.dish?.dish;
    return { error: error, dish: dish };
}
export function parseOnboardedMerchant(res) {
    const error = res.error;
    const merchant = res.data?.completeMerchantOnboarding?.merchant;
    return { error: error, merchant: merchant };
}
export function parseCatalogFiltersQuery(res) {
    const error = res.error;
    const filters = res.data?.catalogFilters?.filters;
    return { error: error, filters: filters };
}
export function parseCuisinesQuery(res) {
    const error = res.error;
    const cuisines = res.data?.cuisines?.cuisines;
    return { error: error, cuisines: cuisines };
}
export function parseCatalogQuery(res) {
    const error = res.error;
    const dishes = res.data?.catalog?.dishes ?? [];
    const merchants = res.data?.catalog?.merchants ?? [];
    return {
        error: error,
        dishes: dishes,
        merchants: merchants,
        loaded: res.data != null && res.data != undefined,
    };
}
export function parseFilteredCatalogQuery(res) {
    const error = res.error;
    const dishes = res.data?.catalog?.dishes ?? [];
    return {
        error: error,
        dishes: dishes,
        loaded: res.data != null && res.data != undefined,
    };
}
export function parseRecommendationCatalogQuery(res) {
    const error = res.error;
    const dishes = res.data?.recommendationCatalog?.dishes ?? [];
    const merchants = res.data?.recommendationCatalog?.merchants ?? [];
    return {
        error: error,
        dishes: dishes,
        merchants: merchants,
        loaded: res.data != null && res.data != undefined,
    };
}
export function parseCatalogVendorsQuery(res) {
    const error = res.error;
    const merchants = res.data?.catalogVendors?.merchants ?? [];
    return {
        error: error,
        merchants: merchants,
        loaded: res.data != null && res.data != undefined,
    };
}
export function parseMerchant(res) {
    const error = res.error;
    const merchant = res.data?.merchant?.merchant;
    return { error: error, merchant: merchant };
}
export function parseMerchantCatalogDishes(res) {
    const error = res.error;
    const dishes = res.data?.merchantDishCatalog?.dishes ?? [];
    return { error: error, dishes: dishes, loading: res.loading };
}
export function parsePlaceOrder(res) {
    const error = res.error;
    const orderId = res.data?.placeOrder?.order?.id;
    return { error: error, orderId: orderId, loading: res.loading };
}
export function parseOrderQuery(res) {
    const error = res.error;
    const order = res.data?.order?.order;
    const refetch = res.refetch;
    return { error: error, order: order, loading: res.loading, refetch: refetch };
}
export function parseExploreMealsQuery(res) {
    const error = res.error;
    const dishes = res.data?.exploreMeals?.dishes ?? [];
    return {
        error: error,
        dishes: dishes,
        loaded: res.data != null && res.data != undefined,
    };
}
export function parseApplyAsMerchantMutation(res) {
    const error = res.error;
    const data = res.data?.applyAsMerchant;
    const user = data?.user;
    return { error: error, user: user };
}
export function parseOrdersSummaryQuery(res) {
    const error = res.error;
    return {
        error,
        ordersSummary: res.data?.ordersSummary,
        orders: res.data?.ordersSummary?.orders ?? [],
    };
}
export function parseMerchantOrderQuery(res) {
    const error = res.error;
    const merchantOrder = res.data?.merchantOrder?.merchantOrder;
    const refetch = res.refetch;
    return { error: error, merchantOrder: merchantOrder, loading: res.loading, refetch: refetch };
}
export function parseCheckCoupon(res) {
    const error = res.error;
    const coupon = res.data?.checkCoupon;
    return { error: error, coupon: coupon };
}
