var maxParticleCount = 150; //set max confetti count
var particleSpeed = 2; //set the particle animation speed
var startConfetti; //call to start confetti animation
var stopConfetti; //call to stop adding confetti
var toggleConfetti; //call to start or stop the confetti animation depending on whether it's already running
var removeConfetti; //call to stop the confetti animation and remove all confetti immediately

(function () {
  startConfetti = startConfettiInner;
  stopConfetti = stopConfettiInner;
  toggleConfetti = toggleConfettiInner;
  removeConfetti = removeConfettiInner;
  var colors = [
    "#7B68EE",
    "#8B4513",
    "#32CD32",
    "#6495ED",
    "#ADD8E6",
    "#FFF0F5",
    "#6A5ACD",
    "#FFE4C4",
    "#FFA07A",
    "#ADFF2F",
    "#A9A9A9",
    "#FFA07A",
    "#FFE4B5",
    "#EE82EE",
    "#D2B48C",
    "#CD5C5C",
    "#FF8C00",
    "#DA70D6",
    "#FFFAFA",
    "#2F4F4F",
    "#DDA0DD",
    "#A0522D",
    "#40E0D0",
    "#6B8E23",
    "#E9967A",
    "#CD853F",
    "#FA8072",
    "#F0E68C",
    "#48D1CC",
    "#BDB76B",
    "#F4A460",
    "#98FB98",
    "#DEB887",
    "#4682B4",
    "#C71585",
    "#9966CC",
    "#FFC0CB",
    "#808000",
    "#FFD700",
    "#E6E6FA",
    "#F5FFFA",
    "#DCDCDC",
    "#483D8B",
    "#FAF0E6",
    "#FF6347",
    "#800080",
    "#7FFF00",
    "#F5F5DC",
    "#8B008B",
    "#008080",
    "#00FA9A",
    "#191970",
    "#000000",
    "#C0C0C0",
    "#20B2AA",
    "#008000",
    "#FAEBD7",
    "#7B68EE",
    "#006400",
    "#DAA520",
    "#3CB371",
    "#00FFFF",
    "#0000CD",
    "#90EE90",
    "#9932CC",
    "#FFFF00",
    "#FFFFE0",
    "#DC143C",
    "#B0C4DE",
    "#FFF5EE",
    "#FFA500",
    "#AFEEEE",
    "#F0FFFF",
    "#B8860B",
    "#8A2BE2",
    "#9400D3",
    "#FFEBCD",
    "#708090",
    "#F0F8FF",
    "#A52A2A",
    "#FFFACD",
    "#FF00FF",
    "#778899",
    "#FFF8DC",
    "#FF00FF",
    "#9370DB",
    "#00CED1",
    "#7CFC00",
    "#FF7F50",
    "#00FF7F",
    "#D2691E",
    "#87CEFA",
    "#BA55D3",
    "#E0FFFF",
    "#FFE4E1",
    "#696969",
    "#F5DEB3",
    "#9ACD32",
    "#F8F8FF",
    "#D3D3D3",
    "#FFEFD5",
    "#FF1493",
    "#00008B",
    "#00FFFF",
    "#D8BFD8",
    "#556B2F",
    "#FF4500",
    "#FFFFFF",
    "#FF0000",
    "#008B8B",
    "#66CDAA",
    "#5F9EA0",
    "#87CEEB",
    "#DB7093",
    "#BC8F8F",
    "#FFDAB9",
    "#4B0082",
    "#FF69B4",
    "#800000",
    "#F08080",
    "#FDF5E6",
    "#FFB6C1",
    "#1E90FF",
    "#2E8B57",
    "#F0FFF0",
    "#F5F5F5",
    "#4169E1",
    "#00FF00",
    "#FFFFF0",
    "#0000FF",
    "#228B22",
    "#00BFFF",
    "#B0E0E6",
    "#B22222",
    "#8B0000",
    "#FFDEAD",
    "#8FBC8F",
    "#FFFAF0",
    "#808080",
    "#FAFAD2",
    "#EEE8AA",
    "#7FFFD4",
    "#000080",
  ];
  var streamingConfetti = false;
  var animationTimer = null;
  var particles = [];
  var waveAngle = 0;

  function resetParticle(particle, width, height) {
    particle.color = colors[(Math.random() * colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  }

  function startConfettiInner() {
    var width = window.innerWidth;
    var height = window.innerHeight;
    window.requestAnimFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
          return window.setTimeout(callback, 16.6666667);
        }
      );
    })();
    var canvas = document.getElementById("confetti-canvas");
    if (canvas === null) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("id", "confetti-canvas");
      canvas.setAttribute(
        "style",
        "position:fixed;display:block;top:0;left:0;right:0;bottom:0;z-index:999999;pointer-events:none"
      );
      document.body.appendChild(canvas);
      canvas.width = width;
      canvas.height = height;
      window.addEventListener(
        "resize",
        function () {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        },
        true
      );
    }
    var context = canvas.getContext("2d");
    while (particles.length < maxParticleCount)
      particles.push(resetParticle({}, width, height));
    streamingConfetti = true;
    if (animationTimer === null) {
      (function runAnimation() {
        context.clearRect(0, 0, window.innerWidth, window.innerHeight);
        if (particles.length === 0) animationTimer = null;
        else {
          updateParticles();
          drawParticles(context);
          animationTimer = requestAnimFrame(runAnimation);
        }
      })();
    }
  }

  function stopConfettiInner() {
    streamingConfetti = false;
  }

  function removeConfettiInner() {
    stopConfetti();
    particles = [];
  }

  function toggleConfettiInner() {
    if (streamingConfetti) stopConfettiInner();
    else startConfettiInner();
  }

  function drawParticles(context) {
    var particle;
    var x;
    for (var i = 0; i < particles.length; i++) {
      particle = particles[i];
      context.beginPath();
      context.lineWidth = particle.diameter;
      context.strokeStyle = particle.color;
      x = particle.x + particle.tilt;
      context.moveTo(x + particle.diameter / 2, particle.y);
      context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
      context.stroke();
    }
  }

  function updateParticles() {
    var width = window.innerWidth;
    var height = window.innerHeight;
    var particle;
    waveAngle += 0.01;
    for (var i = 0; i < particles.length; i++) {
      particle = particles[i];
      if (!streamingConfetti && particle.y < -15) particle.y = height + 100;
      else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(waveAngle);
        particle.y +=
          (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (streamingConfetti && particles.length <= maxParticleCount)
          resetParticle(particle, width, height);
        else {
          particles.splice(i, 1);
          i--;
        }
      }
    }
  }
})();

export { startConfetti, stopConfetti };
