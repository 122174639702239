import { gql } from "@apollo/client";
import { ADDRESS_INFO_FRAGMENT, COUPON_INFO_FRAGMENT, DISH_INFO_FRAGMENT, MERCHANT_INFO_FRAGMENT, MERCHANT_ORDER_INFO_FRAGMENT, ORDER_INFO_FRAGMENT, REVIEW_INFO_FRAGMENT, USER_INFO_FRAGMENT, } from "./fragments";
export const CURRENT_MERCHANT = gql `
    ${MERCHANT_INFO_FRAGMENT}
    query CurrentMerchant {
        currentMerchant {
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const GENERATE_TRIAL_PAYMENT_INTENT = gql `
    query GenerateTrialPaymentIntent {
        generateTrialPaymentIntent {
            status
            clientSecret
        }
    }
`;
export const USER_SUBSCRIPTION_STATUS = gql `
    query UserSubscriptionStatus {
        userSubscriptionStatus {
            status
            subscribed
            canceled
        }
    }
`;
export const CURRENT_USER = gql `
    ${USER_INFO_FRAGMENT}
    query CurrentUser {
        currentUser {
            user {
                ...UserInfo
            }
        }
    }
`;
export const ALL_MY_DISHES = gql `
    ${DISH_INFO_FRAGMENT}
    query AllMyDishes {
        allMyDishes {
            dishes {
                ...DishInfo
            }
        }
    }
`;
export const ADD_CHECKOUT_REACHED_STATISTIC = gql `
    mutation AddCheckoutReachedStatistic($input: AddCheckoutReachedStatisticInput) {
        addCheckoutReachedStatistic(input: $input) {
            status
        }
    }
`;
export const ADD_ATC_STATISTIC = gql `
    mutation AddATCStatistic($input: AddATCStatisticInput) {
        addATCStatistic(input: $input) {
            status
        }
    }
`;
export const ADD_CLICKED_STATICSTIC = gql `
    mutation AddClickedStatistic($input: AddClickedStatisticInput) {
        addClickedStatistic(input: $input) {
            status
        }
    }
`;
export const ADD_SEEN_STATISTIC = gql `
    mutation AddSeenStatistic($input: AddSeenStatisticInput) {
        addSeenStatistic(input: $input) {
            status
        }
    }
`;
export const SUBMIT_DISH = gql `
    mutation SubmitDish($input: SubmitDishInput) {
        submitDish(input: $input) {
            status
        }
    }
`;
export const REGISTER_USER = gql `
    ${USER_INFO_FRAGMENT}
    mutation RegisterUser($input: RegisterUserInput) {
        registerUser(input: $input) {
            status
            user {
                ...UserInfo
            }
            auth {
                accessToken
            }
        }
    }
`;
export const AUTHENTICATE_USER = gql `
    ${USER_INFO_FRAGMENT}
    mutation AuthenticateUser($input: AuthenticateUserInput) {
        authenticateUser(input: $input) {
            status
            user {
                ...UserInfo
            }
            auth {
                accessToken
            }
        }
    }
`;
export const CANCEL_SUBSCRIPTION = gql `
    mutation CancelSubscription {
        cancelSubscription {
            status
        }
    }
`;
export const ENLIST = gql `
    mutation Enlist($input: EnlistInput) {
        enlist(input: $input) {
            status
        }
    }
`;
export const UPDATE_NOTIFICATION_SETTINGS = gql `
    mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput) {
        updateNotificationSettings(input: $input) {
            status
        }
    }
`;
export const ADMIN_LOGIN = gql `
    query AdminLogin {
        adminLogin {
            status
        }
    }
`;
export const GENERATE_SUBSCRIPTION_PORTAL_URL = gql `
    query GenerateSubscriptionPortalUrl {
        generateSubscriptionPortalUrl {
            status
            url
        }
    }
`;
export const UPCOMING_ORDERS = gql `
    query UpcomingOrders {
        upcomingOrders {
            status
            upcoming {
                numOrders
                dateSecs
            }
        }
    }
`;
export const FETCH_STATISTICS = gql `
    query FetchStatistics($input: FetchStatisticsInput!) {
        fetchStatistics(input: $input) {
            statistics {
                key
                name
                vendorName
                imageUrl

                values {
                    reach {
                        main
                        compare
                    }
                    impressions {
                        main
                        compare
                    }
                    clicks {
                        main
                        compare
                    }
                    ctr {
                        main
                        compare
                    }
                    atc {
                        main
                        compare
                    }
                    atcRate {
                        main
                        compare
                    }
                    checkoutsReached {
                        main
                        compare
                    }
                    purchases {
                        main
                        compare
                    }
                    purchaseValue {
                        main
                        compare
                    }
                }
            }
            status
        }
    }
`;
export const EARNINGS_SUMMARY = gql `
    query EarningsSummary($input: EarningsSummaryInput!) {
        earningsSummary(input: $input) {
            status
            earnings
        }
    }
`;
export const EDIT_MERCHANT_PROFILE = gql `
    ${MERCHANT_INFO_FRAGMENT}
    mutation EditMerchantProfile($input: EditMerchantProfileInput) {
        editMerchantProfile(input: $input) {
            status
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const EDIT_DISH = gql `
    ${DISH_INFO_FRAGMENT}
    mutation EditDish($input: EditDishInput) {
        editDish(input: $input) {
            status
            dish {
                ...DishInfo
            }
        }
    }
`;
export const DELETE_DISH = gql `
    ${DISH_INFO_FRAGMENT}
    mutation DeleteDish($input: DeleteDishInput) {
        deleteDish(input: $input) {
            status
            dish {
                ...DishInfo
            }
        }
    }
`;
export const SET_DISH_AVAILABILITY = gql `
    ${MERCHANT_INFO_FRAGMENT}
    mutation SetDishAvailability($input: SetDishAvailabilityInput) {
        setDishAvailability(input: $input) {
            status
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const SET_MERCHANT_AVAILABILITY = gql `
    ${MERCHANT_INFO_FRAGMENT}
    mutation SetMerchantAvailability($input: SetMerchantAvailabilityInput) {
        setMerchantAvailability(input: $input) {
            status
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const WEEKLY_DELIVERY_DAY = gql `
    query WeeklyDeliveryDay {
        weeklyDeliveryDay {
            day
        }
    }
`;
export const ONBOARD_MERCHANT = gql `
    ${MERCHANT_INFO_FRAGMENT}
    mutation CompleteMerchantOnboarding {
        completeMerchantOnboarding {
            status
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const UPLOAD_FILE = gql `
    mutation UploadFile($file: Upload!) {
        uploadFile(file: $file) {
            fileURL
            fileURLCompressed
        }
    }
`;
export const SERVICEABLE_DISHES = gql `
    ${DISH_INFO_FRAGMENT}
    query ServiceableDishes {
        serviceableDishes {
            dishes {
                ...DishInfo
            }
        }
    }
`;
export const UPLOAD_FOOD_HANDLER_CERT = gql `
    mutation UploadFoodHandlerCertificate(
        $input: UploadFoodHandlerCertificateInput
    ) {
        uploadFoodHandlerCertificate(input: $input) {
            status
        }
    }
`;
export const MERCHANT_DISH_CATALOG = gql `
    ${DISH_INFO_FRAGMENT}
    query MerchantDishCatalog($input: MerchantDishCatalogInput) {
        merchantDishCatalog(input: $input) {
            dishes {
                ...DishInfo
            }
        }
    }
`;
export const RECOMMENDATION_CATALOG = gql `
    ${DISH_INFO_FRAGMENT},
    ${MERCHANT_INFO_FRAGMENT},
    query RecommendationCatalog($input: RecommendationCatalogInput) {
        recommendationCatalog(input: $input) {
            dishes {
                ...DishInfo
            }
            merchants {
                ...MerchantInfo
            }
        }
    }
`;
export const RECENTLY_ADDED_CATALOG = gql `
    ${DISH_INFO_FRAGMENT},
    ${MERCHANT_INFO_FRAGMENT},
    query RecentlyAddedCatalog($input: RecentlyAddedCatalogInput) {
        recentlyAddedCatalog(input: $input) {
            dishes {
                ...DishInfo
            }
            merchants {
                ...MerchantInfo
            }
        }
    }
`;
export const MERCHANT_EXTRA_INFO = gql `
    query MerchantExtraInfo($input: MerchantExtraInfoInput) {
        merchantExtraInfo(input: $input) {
            hasBio
            hasProfilePic
            hasFoodHandlersCertificate
            hasDeliveryHub
            status
        }
    }
`;
export const FILTERED_CATALOG = gql `
    ${DISH_INFO_FRAGMENT},
    query FilteredCatalog($input: FilteredCatalogInput) {
        filteredCatalog(input: $input) {
            dishes {
                ...DishInfo
            }
        }
    }
`;
export const CATALOG = gql `
    ${DISH_INFO_FRAGMENT},
    ${MERCHANT_INFO_FRAGMENT},
    query Catalog($input: CatalogInput) {
        catalog(input: $input) {
            dishes {
                ...DishInfo
            }
            merchants {
                ...MerchantInfo
            }
        }
    }
`;
export const CONNECT_TO_STRIPE = gql `
    ${MERCHANT_INFO_FRAGMENT}
    mutation ConnectToStripe {
        connectToStripe {
            stripeUrl
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const PING_STRIPE_CONNECTION = gql `
    ${MERCHANT_INFO_FRAGMENT}
    mutation PingStripeConnection {
        pingStripeConnection {
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const CUISINES = gql `
    query Cuisines {
        cuisines {
            cuisines
        }
    }
`;
export const GENERATE_PAYMENT_CLIENT_SECRET = gql `
    mutation GeneratePaymentClientSecret {
        generatePaymentClientSecret {
            status
            clientSecret
        }
    }
`;
export const GOOGLE_PLACE_SEARCH = gql `
    query GooglePlaceSearch($input: GooglePlaceSearchInput!) {
        googlePlaceSearch(input: $input) {
            status
            candidates {
                address
                placeId
            }
        }
    }
`;
export const CATALOG_FILTERS = gql `
    query CatalogFilters {
        catalogFilters {
            filters
        }
    }
`;
export const CATALOG_VENDORS = gql `
    ${MERCHANT_INFO_FRAGMENT},
    query CatalogVendors($input: CatalogVendorsInput) {
        catalogVendors(input: $input) {
            merchants {
                ...MerchantInfo
            }
        }
    }
`;
export const MERCHANT = gql `
    ${MERCHANT_INFO_FRAGMENT}
    query Merchant($input: MerchantInput) {
        merchant(input: $input) {
            merchant {
                ...MerchantInfo
            }
        }
    }
`;
export const ALL_MERCHANTS = gql `
    ${MERCHANT_INFO_FRAGMENT}
    query AllMerchants {
        allMerchants {
            allMerchants {
                ...MerchantInfo
            }
        }
    }
`;
export const CHECK_COUPON = gql `
    ${COUPON_INFO_FRAGMENT}
    query CheckCoupon($input: CheckCouponInput) {
        checkCoupon(input: $input) {
            status
            coupon {
                ...CouponInfo
            }
        }
    }
`;
export const SET_CART = gql `
    mutation SetCart($input: SetCartInput) {
        setCart(input: $input) {
            status
        }
    }
`;
export const SET_CURRENT_ZIP_CODE = gql `
    mutation SetCurrentZipCode($input: SetCurrentZipCodeInput) {
        setCurrentZipCode(input: $input) {
            status
        }
    }
`;
export const ADD_PAYMENT_METHOD = gql `
    mutation AddPaymentMethod($input: AddPaymentMethodInput) {
        addPaymentMethod(input: $input) {
            status
            paymentMethod {
                id
            }
        }
    }
`;
export const REMOVE_PAYMENT_METHOD = gql `
    mutation RemovePaymentMethod($input: RemovePaymentMethodInput) {
        removePaymentMethod(input: $input) {
            status
        }
    }
`;
export const SET_DEFAULT_PAYMENT_METHOD = gql `
    mutation SetDefaultPaymentMethod($input: SetDefaultPaymentMethodInput) {
        setDefaultPaymentMethod(input: $input) {
            status
        }
    }
`;
export const PLACE_ORDER = gql `
    mutation PlaceOrder($input: PlaceOrderInput) {
        placeOrder(input: $input) {
            status
            order {
                id
            }
        }
    }
`;
export const ORDER = gql `
    ${ORDER_INFO_FRAGMENT}
    query Order($input: OrderInput!) {
        order(input: $input) {
            status
            order {
                ...OrderInfo
            }
        }
    }
`;
export const EDIT_ACCOUNT_INFO = gql `
    mutation EditAccountInfo($input: EditAccountInfoInput) {
        editAccountInfo(input: $input) {
            status
        }
    }
`;
export const CHANGE_PASSWORD = gql `
    mutation ChangePassword($input: ChangePasswordInput) {
        changePassword(input: $input) {
            status
        }
    }
`;
export const SEARCH_DISHES = gql `
    ${MERCHANT_INFO_FRAGMENT},
    ${DISH_INFO_FRAGMENT},
    mutation Search($input: SearchInput) {
        search(input: $input) {
            merchants {
                ...MerchantInfo
            }
            dishes {
                ...DishInfo
            }
            status
        }
    }
`;
export const DISH = gql `
    ${DISH_INFO_FRAGMENT}
    query Dish($input: DishInput) {
        dish(input: $input) {
            dish {
                ...DishInfo
            }
            status
        }
    }
`;
export const LOGOUT = gql `
    ${USER_INFO_FRAGMENT}
    mutation Logout {
        logout {
            status
        }
    }
`;
export const APPLY_AS_MERCHANT = gql `
    ${USER_INFO_FRAGMENT}
    mutation ApplyAsMerchant {
        applyAsMerchant {
            status
            user {
                ...UserInfo
            }
        }
    }
`;
export const ADDRESS_FROM_GOOGLE_PLACE = gql `
    ${ADDRESS_INFO_FRAGMENT}
    query AddressFromGooglePlace($input: AddressFromGooglePlaceInput!) {
        addressFromGooglePlace(input: $input) {
            ...AddressInfo
        }
    }
`;
export const EXPLORE_MEALS = gql `
    ${DISH_INFO_FRAGMENT},
    ${MERCHANT_INFO_FRAGMENT},
    query ExploreMeals($input: ExploreMealsInput!) {
        exploreMeals(input: $input) {
            dishes {
                ...DishInfo
            }
            status
        }
    }
`;
export const SUBMIT_REVIEW = gql `
    ${REVIEW_INFO_FRAGMENT}
    mutation SubmitReview($input: SubmitReviewInput) {
        submitReview(input: $input) {
            status
            review {
                ...ReviewInfo
            }
        }
    }
`;
export const CHANGE_DEFAULT_DELIVERY_INSTRUCTIONS = gql `
    mutation ChangeDefaultDeliveryInstructions($input: ChangeDefaultDeliveryInstructionsInput) {
        changeDefaultDeliveryInstructions(input: $input) {
            status
        }
    }
`;
export const UPDATE_REVIEW = gql `
    ${REVIEW_INFO_FRAGMENT}
    mutation UpdateReview($input: UpdateReviewInput) {
        updateReview(input: $input) {
            status
            review {
                ...ReviewInfo
            }
        }
    }
`;
export const ORDERS_SUMMARY = gql `
    ${MERCHANT_ORDER_INFO_FRAGMENT},
    query OrdersSummary($input: OrdersSummaryInput) {
        ordersSummary(input: $input) {
            status
            orders {
                ...MerchantOrderInfo
            }
            orderCount
            customerCount
            dishesSoldCount
            tipsCollectedCents
            totalRevenueCents
            sales {
                dishName
                volume
            }
        }
    }
`;
export const MERCHANT_ORDER = gql `
    ${MERCHANT_ORDER_INFO_FRAGMENT},
    query MerchantOrder($input: MerchantOrderInput!) {
        merchantOrder(input: $input) {
            status
            merchantOrder {
                ...MerchantOrderInfo
            }
        }
    }
`;
export const CASH_OUT = gql `
    mutation CashOut {
        cashOut {
            status
        }
    }
`;
export const DISCONNECT_STRIPE = gql `
    mutation DisconnectStripe {
        disconnectStripe {
            status
        }
    }
`;
export const REMOVE_DISH_QUANTITY_IN_CART = gql `
    mutation RemoveDishQuantityInCart($input: RemoveDishQuantityInCartInput) {
        removeDishQuantityInCart(input: $input) {
            status
        }
    }
`;
export const REQUEST_PASSWORD_RESET = gql `
    mutation RequestPasswordReset($input: RequestPasswordResetInput) {
        requestPasswordReset(input: $input) {
            status
        }
    }
`;
export const RESET_PASSWORD = gql `
    mutation ResetPassword($input: ResetPasswordInput) {
        resetPassword(input: $input) {
            status
        }
    }
`;
