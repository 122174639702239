import { createContext } from "react";
export const LocalCartContext = createContext([]);
export const ExpandedDishContext = createContext({ setExpandedDish: () => { } });
export const CatalogContext = createContext({
    accumulatedMerchants: [],
    accumulatedDishes: [],
    setAccumulatedMerchants: () => { },
    setAccumulatedDishes: () => { },
    currentCatalogDate: new Date(),
    setCurrentCatalogDate: () => { },
    selectedDish: null,
    selectDish: () => { },
    cuisines: [],
    setCart: () => { },
});
