import moment from "moment";
export function months() {
    return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
}
export function wrapMonthIndex(i) {
    return ((i % months().length) + months().length) % months().length;
}
export function wrapDayIndex(i) {
    return ((i % 7) + 7) % 7;
}
export function monthAtIndex(i) {
    return months()[wrapMonthIndex(i)];
}
export function days() {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
}
export function dayNameFromIndex(idx) {
    return days()[idx];
}
export function numDaysInMonth(year, month) {
    const yearString = `${year}-${(month + 1).toString().padStart(2, "0")}-01T08:00:00Z`;
    return moment(yearString).daysInMonth();
}
export function firstDayIndex(year, month) {
    const yearString = `${year}-${(month + 1).toString().padStart(2, "0")}-01T08:00:00Z`;
    return moment(yearString).day();
}
