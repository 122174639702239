export function formatCurrency(number, maxFraction) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: maxFraction,
        maximumFractionDigits: maxFraction,
    });
    return formatter.format(number);
}
export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
export function invLerp(a, b, v) {
    if (a === b && a === v)
        return 100;
    return ((v - a) / (b - a)) * 100;
}
export function randomString() {
    return Math.random().toString(36).split(".")[1];
}
export function getRandomEnumValue(anEnum) {
    const enumValues = Object.keys(anEnum);
    const randomIndex = Math.floor(Math.random() * enumValues.length);
    const randomEnumKey = enumValues[randomIndex];
    return anEnum[randomEnumKey];
}
export const decimalCount = (num) => {
    const numStr = String(num);
    if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
    }
    return 0;
};
export function supportedImageTypes() {
    return [".jpeg", ".jpg", ".png", ".pdf", ".gif", ".tiff", ".heic", ".svg", ".bmp", ".raw"];
}
export function supportedMaxImageSize() {
    return 5000000;
}
