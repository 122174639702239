import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import ButtonStyle from "../../../types/ButtonStyle";
import ElementSize from "../../../types/ElementSize";
export const Button = ({ children, onClick, style, size, href, to, noBorder, bypassParentWidth, reducedPadding, showSpinner, isEnabled, className, }) => {
    if (!style)
        style = ButtonStyle.STANDARD;
    if (!size)
        size = ElementSize.M;
    const classes = classNames("relative flex justify-center items-center rounded-full", {
        "w-full": !bypassParentWidth,
        "font-[700] text-[22.5px] leading-[24px] py-[12px] px-[20px]": size === ElementSize.L,
        "font-[700] text-[18px] leading-[28px] leading-[24px] py-[12px] px-[20px] xs:py-[12px] xs:px-[20px]": size === ElementSize.M,
        "font-[500] text-[16px] leading-[24px] py-[8px] px-[12px]": size === ElementSize.S && !reducedPadding,
        "font-[500] text-[16px] leading-[24px] py-[5.5px] px-[9.5px]": size === ElementSize.S && reducedPadding,
        "font-[400] text-[12px] leading-[16px] py-[6px] px-[12px]": size === ElementSize.XS,
        "bg-[#F5F5F5]": style === ButtonStyle.GREY,
        "text-black bg-white": style === ButtonStyle.STANDARD,
        "border border-black": style === ButtonStyle.STANDARD && !noBorder,
        "bg-black text-white": style === ButtonStyle.BLACK,
        "opacity-50": !(isEnabled == null ? true : isEnabled),
    }, className);
    if (href) {
        return (_jsx("a", { target: "_blank", href: href, className: classes, children: children }));
    }
    else if (to) {
        return (_jsx(Link, { to: to, className: classes, children: children }));
    }
    else {
        return (_jsxs("button", { disabled: !(isEnabled == null ? true : isEnabled), type: onClick ? "button" : "submit", onClick: (isEnabled == null ? true : isEnabled) ? (showSpinner ? undefined : onClick) : undefined, className: classes, children: [showSpinner && (isEnabled == null ? true : isEnabled) && (_jsx("div", { className: "z-[30] absolute m-auto left-0 right-0 w-full h-full flex justify-center items-center", children: _jsx(ClipLoader, { color: style == ButtonStyle.GREY || style == ButtonStyle.STANDARD ? "black" : "white", size: "21px", speedMultiplier: 0.75 }) })), _jsx("div", { className: classNames("", {
                        "opacity-0": showSpinner,
                    }), children: children })] }));
    }
};
export default Button;
