import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Document, Font, Image, Page, StyleSheet, Text, View, pdf } from "@react-pdf/renderer";
import moment from "moment";
import satoshiBold from "../../assets/fonts/Satoshi-Bold.ttf";
import satoshiMedium from "../../assets/fonts/Satoshi-Medium.ttf";
import satoshiRegular from "../../assets/fonts/Satoshi-Regular.ttf";
import heart from "../../assets/images/heart.png";
Font.register({ family: "SatoshiRegular", src: satoshiRegular });
Font.register({ family: "SatoshiMedium", src: satoshiMedium });
Font.register({ family: "SatoshiBold", src: satoshiBold });
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    order: {
        flexDirection: "column",
        padding: 15,
        flexBasis: "33.3333%",
        border: "1px solid black",
        height: "25%",
    },
    singleOrder: {
        flexDirection: "column",
        padding: 15,
        height: "100%",
        flexBasis: "100%",
    },
    title: {
        fontSize: "16px",
    },
    instructionTitle: {
        fontSize: "6px",
        marginBottom: "3px",
    },
    reheatText: {
        paddingRight: "10px",
        fontSize: "6px",
    },
    text: {
        fontSize: "6px",
    },
    neighborsLabel: {
        fontSize: "6px",
        marginBottom: "3px",
    },
    spacer: {
        flex: 1,
    },
    footer: {
        flexDirection: "row",
        fontSize: "6px",
    },
    heart: {
        marginTop: "1.8px",
        marginRight: "1px",
        width: "4px",
        height: "5px",
    },
});
const OrderLabelView = function ({ item, merchantName, itemIdx, single, }) {
    return (_jsxs(View, { style: single ? styles.singleOrder : styles.order, children: [_jsx(Text, { style: styles.title, children: item.dish.profile?.displayName }), _jsx(View, { style: styles.spacer }), _jsx(Text, { style: styles.instructionTitle, children: "HEATING INSTRUCTIONS" }), _jsx(Text, { style: styles.reheatText, children: item.dish.profile?.nutrition?.reheatingInstructions }), _jsx(View, { style: styles.spacer }), _jsx(Text, { style: styles.neighborsLabel, children: "Neighbors" }), _jsxs(View, { style: styles.footer, children: [_jsxs(Text, { style: styles.text, children: ["Best by ", item.bestByDate.toLocaleDateString("en-US")] }), _jsx(View, { style: styles.spacer }), _jsx(Image, { src: heart, style: styles.heart }), _jsxs(Text, { style: styles.text, children: ["Handmade with love by", "  ", merchantName] })] })] }, itemIdx));
};
const LabelDocument = function ({ orders, }) {
    const rows = 6;
    const cols = 3;
    const itemsPerPage = rows * cols;
    const pageItems = [[]];
    for (let i = 0; i < orders.length; i++) {
        const deliveryDate = moment(orders[i].order.delivery?.deliveryDate);
        const preparationDate = deliveryDate.subtract(1, "day");
        for (let j = 0; j < orders[i].cartItems.length; j++) {
            for (let k = 0; k < orders[i].cartItems[j].quantity; k++) {
                if (pageItems[pageItems.length - 1].length === itemsPerPage) {
                    pageItems.push([]);
                }
                const expirationDate = preparationDate
                    .clone()
                    .add(orders[i].cartItems[j].dish.profile?.nutrition?.daysToExpire, "days");
                pageItems[pageItems.length - 1].push({
                    ...orders[i].cartItems[j],
                    bestByDate: expirationDate.toDate(),
                    merchantName: orders[i].merchant.profile?.displayName
                });
            }
        }
    }
    return (_jsx(Document, { children: pageItems.map((page, pageIdx) => {
            return (_jsx(Page, { style: styles.page, size: "LETTER", orientation: "landscape", children: page.map((item, itemIdx) => (_jsx(OrderLabelView, { item: item, merchantName: item.merchantName || "", itemIdx: itemIdx, single: false }, itemIdx))) }, pageIdx));
        }) }));
};
const SingleLabelDocument = function ({ item, }) {
    return (_jsx(Document, { children: _jsx(Page, { style: styles.page, size: [268 / 2, 464 / 2], orientation: "landscape", children: _jsx(OrderLabelView, { item: item, merchantName: item.merchantName || "", itemIdx: 0, single: true }) }) }));
};
export async function generateMultiOrderLabelPDF(merchants) {
    const orders = merchants.map((x) => x.orders).flatMap((x) => x);
    const p = await pdf(_jsx(LabelDocument, { orders: orders }));
    return p.toBlob();
}
export default async function (orders) {
    const p = await pdf(_jsx(LabelDocument, { orders: orders }));
    return p.toBlob();
}
export async function generateSingleOrderLabelPDFs(orders) {
    let blobs = [];
    for (let i = 0; i < orders.length; i++) {
        const deliveryDate = moment(orders[i].order.delivery?.deliveryDate);
        const preparationDate = deliveryDate.subtract(1, "day");
        for (let j = 0; j < orders[i].cartItems.length; j++) {
            for (let k = 0; k < orders[i].cartItems[j].quantity; k++) {
                const p = await pdf(_jsx(SingleLabelDocument, { item: {
                        ...orders[0].cartItems[0],
                        bestByDate: new Date(),
                        merchantName: orders[0].merchant.profile?.displayName
                    } }));
                blobs.push(await p.toBlob());
            }
        }
    }
    return blobs;
}
