import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CloseButton from "../../../assets/icons/cross-gray.svg";
import ButtonStyle from "../../../types/ButtonStyle";
import ElementSize from "../../../types/ElementSize";
import Button from "../../global/Button";
import DualInputContainer from "../../global/DualInputContainer";
import Input from "../../global/Input";
import FormWrapper from "../DishDetailView/FormWrapper";
import classNames from "classnames";
import { parseApplyAsMerchantMutation, parseRegisterUser } from "../../../api/parse";
import { useApplyAsMerchantMutation, useRegisterUserMutation, } from "../../../generated/graphql";
import formatPhoneNumber from "../../../lib/util/formatPhoneNumber";
import isValidEmail from "../../../lib/util/validation/isValidEmail";
import isValidUSPhone from "../../../lib/util/validation/isValidUSPhone";
import { ApplyViewSource } from "../../../pages/merchant/lander/ApplyView";
import Checkbox from "../../global/Checkbox";
function canApply(form, isAuthenticated) {
    return (form.firstName.length > 0 &&
        form.lastName.length > 0 &&
        String(form.zipCode).length == 5 &&
        /^[0-9]+$/.test(form.zipCode) &&
        isValidUSPhone(form.phoneNumber) &&
        isValidEmail(form.email) &&
        form.hasSignedGuidelines &&
        (form.password.length > 0 || isAuthenticated));
}
export default function ApplyAsVendor({ onClose, skipOnboarding, source, onRegister, initialUser, }) {
    const [form, setForm] = useState({
        firstName: initialUser?.profile?.givenName ?? "",
        lastName: initialUser?.profile?.familyName ?? "",
        zipCode: initialUser?.zipCode ?? -1,
        phoneNumber: initialUser?.phoneNumber ?? "",
        email: initialUser?.email ?? "",
        password: "",
        hasSignedGuidelines: false,
    });
    const [registerUser, registerUserRes] = useRegisterUserMutation();
    const { user } = parseRegisterUser(registerUserRes);
    const [applyAsMerchant, applyAsMerchantRes] = useApplyAsMerchantMutation();
    const { merchant } = parseApplyAsMerchantMutation(applyAsMerchantRes);
    if (user || merchant || initialUser?.merchant?.id) {
        onRegister();
    }
    useEffect(() => {
        if (source == ApplyViewSource.LANDER) {
            const div0Id = document.querySelector("#phone_no_div_0");
            const input0 = div0Id?.querySelectorAll("input");
            const div1Id = document.querySelector("#phone_no_div_1");
            const input1 = div1Id?.querySelectorAll("input");
            let input0Val = input0[0].value;
            let input1Val = input1[0].value;
            let input = input1Val.length > 0 ? input1Val : input0Val;
            setForm((f) => ({ ...f, phoneNumber: formatPhoneNumber(input) }));
        }
    }, []);
    const onKeyUp = (event) => {
        setPhoneNumber(event.target.value);
    };
    useEffect(() => {
        window.document.body.classList.add("locked");
    });
    useEffect(() => {
        if (applyAsMerchant.error || registerUserRes.error) {
            alert(applyAsMerchant.error?.message ?? registerUserRes.error?.message);
        }
    }, [applyAsMerchant.error, registerUserRes.error]);
    const ctaCopy = source == ApplyViewSource.LANDER ? "Apply" : "Sign Up";
    return (_jsxs("div", { className: "max-md:h-full max-md:overflow-y-auto", children: [_jsx("div", { className: "flex flex-row justify-end", children: _jsx("div", { className: classNames("bg-transparent flex items-center justify-center w-[32px] h-[32px] cursor-pointer mr-[24px] mt-[10px] sm:mt-none md:pt-none md:-mr-[8px] z-[20]", {
                        hidden: source == ApplyViewSource.PRIVATE,
                    }), onClick: onClose, children: _jsx(CloseButton, { className: "cursor-pointer z-[10]", onClick: onClose }) }) }), _jsx("div", { className: classNames({
                    "pt-[15px]": source == ApplyViewSource.PRIVATE,
                }), children: _jsx(FormWrapper, { label: ctaCopy, py: 0, mobilePy: 0, children: _jsxs("div", { className: "flex flex-col gap-[20px]", children: [_jsxs(DualInputContainer, { children: [_jsx(Input, { placeholder: "John", required: true, onInput: (e) => {
                                            setForm((f) => ({
                                                ...f,
                                                firstName: e.target.value,
                                            }));
                                        }, label: "First name", value: form.firstName, showRequiredError: false }), _jsx(Input, { placeholder: "Smith", required: true, onInput: (e) => {
                                            setForm((f) => ({
                                                ...f,
                                                lastName: e.target.value,
                                            }));
                                        }, label: "Last name", value: form.lastName, showRequiredError: false })] }), _jsx(Input, { placeholder: "94116", required: true, onInput: (e) => {
                                    const numberStr = e.target.value;
                                    let number = Number(numberStr);
                                    if (isNaN(number) || numberStr.length > 5) {
                                        number = form.zipCode;
                                    }
                                    setForm((f) => ({ ...f, zipCode: number }));
                                }, type: "number", label: "ZIP code", value: form.zipCode <= 0 ? "" : form.zipCode, showRequiredError: false }), _jsx(Input, { placeholder: "john.smith@gmail.com", required: true, disabled: initialUser != null &&
                                    initialUser != undefined &&
                                    (initialUser.email?.length ?? 0) > 0, onInput: (e) => {
                                    setForm((f) => ({
                                        ...f,
                                        email: e.target.value,
                                    }));
                                }, label: "Email", value: form.email, type: "email", showRequiredError: false }), _jsx(Input, { placeholder: "(415) 123-4567", required: true, onInput: (e) => {
                                    setForm((f) => ({
                                        ...f,
                                        phoneNumber: formatPhoneNumber(e.target.value),
                                    }));
                                }, label: "Phone number", value: form.phoneNumber, type: "tel", showRequiredError: false }), !initialUser && (_jsx(Input, { placeholder: "**********", required: true, onInput: (e) => {
                                    setForm((f) => ({
                                        ...f,
                                        password: e.target.value,
                                    }));
                                }, label: "Password", value: form.password, type: "password", showRequiredError: false })), _jsx(Checkbox, { rightChild: _jsxs("span", { className: "text-[14px] text-[#999999] font-medium", children: ["I agree to the", " ", _jsx("span", { className: "underline", children: _jsx("a", { href: "https://neighbors.co/legal/terms", target: "_blank", children: "Terms" }) }), " ", "and", " ", _jsx("span", { className: "underline", children: _jsx("a", { href: "https://neighbors.co/legal/privacy", target: "_blank", children: "Privacy Policy" }) })] }), onClick: () => {
                                    setForm({ ...form, hasSignedGuidelines: true });
                                }, isSelected: form.hasSignedGuidelines }), _jsx(Button, { size: ElementSize.L, isEnabled: canApply(form, initialUser != null && initialUser != undefined), style: ButtonStyle.BLACK, noBorder: true, showSpinner: registerUserRes.loading || applyAsMerchantRes.loading, onClick: () => {
                                    if (initialUser) {
                                        applyAsMerchant({
                                            variables: {
                                                input: {
                                                    givenName: form.firstName,
                                                    familyName: form.lastName,
                                                    email: form.email,
                                                    phoneNumber: form.phoneNumber,
                                                    zipCode: String(form.zipCode),
                                                },
                                            },
                                        });
                                    }
                                    else {
                                        registerUser({
                                            variables: {
                                                input: {
                                                    givenName: form.firstName,
                                                    familyName: form.lastName,
                                                    email: form.email,
                                                    password: form.password,
                                                    isMerchant: true,
                                                    phoneNumber: form.phoneNumber,
                                                    zipCode: String(form.zipCode),
                                                    skipOnboarding: skipOnboarding,
                                                },
                                            },
                                        });
                                    }
                                }, children: ctaCopy })] }) }) })] }));
}
