import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MiniStarUnfilled from "../../../assets/icons/mini-mini-star-unfilled.svg";
import MiniStar from "../../../assets/icons/mini-mini-star.svg";
import StarUnfilled from "../../../assets/icons/mini-star-unfilled.svg";
import Star from "../../../assets/icons/mini-star.svg";
import SelectableStarFilled from "../../../assets/icons/rating-select-filled.svg";
import SelectableStarUnfilled from "../../../assets/icons/rating-select-unfilled.svg";
export default function ReviewStar({ isFilled, selectable, onSelect, }) {
    return (_jsxs("div", { children: [!selectable && isFilled && _jsx(MiniStar, { className: "h-4 md:hidden" }), !selectable && !isFilled && _jsx(MiniStarUnfilled, { className: "h-4 md:hidden" }), !selectable && isFilled && _jsx(Star, { className: "h-4 hidden md:block" }), !selectable && !isFilled && _jsx(StarUnfilled, { className: "h-4 hidden md:block" }), selectable && isFilled && _jsx(SelectableStarFilled, { className: "h-6" }), selectable && !isFilled && _jsx(SelectableStarUnfilled, { className: "h-6" })] }));
}
