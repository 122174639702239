import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
export default function Terms() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
        });
    }, []);
    return _jsx("div", { dangerouslySetInnerHTML: { __html: termsHTML } });
}
const termsHTML = `
<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.3.5.2 (Linux)"/>
	<meta name="created" content="00:00:00"/>
	<meta name="changed" content="00:00:00"/>
	<style type="text/css">
		@page { size: 8.5in 11in; margin-left: 0.38in; margin-right: 0.5in; margin-top: 0.5in; margin-bottom: 0.5in }
		p { line-height: 115%; margin-bottom: 0.1in; background: transparent }
		a:link { color: #000080; so-language: zxx; text-decoration: underline }
    body {
      padding: 25px;
    }
	</style>
</head>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>TERMS
AND CONDITIONS-UNITED STATES</b></span></font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Neighbors</b></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
following Terms and Conditions of Use (“Terms”) apply to all
visitors of </span></span></font></font></font><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://neighbors.co/</span></u></font></font></font><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">,
and any Website provided on or in connection therewith (collectively,
“the Website”). The Website is operated by Neighbors Technology
Inc and any of its affiliated entities (collectively “Neighbors”,
“We”, “Us”, or “Our”). This is a legally binding
agreement between Us and You, the visitor to the Website.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">By
accessing and using the Website, you acknowledge that you have read,
understood and agree to be bound by these Terms without limitation or
qualification, including our </span></span></font></font></font><a href="https://neighbors.co/legal/privacy"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">Privacy
Policy</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
(which is expressly incorporated herein by reference) and the data
collection and use of your information set forth therein. We&nbsp;reserve
the right to make changes in rules and regulations from time to time.
Your continued use of the Website means that you accept and agree to
the changes posted and are bound by any such revisions. Visit this
page periodically to review the terms and conditions.&nbsp;We reserve
the right to terminate or suspend your account and/or access to the
Website without any notice and in our sole discretion and reserve the
rights to pursue any other remedies available to Us by law.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>THESE
TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AND CLASS
ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF
ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE
TO YOU IN THE EVENT OF A DISPUTE. PLEASE READ THIS DOCUMENT
CAREFULLY. </b></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Your
Account</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
may be required to register for an account with us in order to access
or use parts of the Website. Your account gives you access to the
Website and functionality that we may establish and maintain from
time to time and in our sole discretion. If you elect to take
advantage of such features, you must register through the Website by
completing the applicable registration form to create your account
with a unique username and password. The decision to provide this
information is purely optional; however, if you elect not to provide
such information, you may not be able to access certain content or
participate in certain features of the Website. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
you choose to register an account on the Website, you will create a
username and password for your account. By creating an account, you
agree: (a) that you are of legal age in the jurisdiction in which you
reside to form a binding contract with Us; (b) that you are able to
and have the appropriate authority and authorization to create a
binding agreement with Neighbors; (c) to provide true, accurate,
current and complete information about yourself when we request it;
(d) to maintain and promptly update this information to keep it true,
accurate, current and complete to the extent the Website permit such
updates; and (e) to use limited-access portions of the Website only
using access credentials that we have issued to you and not to
violate these Terms.    It is your responsibility to maintain the
confidentiality of your account, including your login credentials and
information associated with the account. Neighbors encourages you to
use “strong” passwords (passwords that use a combination of upper
and lower case letters, numbers and symbols) with your account. You
must notify Neighbors immediately of any unauthorized use of your
credentials or any other breach of security. Even if you notify us,
you will be responsible for any activities that occur using your
access credentials, including any charges resulting from the use of
your account. Neighbors will not be liable for any losses or damages
caused by any unauthorized use of your account, financial or
otherwise.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">By
providing Neighbors your email address you consent to receiving
Website-related notices, including any notices required by law, in
lieu of communication by postal mail. We may also use your email
address to send you other messages, such as changes to features of
the Website and special offers, promotions, or relevant news about
Neighbors If you do not want to receive such email messages, you may
opt-out or change your preferences on the personal settings page
associated with your account or by following the instructions
included in the email you receive. Opting out may prevent you from
receiving email messages regarding updates, improvements, or offers.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">When
you sign up for or use our products and Website, you may give us
permission to access your information in other Website. For example,
you may link your Facebook account, which allows us to obtain
information from those accounts (like your full name and email). The
information we get from those Website often depends on your settings
or their privacy policies, so be sure to check what those are. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
you violate these Terms, as further stated below in these Terms and
Conditions, We may terminate your account without prior notice. We
may also terminate your account without prior notice, if We in our
sole discretion decide that it would be in&nbsp;Neighbors’s best
interests to do so.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Merchants
Are Independent</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
understand and agree that Neighbors provides a technology platform
connecting you with independent food service providers and others
that provide the products offered through the Websites (“Merchants”).
You acknowledge and agree that Neighbors does not itself prepare food
and has no responsibility or liability for the acts or omissions of
any Merchant. Merchant is the retailer; the services offered by
Neighbors pursuant to these Terms and Conditions do not include any
food preparation services.    Neighbors will not assess or
guarantee the suitability, legality or ability of any Merchant. You
agree that Neighbors is not responsible for the Merchants’ food
preparation or the safety of the food or whether the photographs or
images displayed through the Websites accurately reflect the food
prepared by the Merchants and does not verify Merchants’ compliance
with applicable laws or regulations. Neighbors has no responsibility
or liability for acts or omissions by any Merchant.&nbsp;</span></span></font></font></font></p>
<p style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
agree that the goods that you purchase will be prepared by the
Merchant you have selected.    Depending on the jurisdiction,
Neighbors may enter into agreements with independent contractors
(each a &quot;Courier&quot;) to provide delivery services under
certain circumstances if desirable by individual Merchants or You. If
a Merchant or You chooses to use a Courier to deliver a Meal,
Neighbors is not offering such delivery services and has no
responsibility or liability for the actions or inactions of any
Courier. Neighbors will not be liable or responsible for any delivery
services provided by a Courier or any errors or misrepresentations
made by them. Couriers are required to comply with all applicable
laws, rules and regulations.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#262626"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Ingredients
and Allergens</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#262626"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">For
any particular Product and related content posted using the Website,
a Seller may provide a comprehensive list of ingredients contained in
or otherwise used to prepare the food, including any known allergens.
We are not responsible for the accuracy of the information and
labeling of Products delivered to You. You should be advised that
Products may be prepared in facilities using the same equipment that
is used to prepare Products containing other allergens even if the
allergen is marked as being absent from the food. We shall not be
liable for any illness, health problem, or other damages that may
result from any order or consumption of any Products and related
items purchased through the Website.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Policies
Related To Purchase</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
understand that: (a) the prices for items displayed through the
Website may differ from the prices offered or published by Merchants
for the same goods or items offered by Merchant through Merchant’s
own website or store and/or from prices available at third-party
websites and that such prices may not be the lowest prices at which
the goods or other items are sold; (b) Neighbors has no obligation to
itemize its costs, profits or margins when publishing such prices;
and (c) Neighbors reserves the right to change such prices at any
time, at its discretion. You are liable for all transaction taxes on
the services from the Website (other than taxes based on Neighbors’s
income). All payments will be processed by Neighbors or its payments
processor, using the preferred payment method designated in your
account. If your payment details change, your card provider may
provide us with updated card details. We may use these new details or
details from other cards on file in order to help prevent any
interruption to your use of the services from the Website. This
includes our right to charge any card on file if your initial form of
preferred payment fails. It is your responsibility to keep&nbsp;your
billing information up to date.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Charges
paid by you for completed and delivered orders are final and
non-refundable. Neighbors has no obligation to provide refunds or
credits, but may grant them, in each case in Neighbors’s sole
discretion.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Neighbors,
at its sole discretion, may make promotional offers with different
features and different rates to any user, which depending on the
offer may include or exclude you. These promotional offers are
subject to the terms of these Terms and Conditions and may be valid
only for certain users as indicated in the offer. You agree that
promotional offers: (i) may only be used by the intended audience,
for the intended purpose, and in a lawful manner; (ii) may not be
duplicated, sold or transferred in any manner, or made available to
the general public, unless expressly permitted by Neighbors; (iii)
are subject to the specific terms that Neighbors establishes for such
promotional offer; (iv) cannot be redeemed for cash or cash
equivalent; and (v) are not valid for use after the date indicated in
the offer. Neighbors reserves the right to withhold or deduct credits
or benefits obtained through a promotion in the event that Neighbors
determines or believes that the redemption of the promotion or
receipt of the credit or benefit was in error, fraudulent, illegal,
or in violation of the applicable promotion terms or these Terms and
Conditions. Neighbors reserves the right to modify or cancel an offer
at any time. Any credit issued by Neighbors is valid for 6 months
from the date of issue except to the extent prohibited under
applicable law and may not be redeemed for cash or cash equivalent.
Upon expiration, credits will be removed from your account. Expired
credits are no longer redeemable and cannot be used towards any
order. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Neighbors
may change the fees for Our services as we deem necessary or
appropriate for our business.    Without limiting the foregoing,
Neighbors may also charge Merchants fees on orders that you place
through the Website, including commissions and other fees, and may
change those merchant fees as we deem necessary or appropriate for
our business or to comply with applicable law.</span></span></font></font></font></p>
<p style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Additional
Terms: </b></span></font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Additional
Terms and Conditions may apply to purchases of goods or services and
to particular portions or features of the Website, all of which terms
are hereby made a part of these Terms by this reference. You agree to
abide by any such other Terms and Conditions. You represent that you
are of sufficient legal age to use or participate in such service or
feature. In case of a conflict between these Terms and Conditions of
Use and the Terms posted for or applicable to a specific portion of
the Website or for any service offered on or through the Website, the
latter terms shall control with respect to your use of that portion
of the Website or the specific service.</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Information
Accuracy</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">While
describing our products, we try to be as accurate as possible.
However, we do not assume any responsibility for the accuracy of
information.&nbsp;Neighbors reserves the right to do any of the
following without prior notice: (a) Suspend, terminate or modify the
access to or operations of the Website (b) Change or modify the
Website or any portion of it, or the products or its programs and
their descriptions (c) Interrupt the functions or features of the
Website for maintenance or other changes.</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Limited
License</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
content, information, software, designs, materials, functions and
data included in and contained on the Website or made available via
the Website (the “Content”) is protected by intellectual property
and other laws. You must comply with these and any other applicable
laws when you access the Site. All Content including but not limited
to designs, structure, text, logos, product packaging, trade dress,
product configuration, brand names, product names, graphics,
arrangement of content, user and visual interfaces, artwork, sounds,
music, programming, and all other copyrightable work or trademarks on
this Website are protected by copyright and/or trademark law and are
the property of Neighbors or its licensors. Except as allowed under
applicable law, no Content, in whole or in part, may be used, copied,
sold, reproduced, duplicated, modified, or otherwise exploited
without prior express written permission from&nbsp;Neighbors</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Unless
indicated to the contrary and subject to your strict compliance with
these Terms and Conditions, you may access and use the Website only
for personal, non-commercial use, or in connection with your
authorized purchase or sale of our products or Website. In connection
with these uses, Neighbors grants you a limited, non-exclusive,
revocable, non-assignable, personal and non-transferable license to:
access, copy, download, display, view, use, play and/or print one (1)
copy of the Content made available on the Website on a personal
computer, mobile phone or other wireless device, or other Internet
enabled device (each, an “Internet Device”), provided that you:
(a) keep intact all copyright and other proprietary notices, (b) make
no modifications to the Content, and (c) do not use the Content in a
manner that suggests an association with any of our products, Website
or brands. Any business use, “re-mailing” or high-volume or
automated use of Website is prohibited. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">As
a condition of your use of the Website, you warrant to Neighbors that
you will not use the Website for any purpose that is unlawful or
prohibited by these Terms or any applicable law. You may not use the
Website in any manner which could damage, disable, overburden, or
impair the Website or our computer network or interfere with any
other party’s use and enjoyment of the Website. You may not convey
unauthorized claims about the curative or health enhancing effects of
our products or suggest that we have made such claims. You may not
obtain or attempt to obtain any materials or information through any
means not intentionally made available or provided for through the
Website. You agree not to attempt to reverse-engineer the Website or
access any protected or non-public portion of the Website without
express authorization from Neighbors. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">In
addition to our other legal rights, we may limit or terminate your
license to use the Website, or certain features of the Website, at
any time and for any reason, without any prior notice to you
whatsoever.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Submissions</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
Website may allow you to submit, embed, display, transmit, or
otherwise distribute audio, video, text, photos, or other materials
(collectively, “User Submissions”) to or through the Website.
When you provide User Submissions, you grant to Neighbors a
non-exclusive, royalty-free, fully paid, perpetual, worldwide,
irrevocable and fully sublicensable and transferable (in whole or in
part) license under all copyrights, trademarks, patents, trade
secrets, privacy and publicity rights and other intellectual property
rights you own or control to: (i) use, reproduce, transmit, modify,
index, adapt, publish, translate, create derivative works from,
distribute, display and otherwise exploit such content throughout the
world in any media, whether now known or hereafter invented,
including for any and all purposes, including commercial or marketing
purposes, all without further notice to you, with or without
attribution, and without the requirement of any permission from or
payment to you or any other person or entity, and (ii) to use your
name, persona or likeness alone or in connection with such uses,
without any obligation or remuneration to you. Without limitation,
the granted rights include the right to: (a) configure, host, index,
cache, archive, store, digitize, compress, optimize, modify,
reformat, edit, adapt, publish in searchable format, and remove such
User Submissions and combine same with other materials, and (b) use
any ideas, concepts, know-how, or techniques contained in any User
Submissions for any purposes whatsoever, including developing,
manufacturing, and marketing products and/or Website. Except as
prohibited by law, you hereby waive, and you agree to waive, any
moral rights (including attribution and integrity) that you may have
in any User Submissions, even if it is altered or changed in a manner
not agreeable to you. To the extent not waivable, you irrevocably
agree not to exercise such rights (if any) in a manner that
interferes with any exercise of the granted rights. You understand
that you will not receive any fees, sums, consideration, or
remuneration for any of the rights granted in this Section.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
agree that your User Submissions will be limited to messages and
material that are proper and related to the Website. By posting,
uploading, inputting, providing or submitting your User Submission
you warrant and represent that you own or otherwise control all of
the rights to your User Submission as described in this section
including, without limitation, all the rights necessary for you to
provide, post, upload, input or submit the Submissions and grant the
necessary license noted in the preceding paragraph. You represent,
warrant, and covenant that your User Submission will not:</span></span></font></font></font></p>
<ul>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Defame,
	abuse, harass, stalk, threaten or otherwise violate the legal rights
	(such as rights of privacy and publicity) of others.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Publish,
	post, upload, distribute or disseminate any inappropriate, profane,
	defamatory, infringing, obscene, indecent or unlawful topic, name,
	material or information.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Upload
	files that contain software or other material protected by
	intellectual property laws (or by rights of privacy of publicity)
	unless you own or control the rights thereto or have received all
	necessary consents.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Constitute
	or encourage violence or a criminal offense, violate the rights of
	any party, including intellectual property rights, or otherwise give
	rise to liability or violate any law;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Impersonate
	any other person, user, or Neighbors, or may be false, fraudulent,
	deceptive, inaccurate, or misleading, or that misrepresents your
	identity or affiliation with a person or Neighbors;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Include
	other peoples’ personal information, such as another person’s
	address, phone number, e-mail address, social security number,
	credit card number, medical information, financial information, or
	any other information that may be used to track, contact, or
	impersonate that individual;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Upload
	files that contain viruses, corrupted files, or any other similar
	software or programs that may damage the operation of another’s
	computer.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Advertise
	or offer to sell or buy any goods or Website for any business
	purpose, unless such user Submission specifically allows such
	messages.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Conduct
	or forward surveys, contests, pyramid schemes or chain letters.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Download
	any file posted by another User Submission that you know, or
	reasonably should know, cannot be legally distributed in such
	manner.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Falsify
	or delete any author attributions, legal or other proper notices or
	proprietary designations or labels of the origin or source of
	software or other material contained in a file that is uploaded.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Restrict
	or inhibit any other user from using and enjoying the Website and
	User Submissions.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Violate
	any code of conduct or other guidelines which may be applicable for
	any particular User Submission.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Harvest
	or otherwise collect information about others, including e-mail
	addresses, without their consent.</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Violate
	any applicable laws or regulations</span></span></font></font></font></p>
</ul>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-left: 0.5in; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
may refuse or remove a User Submission without notice for any reason
in our sole discretion, including our belief that a User Submission
may violate these Terms and Conditions or be otherwise objectionable.
However, we will have no obligation to review, monitor, display,
post, store, maintain, accept or otherwise make use of, User
Submissions, and you agree that neither we nor our employees or
agents will be liable for User Submissions or any loss or damage to
you and any other person or entity resulting from User Submissions.
We cannot and do not assure that other users are or will be complying
with the foregoing or any other agreement, and, as between you and
us, you hereby assume all risk of harm, injury, or damages resulting
from any such lack of compliance. Once you upload a User Submission,
it may become public. We are not responsible for any harm, injury, or
damages whatsoever, arising out of your upload of a User Submission
that becomes public. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Linking
to This Website.</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Running
or displaying this Website or any information or material displayed
on this Website in frames or through similar means on another site
and creating any link from another site to any page on this Website
without our prior written permission is strictly prohibited.
Permitted links to this Website must comply with applicable laws,
rules and regulations.</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Third-
Party Sites.</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
Website may contain links to third party web sites that are meant for
convenience of the users and visitors (“Linked Sites”), and the
inclusion of any link does not imply endorsement by Neighbors of the
site or any association with its operators. The Linked Sites are not
under the control of Neighbors and Neighbors is not responsible for
the content of any Linked Site, including without limitation any link
contained in a Linked Site, or any changes or updates to a Linked
Site. Neighbors is not responsible for webcasting or any other form
of transmission received from any Linked Site. Linking to any such
third-party site is at your own risk and&nbsp;Neighbors&nbsp;is not
responsible for reliability or accuracy of information or data of
such sites. Please review the Terms and Conditions and Privacy Policy
of third-party sites that you visit.</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>No
Warranty/ Limitations of Liability</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>THE
INFORMATION, CONTENT, SOFTWARE, PRODUCTS, AND WEBSITE INCLUDED IN OR
AVAILABLE THROUGH THE WEBSITE MAY INCLUDE INACCURACIES OR
TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY MADE TO THE
INFORMATION HEREIN.    WE AND/OR ITS SUPPLIERS MAY MAKE
IMPROVEMENTS AND/OR CHANGES IN THE PRODUCTS OR WEBSITE OFFERED, OR TO
THE WEBSITE AT ANY TIME. </b></span></font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Neighbors
AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
INFORMATION, SOFTWARE, PRODUCTS, WEBSITE AND RELATED GRAPHICS
CONTAINED ON THE WEBSITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
PRODUCTS, WEBSITE AND RELATED GRAPHICS ARE PROVIDED “AS IS”
WITHOUT WARRANTY OR CONDITION OF ANY KIND. Neighbors AND/OR ITS
SUPPLIERS OR SERVICE PROVIDERS HEREBY DISCLAIM ALL WARRANTIES AND
CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
WEBSITE AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR
CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
TITLE AND NON-INFRINGEMENT.</b></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>TO
THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
Neighbors AND/OR ITS SUPPLIERS OR SERVICE PROVIDERS, OR ANY OF THEIR
EMPLOYEES, AGENTS, DIRECTORS, MANAGERS, MEMBERS, SHAREHOLDERS, OR
AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
WITHOUT LIMITATION, DAMAGES FOR INTERRUPTION OF BUSINESS, SYSTEM
FAILURES, INCLUDING VIRUSES OBTAINED DURING HYPERLINK, LOST PROFITS
OR REVENUES, COSTS OF REPLACEMENT GOODS, DATA NON-DELIVERY,
MISDELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION LOSS OR
DAMAGE TO DATA ARISING OUT OF THE USE OF OR INABILITY TO USE THIS
WEBSITE, ANY Neighbors PRODUCT OR PRODUCT SOLD BY Neighbors OR LINKS
TO ANY THIRD PARTY SITE FROM THE WEBSITE, DAMAGES RESULTING FROM USE
OF OR RELIANCE ON THE INFORMATION PRESENT, LOSS OF USE, DATA OR
PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
PERFORMANCE OF THE WEBSITE, WITH THE DELAY OR INABILITY TO USE THE
WEBSITE, THE PROVISION OF OR FAILURE TO PROVIDE WEBSITE, OR FOR ANY
INFORMATION, SOFTWARE, PRODUCTS, WEBSITE AND RELATED GRAPHICS
OBTAINED THROUGH THE WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF
THE WEBSITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
LIABILITY OR OTHERWISE, EVEN IF Neighbors OR ANY OF ITS SUPPLIERS OR
SERVICE PROVIDERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
ANY PORTION OF THE WEBSITE, OR WITH ANY OF THESE TERMS AND
CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
THE WEBSITE.</b></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Indemnification</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
agree to defend, indemnify and hold&nbsp;harmless Neighbors&nbsp;and
its affiliates, subsidiaries, agents, licensors, managers, members,
and their employees, contractors, agents, officers and directors,
from and against any and all claims, damages, obligations, losses,
liabilities, costs or debt, and expenses (including but not limited
to attorney’s fees) arising from: (i) your use of and access to the
Website, including any data or content transmitted or received by
you; (ii) your violation of any term of these Terms; (iii) your
violation of any third-party right, including without limitation any
right of privacy, right of publicity or Intellectual Property Rights;
(iv) your violation of any applicable law, rule or regulation; (v)
your User submissions or any that is submitted via your account; or
(vi) any other party’s access and use of the Website with your
unique username, password, or other appropriate security code. You
also agree to indemnify us for any loss, damages, or costs, including
reasonable attorneys’ fees, resulting from your use of any data
gathering and extraction tools or any other action you take that
imposes an unreasonable burden or load on our infrastructure.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Breach
And Limitation of Liability</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
understand and agree that a key element of the services and these
Terms is your and Our mutual desire to keep the services simple and
efficient, and to provide the services at low cost.&nbsp;&nbsp;You
understand and agree to the limitations on remedies and liabilities
set forth in this Section to keep the services simple and efficient,
and costs low, for all users.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">TO
THE FULLEST EXTENT PERMITTED BY LAW, OUR AGGREGATE LIABILITY SHALL
NOT EXCEED THE GREATER OF AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM
YOU TO US IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT
GIVING RISE TO SUCH CLAIM. THIS CAP ON LIABILITY SHALL APPLY FULLY TO
RESIDENTS OF NEW JERSEY.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">TO
THE FULLEST EXTENT OF LAW, WE SHALL NOT BE LIABLE TO YOU OR ANYONE
ELSE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL,
CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING
PERSONAL INJURY, LOST PROFITS, PAIN AND SUFFERING, EMOTIONAL
DISTRESS, AND LOSS OF DATA, REVENUE, USE AND ECONOMIC ADVANTAGE).&nbsp;</span></span></font></font></font><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>THE
FOREGOING DISCLAIMER OF PUNITIVE AND EXEMPLARY DAMAGES, AND THE
ENTIRE DISCLAIMER OF DAMAGES FOR PERSONAL INJURY OR PROPERTY DAMAGE,
OR FOR ANY INJURY CAUSED BY OUR FRAUD OR FRAUDULENT
MISREPRESENTATION, SHALL NOT APPLY TO USERS WHO RESIDE IN THE STATE
OF NEW JERSEY.</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Binding
Arbitration/Class Waiver</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">For
any dispute with Neighbors, you agree to first contact us at and
attempt to resolve the dispute with us informally. In the unlikely
event that Neighbors has not been able to resolve a dispute it has
with you after attempting to do so for a period of sixty (60) days,
we each agree to resolve any claim, dispute, or controversy
(excluding any of Neighbors’ claims for injunctive or other
equitable relief) arising out of or in connection with or relating to
these Terms and Conditions, or the breach or alleged breach thereof
(collectively, “Claims”), by binding arbitration by the American
Arbitration Association (“AAA”) in the city of San Francisco
under the commercial rules then in effect for the AAA, except as
provided herein. The award rendered by the arbitrator shall include
costs of arbitration, reasonable attorneys’ fees and reasonable
costs for expert and other witnesses, and any judgment on the award
rendered by the arbitrator may be entered in any court of competent
jurisdiction. Nothing in this Section shall be deemed as preventing
Neighbors from seeking injunctive or other equitable relief from the
courts as necessary to protect any of Neighbors’ proprietary
interests.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>ALL
CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT
AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION,
COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER
REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION,
AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO
THESE TERMS AND CONDITIONS, YOU AND Neighbors ARE EACH WAIVING THE
RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION,
COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER
REPRESENTATIVE PROCEEDING OF ANY KIND.</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Choice
of Law / Jurisdiction</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
laws of the State of California govern these Terms and Conditions and
any dispute of any sort that may arise between you and Neighbors or
its affiliates, without regard to conflict of laws rules, as if
entered into by residents of California and fully performed therein.
Subject to the binding Arbitration clause above, You irrevocably
consent to the jurisdiction of the state courts located in or serving
San Francisco county or a federal court of competent jurisdiction in
the United States District Court for the Northern District of
California for any action relating to the Site or these Terms.
Notwithstanding any other provision of these Terms, we may seek
injunctive or other equitable relief from any court of competent
jurisdiction.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
make no representation that any Website is appropriate or available
for use in any particular location. Those who choose to access the
Website do so on their own initiative and are responsible for
compliance with all applicable laws including any applicable local
laws.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Trademark
/ Copyright/ Notice of Infringement</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">All
Content of the Website including but not limited to text, design,
graphics, interfaces, or code are: Copyright 2022 by Neighbors or its
licensees. All rights reserved.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">All
trademarks, service marks, and trade names are trademarks or
registered trademarks and are proprietary to Neighbors, or other
respective owners that have granted Neighbors license to use such
marks.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Pursuant
to Title 17, United States Code, Section 512(c)(2), notifications of
claimed copyright infringement under United States copyright law
should be sent to Website Provider’s Designated Agent. ALL
INQUIRIES NOT RELEVANT TO THE FOLLOWING PROCEDURE WILL RECEIVE NO
RESPONSE.</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">For
your complaint to be valid under the DMCA, you must provide the
following information in writing:</span></span></font></font></font></p>
<ol>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">An
	electronic or physical signature of a person authorized to act on
	behalf of the copyright owner;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Identification
	of the copyrighted work that you claim has been infringed;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Identification
	of the material that is claimed to be infringing and where it is
	located on the Website;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Information
	reasonably sufficient to permit Neighbors to contact you, such as
	your address, telephone number, and, e-mail address;</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">A
	statement that you have a good faith belief that use of the material
	in the manner complained of is not authorized by the copyright
	owner, its agent, or law; and</span></span></font></font></font></p>
	<li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">A
	statement, made under penalty of perjury, that the above information
	is accurate, and that you are the copyright owner or are authorized
	to act on behalf of the owner.</span></span></font></font></font></p>
</ol>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">UNDER
FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS
INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY
AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND
ATTORNEYS’ FEES.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Please
note that this procedure is exclusively for notifying Neighbors and
its affiliates that your copyrighted material has been infringed. The
preceding requirements are intended to comply with Neighbors’s
rights and obligations under the DMCA, including 17 U.S.C. §512(c),
but do not constitute legal advice. It may be advisable to contact an
attorney regarding your rights and obligations under the DMCA and
other applicable laws.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Accessibility</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Neighbors
is committed to ensuring accessibility of the Website to people with
disabilities under the Americans With Disabilities Act of 1990. Thus,
it is our goal that the Website and content produced by our company
will meet the legally appropriate standards so that anyone can access
and use the Website. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Website
content provided by third-parties, including any user-generated
content, may not meet said standards because the creation of that
content is out of our control. However, we strive to ensure that this
content is accessible and will request it be made accessible as
necessary. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
you have an accessibility issue with the Website, please reach out to
us by email to </span></span></font></font></font><a href="mailto:chris@neighbors.co"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">chris@neighbors.co</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
with your concern so that we can take the appropriate action. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Internet
Delays</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
Website and services provided may be subject to limitations, delays,
and other problems inherent in the use of the Internet and electronic
communications. Except as set forth in Our Privacy Policy or as
otherwise required by applicable law, We are not responsible for any
delays, delivery failures, or damage, loss or injury resulting from
such problems.</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Assignment
/ Entire Agreement / Severability / Admissibility</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">These
Terms and Conditions, and any rights and licenses granted hereunder,
may not be transferred or assigned by you, but may be assigned by
Neighbors without restriction. Any attempted transfer or assignment
in violation hereof shall be null and void.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Unless
otherwise specified herein, these Terms and Conditions constitutes
the entire agreement between the user and Neighbors with respect to
the Website and it supersedes all prior or contemporaneous
communications and proposals, whether electronic, oral or written,
between the user and Neighbors with respect to the Website.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
any part of these Terms is determined to be invalid or unenforceable
pursuant to applicable law including, but not limited to, the
warranty disclaimers and liability limitations set forth above, then
the invalid or unenforceable provision will be deemed superseded by a
valid, enforceable provision that most closely matches the intent of
the original provision and the remainder of the agreement shall
continue in effect.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">A
printed version of these Terms and Conditions and of any notice given
in electronic form shall be admissible in judicial or administrative
proceedings based upon or relating to these Terms and Conditions to
the same extent and subject to the same conditions as other business
documents and records originally generated and maintained in printed
form. It is the express wish to the parties that these Terms and
Conditions and all related documents be drawn up in English.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Termination</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">At
Our sole discretion, We may modify or discontinue the services, or
may modify, suspend or terminate your access to the services, for any
reason, with or without notice to you and without liability to you or
any third party. In addition to suspending or terminating your access
to the services, We reserves the right to take appropriate legal
action, including without limitation, pursuing civil, criminal or
injunctive redress. Even after your right to use the services is
terminated, these Terms will remain enforceable against you. All
provisions which by their nature should survive to give effect to
those provisions shall survive the termination of these Terms and
Conditions.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Modification
and Changes to these Terms and Conditions</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
reserve the right, in our sole discretion, to change these Terms and
Conditions at any time by posting a revised Terms and Conditions on
the Website. It is your responsibility to check periodically for any
changes we may make to these Terms and Conditions. Your continued use
of the Website following the posting of changes to these Terms and
Conditions other policies means you accept the changes.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>No
Waiver</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">No
waiver of any term of these Terms and Conditions shall be deemed a
further or continuing waiver of such term or any other term, and Our
failure to assert any right or provision under these Terms and
Conditions shall not constitute a waiver of such right or provision.</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Notice
for California Users</b></span></font></font></font><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Under
California Civil Code Section 1789.3, users of the Website from
California are entitled to the following specific consumer rights
notice: The Complaint Assistance Unit of the Division of Consumer
Websites of the California Department of Consumer Affairs may be
contacted in writing at 1625 North Market Blvd., Suite N 112,
Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800)
952-5210. </span></span></font></font></font>
</p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Contact
Us</b></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
you have any questions about these Terms and Conditions, this
Website, or your dealings with Us, please contact Us at:</span></span></font></font></font></p>
<p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Neighbors
Technology Inc</span></span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Or
by email at </span></span></font></font></font><a href="mailto:chris@neighbors.co"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">chris@neighbors.co</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
</span></span></font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Last
Updated: October 26, 2022</span></span></font></font></font></p>
</body>
</html>`;
