import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import useScript from "../../../lib/hooks/useScript";
import constants from "../../../lib/util/constants";
import AuthenticateButton, { AuthenticateButtonType } from "../AuthenticateButton";
const clientId = constants.apiKeys.apple.APPLE_CLIENT_ID;
const scope = constants.apiKeys.apple.APPLE_CLIENT_SCOPE;
const nonce = "NONCE";
const redirectURI = constants.apiKeys.apple.APPLE_REDIRECT_URI;
export default function AuthenticateWithApple({ isMerchant, onAuthorizationPayload, isLoading, }) {
    const [loaded] = useScript(`https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`);
    const onClick = async (e = null) => {
        if (e) {
            e.preventDefault();
        }
        let data = null;
        try {
            data = await AppleID.auth.signIn();
        }
        catch (err) {
            alert("Apple sign in error");
        }
        const payload = {
            appleToken: data.authorization.id_token,
            givenName: data.user?.name?.firstName,
            familyName: data.user?.name?.lastName,
            email: data.user?.email,
        };
        onAuthorizationPayload(payload);
    };
    useEffect(() => {
        if (loaded) {
            AppleID.auth.init({
                clientId,
                scope,
                redirectURI,
                state: "",
                nonce,
                usePopup: true,
            });
        }
        return () => { };
    }, [loaded]);
    return (_jsx(AuthenticateButton, { showSpinner: isLoading, type: AuthenticateButtonType.APPLE, onClick: onClick }));
}
