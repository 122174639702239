import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var AuthProvider;
(function (AuthProvider) {
    AuthProvider["Apple"] = "APPLE";
    AuthProvider["Email"] = "EMAIL";
    AuthProvider["Facebook"] = "FACEBOOK";
    AuthProvider["Google"] = "GOOGLE";
    AuthProvider["Phone"] = "PHONE";
})(AuthProvider || (AuthProvider = {}));
export var CouponType;
(function (CouponType) {
    CouponType["Flat"] = "FLAT";
    CouponType["Percentage"] = "PERCENTAGE";
})(CouponType || (CouponType = {}));
export var DishStatus;
(function (DishStatus) {
    DishStatus["ChangesPending"] = "CHANGES_PENDING";
    DishStatus["Deleted"] = "DELETED";
    DishStatus["Draft"] = "DRAFT";
    DishStatus["InReview"] = "IN_REVIEW";
    DishStatus["Live"] = "LIVE";
    DishStatus["Rejected"] = "REJECTED";
    DishStatus["SoldOut"] = "SOLD_OUT";
    DishStatus["Suspended"] = "SUSPENDED";
    DishStatus["Unlisted"] = "UNLISTED";
})(DishStatus || (DishStatus = {}));
export var MerchantApprovalStatus;
(function (MerchantApprovalStatus) {
    MerchantApprovalStatus["Approved"] = "APPROVED";
    MerchantApprovalStatus["Pending"] = "PENDING";
    MerchantApprovalStatus["PendingChanges"] = "PENDING_CHANGES";
    MerchantApprovalStatus["Rejected"] = "REJECTED";
})(MerchantApprovalStatus || (MerchantApprovalStatus = {}));
export var MerchantType;
(function (MerchantType) {
    MerchantType["Chef"] = "CHEF";
    MerchantType["Restaurant"] = "RESTAURANT";
})(MerchantType || (MerchantType = {}));
export var OrderCancelationType;
(function (OrderCancelationType) {
    OrderCancelationType["HumanInvoked"] = "HUMAN_INVOKED";
    OrderCancelationType["PaymentFailed"] = "PAYMENT_FAILED";
})(OrderCancelationType || (OrderCancelationType = {}));
export var OrderCancelor;
(function (OrderCancelor) {
    OrderCancelor["Admin"] = "ADMIN";
    OrderCancelor["Courier"] = "COURIER";
    OrderCancelor["System"] = "SYSTEM";
    OrderCancelor["User"] = "USER";
})(OrderCancelor || (OrderCancelor = {}));
export var OrderStageType;
(function (OrderStageType) {
    OrderStageType["Canceled"] = "CANCELED";
    OrderStageType["Complete"] = "COMPLETE";
    OrderStageType["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderStageType["PreparingOrder"] = "PREPARING_ORDER";
    OrderStageType["ProcessingPayment"] = "PROCESSING_PAYMENT";
    OrderStageType["Received"] = "RECEIVED";
    OrderStageType["SourcingIngredients"] = "SOURCING_INGREDIENTS";
})(OrderStageType || (OrderStageType = {}));
export var PaymentMethodIcon;
(function (PaymentMethodIcon) {
    PaymentMethodIcon["Amex"] = "AMEX";
    PaymentMethodIcon["Bank"] = "BANK";
    PaymentMethodIcon["Discover"] = "DISCOVER";
    PaymentMethodIcon["Mastercard"] = "MASTERCARD";
    PaymentMethodIcon["Paypal"] = "PAYPAL";
    PaymentMethodIcon["Unknown"] = "UNKNOWN";
    PaymentMethodIcon["Visa"] = "VISA";
})(PaymentMethodIcon || (PaymentMethodIcon = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Cleared"] = "CLEARED";
    PaymentStatus["Failed"] = "FAILED";
    PaymentStatus["Pending"] = "PENDING";
})(PaymentStatus || (PaymentStatus = {}));
export var UserNotificationDeliveryVendor;
(function (UserNotificationDeliveryVendor) {
    UserNotificationDeliveryVendor["Apns"] = "APNS";
    UserNotificationDeliveryVendor["Email"] = "EMAIL";
    UserNotificationDeliveryVendor["Text"] = "TEXT";
})(UserNotificationDeliveryVendor || (UserNotificationDeliveryVendor = {}));
export const CouponInfoFragmentDoc = gql `
    fragment CouponInfo on Coupon {
  id
  code
  deduction
  type
  limit
  numClaims
  updatedAt
  createdAt
}
    `;
export const ReviewInfoFragmentDoc = gql `
    fragment ReviewInfo on Review {
  rating
  body
  isDeleted
  isSystemHidden
  createdAt
  merchantOrderId
  user {
    profile {
      givenName
      familyName
    }
  }
}
    `;
export const MerchantInfoFragmentDoc = gql `
    fragment MerchantInfo on Merchant {
  id
  hasAddedDish
  cuisine
  email
  phoneNumber
  deliveryDates
  hasSignedGuidelines
  datesAvailable
  cutOffDates
  isOnboardingComplete
  isReadyForApproval
  approvalStatus
  isOutOfArea
  zipCode
  stripeAccountId
  isStripeEnabled
  reviews {
    ...ReviewInfo
  }
  cashouts {
    createdAt
    amountCents
  }
  wallet {
    balanceCents
    clearedBalanceCents
    inTransitCents
  }
  profile {
    aggregateRating
    numReviews
    numWrittenReviews
    givenName
    familyName
    displayPicUrl
    displayPicUrlCompressed
    headerPicUrl
    headerPicUrlCompressed
    bio
    displayName
    name
  }
  certification {
    imageUrl
    expiresAt
  }
  takeRate
  orders {
    earning {
      amountCents
    }
    cartItems {
      id
      quantity
      dish {
        id
        profile {
          id
          displayName
          displayPicUrl
          displayPicUrlCompressed
          type
          description
          nutrition {
            protein
            dietaryLabel
            allergiesLabel
            daysToExpire
            reheatingInstructions
            ingredients
            portionLabel
          }
        }
      }
    }
    order {
      id
      createdAt
      orderNumber
      receipt {
        subtotalCents
        deliveryFeeCents
        taxCents
        feeCents
        driverTipCents
      }
      user {
        profile {
          givenName
          familyName
        }
      }
      delivery {
        deliveryDate
      }
    }
  }
}
    ${ReviewInfoFragmentDoc}`;
export const DishInfoFragmentDoc = gql `
    fragment DishInfo on Dish {
  id
  status
  isApproved
  priceCents
  numOrdersPlaced
  spice
  tags
  merchant {
    ...MerchantInfo
  }
  availability {
    id
    datesAvailable
    weeklyLimit
  }
  profile {
    id
    displayName
    displayPicUrl
    displayPicUrlCompressed
    type
    description
    nutrition {
      protein
      dietaryLabel
      allergiesLabel
      daysToExpire
      reheatingInstructions
      ingredients
      portionLabel
    }
  }
  weeklyLimit
}
    ${MerchantInfoFragmentDoc}`;
export const MerchantOrderInfoFragmentDoc = gql `
    fragment MerchantOrderInfo on MerchantOrder {
  id
  createdAt
  updatedAt
  cartItems {
    id
    quantity
    dish {
      ...DishInfo
    }
  }
  order {
    id
    createdAt
    orderNumber
    receipt {
      subtotalCents
      deliveryFeeCents
      taxCents
      feeCents
      driverTipCents
    }
    user {
      profile {
        givenName
        familyName
      }
    }
    delivery {
      deliveryDate
    }
  }
  earning {
    amountCents
  }
  review {
    id
    rating
    body
  }
  merchant {
    profile {
      displayPicUrl
      displayPicUrlCompressed
      displayName
      name
    }
  }
}
    ${DishInfoFragmentDoc}`;
export const AddressInfoFragmentDoc = gql `
    fragment AddressInfo on Address {
  streetAddress
  unitNumber
  countryCode
  state
  city
  zip
  id
  lat
  long
  formatted
  defaultDeliveryInstructions
}
    `;
export const OrderInfoFragmentDoc = gql `
    fragment OrderInfo on Order {
  id
  merchantIds
  orderNumber
  merchantOrders {
    ...MerchantOrderInfo
  }
  createdAt
  cartItems {
    id
    quantity
    dish {
      id
      profile {
        id
        displayName
        displayPicUrl
        displayPicUrlCompressed
        type
        description
        nutrition {
          protein
          dietaryLabel
          allergiesLabel
          daysToExpire
          reheatingInstructions
          ingredients
          portionLabel
        }
      }
    }
  }
  currentStage {
    type
    isFulfilled
    isFailed
  }
  receipt {
    subtotalCents
    driverTipCents
    deliveryFeeCents
    taxCents
    feeCents
  }
  delivery {
    deliveryDate
    windowStart
    windowEnd
    deliveryInfo {
      fullName
      phoneContact
      deliveryInstructions
      address {
        ...AddressInfo
      }
    }
  }
  payment {
    amountCents
  }
  cancelation {
    cancelor
    cancelationMessage
    cancelationType
  }
  refund {
    refundedAmountCents
    refundReason
  }
}
    ${MerchantOrderInfoFragmentDoc}
${AddressInfoFragmentDoc}`;
export const UserInfoFragmentDoc = gql `
    fragment UserInfo on User {
  id
  canMockOrders
  merchant {
    id
  }
  phoneNumber
  smsOrderUpdates
  email
  isEmailVerified
  isPhoneVerified
  zipCode
  isOnboardingComplete
  isDeleted
  isSuspended
  stripeAccountId
  stripeSubscriptionId
  orderHistory {
    orders {
      id
      receipt {
        subtotalCents
        driverTipCents
        deliveryFeeCents
        taxCents
        feeCents
      }
      currentStage {
        type
        isFulfilled
        isFailed
      }
      createdAt
    }
    merchantOrders {
      ...MerchantOrderInfo
    }
    items {
      quantity
      merchantOrder {
        id
      }
    }
  }
  auth {
    provider
    identifier
    refreshToken
    phoneNumber
  }
  profile {
    givenName
    familyName
    birthday
    countryCode
    createdAt
  }
  cartItems {
    dish {
      ...DishInfo
    }
    quantity
    updatedAt
  }
  paymentMethods {
    id
    createdAt
    updatedAt
    isDefault
    paypalId
    stripeId
    brand
    title
    subtitle
    icon
  }
  lastDeliveryPhone
  lastDeliveryName
  lastDeliveryInstructions
  addresses {
    ...AddressInfo
  }
}
    ${MerchantOrderInfoFragmentDoc}
${DishInfoFragmentDoc}
${AddressInfoFragmentDoc}`;
export const CurrentMerchantDocument = gql `
    query CurrentMerchant {
  currentMerchant {
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useCurrentMerchantQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CurrentMerchantDocument, options);
}
export function useCurrentMerchantLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CurrentMerchantDocument, options);
}
export const GenerateTrialPaymentIntentDocument = gql `
    query GenerateTrialPaymentIntent {
  generateTrialPaymentIntent {
    status
    clientSecret
  }
}
    `;
export function useGenerateTrialPaymentIntentQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GenerateTrialPaymentIntentDocument, options);
}
export function useGenerateTrialPaymentIntentLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GenerateTrialPaymentIntentDocument, options);
}
export const UserSubscriptionStatusDocument = gql `
    query UserSubscriptionStatus {
  userSubscriptionStatus {
    status
    subscribed
    canceled
  }
}
    `;
export function useUserSubscriptionStatusQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserSubscriptionStatusDocument, options);
}
export function useUserSubscriptionStatusLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserSubscriptionStatusDocument, options);
}
export const CurrentUserDocument = gql `
    query CurrentUser {
  currentUser {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export function useCurrentUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CurrentUserDocument, options);
}
export const AllMyDishesDocument = gql `
    query AllMyDishes {
  allMyDishes {
    dishes {
      ...DishInfo
    }
  }
}
    ${DishInfoFragmentDoc}`;
export function useAllMyDishesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AllMyDishesDocument, options);
}
export function useAllMyDishesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AllMyDishesDocument, options);
}
export const AddCheckoutReachedStatisticDocument = gql `
    mutation AddCheckoutReachedStatistic($input: AddCheckoutReachedStatisticInput) {
  addCheckoutReachedStatistic(input: $input) {
    status
  }
}
    `;
export function useAddCheckoutReachedStatisticMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddCheckoutReachedStatisticDocument, options);
}
export const AddAtcStatisticDocument = gql `
    mutation AddATCStatistic($input: AddATCStatisticInput) {
  addATCStatistic(input: $input) {
    status
  }
}
    `;
export function useAddAtcStatisticMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddAtcStatisticDocument, options);
}
export const AddClickedStatisticDocument = gql `
    mutation AddClickedStatistic($input: AddClickedStatisticInput) {
  addClickedStatistic(input: $input) {
    status
  }
}
    `;
export function useAddClickedStatisticMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddClickedStatisticDocument, options);
}
export const AddSeenStatisticDocument = gql `
    mutation AddSeenStatistic($input: AddSeenStatisticInput) {
  addSeenStatistic(input: $input) {
    status
  }
}
    `;
export function useAddSeenStatisticMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddSeenStatisticDocument, options);
}
export const SubmitDishDocument = gql `
    mutation SubmitDish($input: SubmitDishInput) {
  submitDish(input: $input) {
    status
  }
}
    `;
export function useSubmitDishMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SubmitDishDocument, options);
}
export const RegisterUserDocument = gql `
    mutation RegisterUser($input: RegisterUserInput) {
  registerUser(input: $input) {
    status
    user {
      ...UserInfo
    }
    auth {
      accessToken
    }
  }
}
    ${UserInfoFragmentDoc}`;
export function useRegisterUserMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RegisterUserDocument, options);
}
export const AuthenticateUserDocument = gql `
    mutation AuthenticateUser($input: AuthenticateUserInput) {
  authenticateUser(input: $input) {
    status
    user {
      ...UserInfo
    }
    auth {
      accessToken
    }
  }
}
    ${UserInfoFragmentDoc}`;
export function useAuthenticateUserMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AuthenticateUserDocument, options);
}
export const CancelSubscriptionDocument = gql `
    mutation CancelSubscription {
  cancelSubscription {
    status
  }
}
    `;
export function useCancelSubscriptionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CancelSubscriptionDocument, options);
}
export const EnlistDocument = gql `
    mutation Enlist($input: EnlistInput) {
  enlist(input: $input) {
    status
  }
}
    `;
export function useEnlistMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(EnlistDocument, options);
}
export const UpdateNotificationSettingsDocument = gql `
    mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput) {
  updateNotificationSettings(input: $input) {
    status
  }
}
    `;
export function useUpdateNotificationSettingsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateNotificationSettingsDocument, options);
}
export const AdminLoginDocument = gql `
    query AdminLogin {
  adminLogin {
    status
  }
}
    `;
export function useAdminLoginQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AdminLoginDocument, options);
}
export function useAdminLoginLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AdminLoginDocument, options);
}
export const GenerateSubscriptionPortalUrlDocument = gql `
    query GenerateSubscriptionPortalUrl {
  generateSubscriptionPortalUrl {
    status
    url
  }
}
    `;
export function useGenerateSubscriptionPortalUrlQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GenerateSubscriptionPortalUrlDocument, options);
}
export function useGenerateSubscriptionPortalUrlLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GenerateSubscriptionPortalUrlDocument, options);
}
export const UpcomingOrdersDocument = gql `
    query UpcomingOrders {
  upcomingOrders {
    status
    upcoming {
      numOrders
      dateSecs
    }
  }
}
    `;
export function useUpcomingOrdersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UpcomingOrdersDocument, options);
}
export function useUpcomingOrdersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UpcomingOrdersDocument, options);
}
export const FetchStatisticsDocument = gql `
    query FetchStatistics($input: FetchStatisticsInput!) {
  fetchStatistics(input: $input) {
    statistics {
      key
      name
      vendorName
      imageUrl
      values {
        reach {
          main
          compare
        }
        impressions {
          main
          compare
        }
        clicks {
          main
          compare
        }
        ctr {
          main
          compare
        }
        atc {
          main
          compare
        }
        atcRate {
          main
          compare
        }
        checkoutsReached {
          main
          compare
        }
        purchases {
          main
          compare
        }
        purchaseValue {
          main
          compare
        }
      }
    }
    status
  }
}
    `;
export function useFetchStatisticsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(FetchStatisticsDocument, options);
}
export function useFetchStatisticsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(FetchStatisticsDocument, options);
}
export const EarningsSummaryDocument = gql `
    query EarningsSummary($input: EarningsSummaryInput!) {
  earningsSummary(input: $input) {
    status
    earnings
  }
}
    `;
export function useEarningsSummaryQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(EarningsSummaryDocument, options);
}
export function useEarningsSummaryLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(EarningsSummaryDocument, options);
}
export const EditMerchantProfileDocument = gql `
    mutation EditMerchantProfile($input: EditMerchantProfileInput) {
  editMerchantProfile(input: $input) {
    status
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useEditMerchantProfileMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(EditMerchantProfileDocument, options);
}
export const EditDishDocument = gql `
    mutation EditDish($input: EditDishInput) {
  editDish(input: $input) {
    status
    dish {
      ...DishInfo
    }
  }
}
    ${DishInfoFragmentDoc}`;
export function useEditDishMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(EditDishDocument, options);
}
export const DeleteDishDocument = gql `
    mutation DeleteDish($input: DeleteDishInput) {
  deleteDish(input: $input) {
    status
    dish {
      ...DishInfo
    }
  }
}
    ${DishInfoFragmentDoc}`;
export function useDeleteDishMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DeleteDishDocument, options);
}
export const SetDishAvailabilityDocument = gql `
    mutation SetDishAvailability($input: SetDishAvailabilityInput) {
  setDishAvailability(input: $input) {
    status
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useSetDishAvailabilityMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SetDishAvailabilityDocument, options);
}
export const SetMerchantAvailabilityDocument = gql `
    mutation SetMerchantAvailability($input: SetMerchantAvailabilityInput) {
  setMerchantAvailability(input: $input) {
    status
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useSetMerchantAvailabilityMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SetMerchantAvailabilityDocument, options);
}
export const WeeklyDeliveryDayDocument = gql `
    query WeeklyDeliveryDay {
  weeklyDeliveryDay {
    day
  }
}
    `;
export function useWeeklyDeliveryDayQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(WeeklyDeliveryDayDocument, options);
}
export function useWeeklyDeliveryDayLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(WeeklyDeliveryDayDocument, options);
}
export const CompleteMerchantOnboardingDocument = gql `
    mutation CompleteMerchantOnboarding {
  completeMerchantOnboarding {
    status
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useCompleteMerchantOnboardingMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CompleteMerchantOnboardingDocument, options);
}
export const UploadFileDocument = gql `
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    fileURL
    fileURLCompressed
  }
}
    `;
export function useUploadFileMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UploadFileDocument, options);
}
export const ServiceableDishesDocument = gql `
    query ServiceableDishes {
  serviceableDishes {
    dishes {
      ...DishInfo
    }
  }
}
    ${DishInfoFragmentDoc}`;
export function useServiceableDishesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ServiceableDishesDocument, options);
}
export function useServiceableDishesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ServiceableDishesDocument, options);
}
export const UploadFoodHandlerCertificateDocument = gql `
    mutation UploadFoodHandlerCertificate($input: UploadFoodHandlerCertificateInput) {
  uploadFoodHandlerCertificate(input: $input) {
    status
  }
}
    `;
export function useUploadFoodHandlerCertificateMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UploadFoodHandlerCertificateDocument, options);
}
export const MerchantDishCatalogDocument = gql `
    query MerchantDishCatalog($input: MerchantDishCatalogInput) {
  merchantDishCatalog(input: $input) {
    dishes {
      ...DishInfo
    }
  }
}
    ${DishInfoFragmentDoc}`;
export function useMerchantDishCatalogQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MerchantDishCatalogDocument, options);
}
export function useMerchantDishCatalogLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MerchantDishCatalogDocument, options);
}
export const RecommendationCatalogDocument = gql `
    query RecommendationCatalog($input: RecommendationCatalogInput) {
  recommendationCatalog(input: $input) {
    dishes {
      ...DishInfo
    }
    merchants {
      ...MerchantInfo
    }
  }
}
    ${DishInfoFragmentDoc}
${MerchantInfoFragmentDoc}`;
export function useRecommendationCatalogQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(RecommendationCatalogDocument, options);
}
export function useRecommendationCatalogLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(RecommendationCatalogDocument, options);
}
export const RecentlyAddedCatalogDocument = gql `
    query RecentlyAddedCatalog($input: RecentlyAddedCatalogInput) {
  recentlyAddedCatalog(input: $input) {
    dishes {
      ...DishInfo
    }
    merchants {
      ...MerchantInfo
    }
  }
}
    ${DishInfoFragmentDoc}
${MerchantInfoFragmentDoc}`;
export function useRecentlyAddedCatalogQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(RecentlyAddedCatalogDocument, options);
}
export function useRecentlyAddedCatalogLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(RecentlyAddedCatalogDocument, options);
}
export const MerchantExtraInfoDocument = gql `
    query MerchantExtraInfo($input: MerchantExtraInfoInput) {
  merchantExtraInfo(input: $input) {
    hasBio
    hasProfilePic
    hasFoodHandlersCertificate
    hasDeliveryHub
    status
  }
}
    `;
export function useMerchantExtraInfoQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MerchantExtraInfoDocument, options);
}
export function useMerchantExtraInfoLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MerchantExtraInfoDocument, options);
}
export const FilteredCatalogDocument = gql `
    query FilteredCatalog($input: FilteredCatalogInput) {
  filteredCatalog(input: $input) {
    dishes {
      ...DishInfo
    }
  }
}
    ${DishInfoFragmentDoc}`;
export function useFilteredCatalogQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(FilteredCatalogDocument, options);
}
export function useFilteredCatalogLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(FilteredCatalogDocument, options);
}
export const CatalogDocument = gql `
    query Catalog($input: CatalogInput) {
  catalog(input: $input) {
    dishes {
      ...DishInfo
    }
    merchants {
      ...MerchantInfo
    }
  }
}
    ${DishInfoFragmentDoc}
${MerchantInfoFragmentDoc}`;
export function useCatalogQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CatalogDocument, options);
}
export function useCatalogLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CatalogDocument, options);
}
export const ConnectToStripeDocument = gql `
    mutation ConnectToStripe {
  connectToStripe {
    stripeUrl
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useConnectToStripeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ConnectToStripeDocument, options);
}
export const PingStripeConnectionDocument = gql `
    mutation PingStripeConnection {
  pingStripeConnection {
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function usePingStripeConnectionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(PingStripeConnectionDocument, options);
}
export const CuisinesDocument = gql `
    query Cuisines {
  cuisines {
    cuisines
  }
}
    `;
export function useCuisinesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CuisinesDocument, options);
}
export function useCuisinesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CuisinesDocument, options);
}
export const GeneratePaymentClientSecretDocument = gql `
    mutation GeneratePaymentClientSecret {
  generatePaymentClientSecret {
    status
    clientSecret
  }
}
    `;
export function useGeneratePaymentClientSecretMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(GeneratePaymentClientSecretDocument, options);
}
export const GooglePlaceSearchDocument = gql `
    query GooglePlaceSearch($input: GooglePlaceSearchInput!) {
  googlePlaceSearch(input: $input) {
    status
    candidates {
      address
      placeId
    }
  }
}
    `;
export function useGooglePlaceSearchQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GooglePlaceSearchDocument, options);
}
export function useGooglePlaceSearchLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GooglePlaceSearchDocument, options);
}
export const CatalogFiltersDocument = gql `
    query CatalogFilters {
  catalogFilters {
    filters
  }
}
    `;
export function useCatalogFiltersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CatalogFiltersDocument, options);
}
export function useCatalogFiltersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CatalogFiltersDocument, options);
}
export const CatalogVendorsDocument = gql `
    query CatalogVendors($input: CatalogVendorsInput) {
  catalogVendors(input: $input) {
    merchants {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useCatalogVendorsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CatalogVendorsDocument, options);
}
export function useCatalogVendorsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CatalogVendorsDocument, options);
}
export const MerchantDocument = gql `
    query Merchant($input: MerchantInput) {
  merchant(input: $input) {
    merchant {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useMerchantQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MerchantDocument, options);
}
export function useMerchantLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MerchantDocument, options);
}
export const AllMerchantsDocument = gql `
    query AllMerchants {
  allMerchants {
    allMerchants {
      ...MerchantInfo
    }
  }
}
    ${MerchantInfoFragmentDoc}`;
export function useAllMerchantsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AllMerchantsDocument, options);
}
export function useAllMerchantsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AllMerchantsDocument, options);
}
export const CheckCouponDocument = gql `
    query CheckCoupon($input: CheckCouponInput) {
  checkCoupon(input: $input) {
    status
    coupon {
      ...CouponInfo
    }
  }
}
    ${CouponInfoFragmentDoc}`;
export function useCheckCouponQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CheckCouponDocument, options);
}
export function useCheckCouponLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CheckCouponDocument, options);
}
export const SetCartDocument = gql `
    mutation SetCart($input: SetCartInput) {
  setCart(input: $input) {
    status
  }
}
    `;
export function useSetCartMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SetCartDocument, options);
}
export const SetCurrentZipCodeDocument = gql `
    mutation SetCurrentZipCode($input: SetCurrentZipCodeInput) {
  setCurrentZipCode(input: $input) {
    status
  }
}
    `;
export function useSetCurrentZipCodeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SetCurrentZipCodeDocument, options);
}
export const AddPaymentMethodDocument = gql `
    mutation AddPaymentMethod($input: AddPaymentMethodInput) {
  addPaymentMethod(input: $input) {
    status
    paymentMethod {
      id
    }
  }
}
    `;
export function useAddPaymentMethodMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddPaymentMethodDocument, options);
}
export const RemovePaymentMethodDocument = gql `
    mutation RemovePaymentMethod($input: RemovePaymentMethodInput) {
  removePaymentMethod(input: $input) {
    status
  }
}
    `;
export function useRemovePaymentMethodMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RemovePaymentMethodDocument, options);
}
export const SetDefaultPaymentMethodDocument = gql `
    mutation SetDefaultPaymentMethod($input: SetDefaultPaymentMethodInput) {
  setDefaultPaymentMethod(input: $input) {
    status
  }
}
    `;
export function useSetDefaultPaymentMethodMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SetDefaultPaymentMethodDocument, options);
}
export const PlaceOrderDocument = gql `
    mutation PlaceOrder($input: PlaceOrderInput) {
  placeOrder(input: $input) {
    status
    order {
      id
    }
  }
}
    `;
export function usePlaceOrderMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(PlaceOrderDocument, options);
}
export const OrderDocument = gql `
    query Order($input: OrderInput!) {
  order(input: $input) {
    status
    order {
      ...OrderInfo
    }
  }
}
    ${OrderInfoFragmentDoc}`;
export function useOrderQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(OrderDocument, options);
}
export function useOrderLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(OrderDocument, options);
}
export const EditAccountInfoDocument = gql `
    mutation EditAccountInfo($input: EditAccountInfoInput) {
  editAccountInfo(input: $input) {
    status
  }
}
    `;
export function useEditAccountInfoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(EditAccountInfoDocument, options);
}
export const ChangePasswordDocument = gql `
    mutation ChangePassword($input: ChangePasswordInput) {
  changePassword(input: $input) {
    status
  }
}
    `;
export function useChangePasswordMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ChangePasswordDocument, options);
}
export const SearchDocument = gql `
    mutation Search($input: SearchInput) {
  search(input: $input) {
    merchants {
      ...MerchantInfo
    }
    dishes {
      ...DishInfo
    }
    status
  }
}
    ${MerchantInfoFragmentDoc}
${DishInfoFragmentDoc}`;
export function useSearchMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SearchDocument, options);
}
export const DishDocument = gql `
    query Dish($input: DishInput) {
  dish(input: $input) {
    dish {
      ...DishInfo
    }
    status
  }
}
    ${DishInfoFragmentDoc}`;
export function useDishQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(DishDocument, options);
}
export function useDishLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(DishDocument, options);
}
export const LogoutDocument = gql `
    mutation Logout {
  logout {
    status
  }
}
    `;
export function useLogoutMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(LogoutDocument, options);
}
export const ApplyAsMerchantDocument = gql `
    mutation ApplyAsMerchant {
  applyAsMerchant {
    status
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export function useApplyAsMerchantMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ApplyAsMerchantDocument, options);
}
export const AddressFromGooglePlaceDocument = gql `
    query AddressFromGooglePlace($input: AddressFromGooglePlaceInput!) {
  addressFromGooglePlace(input: $input) {
    ...AddressInfo
  }
}
    ${AddressInfoFragmentDoc}`;
export function useAddressFromGooglePlaceQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AddressFromGooglePlaceDocument, options);
}
export function useAddressFromGooglePlaceLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AddressFromGooglePlaceDocument, options);
}
export const ExploreMealsDocument = gql `
    query ExploreMeals($input: ExploreMealsInput!) {
  exploreMeals(input: $input) {
    dishes {
      ...DishInfo
    }
    status
  }
}
    ${DishInfoFragmentDoc}`;
export function useExploreMealsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ExploreMealsDocument, options);
}
export function useExploreMealsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ExploreMealsDocument, options);
}
export const SubmitReviewDocument = gql `
    mutation SubmitReview($input: SubmitReviewInput) {
  submitReview(input: $input) {
    status
    review {
      ...ReviewInfo
    }
  }
}
    ${ReviewInfoFragmentDoc}`;
export function useSubmitReviewMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SubmitReviewDocument, options);
}
export const ChangeDefaultDeliveryInstructionsDocument = gql `
    mutation ChangeDefaultDeliveryInstructions($input: ChangeDefaultDeliveryInstructionsInput) {
  changeDefaultDeliveryInstructions(input: $input) {
    status
  }
}
    `;
export function useChangeDefaultDeliveryInstructionsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ChangeDefaultDeliveryInstructionsDocument, options);
}
export const UpdateReviewDocument = gql `
    mutation UpdateReview($input: UpdateReviewInput) {
  updateReview(input: $input) {
    status
    review {
      ...ReviewInfo
    }
  }
}
    ${ReviewInfoFragmentDoc}`;
export function useUpdateReviewMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateReviewDocument, options);
}
export const OrdersSummaryDocument = gql `
    query OrdersSummary($input: OrdersSummaryInput) {
  ordersSummary(input: $input) {
    status
    orders {
      ...MerchantOrderInfo
    }
    orderCount
    customerCount
    dishesSoldCount
    tipsCollectedCents
    totalRevenueCents
    sales {
      dishName
      volume
    }
  }
}
    ${MerchantOrderInfoFragmentDoc}`;
export function useOrdersSummaryQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(OrdersSummaryDocument, options);
}
export function useOrdersSummaryLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(OrdersSummaryDocument, options);
}
export const MerchantOrderDocument = gql `
    query MerchantOrder($input: MerchantOrderInput!) {
  merchantOrder(input: $input) {
    status
    merchantOrder {
      ...MerchantOrderInfo
    }
  }
}
    ${MerchantOrderInfoFragmentDoc}`;
export function useMerchantOrderQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MerchantOrderDocument, options);
}
export function useMerchantOrderLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MerchantOrderDocument, options);
}
export const CashOutDocument = gql `
    mutation CashOut {
  cashOut {
    status
  }
}
    `;
export function useCashOutMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CashOutDocument, options);
}
export const DisconnectStripeDocument = gql `
    mutation DisconnectStripe {
  disconnectStripe {
    status
  }
}
    `;
export function useDisconnectStripeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DisconnectStripeDocument, options);
}
export const RemoveDishQuantityInCartDocument = gql `
    mutation RemoveDishQuantityInCart($input: RemoveDishQuantityInCartInput) {
  removeDishQuantityInCart(input: $input) {
    status
  }
}
    `;
export function useRemoveDishQuantityInCartMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RemoveDishQuantityInCartDocument, options);
}
export const RequestPasswordResetDocument = gql `
    mutation RequestPasswordReset($input: RequestPasswordResetInput) {
  requestPasswordReset(input: $input) {
    status
  }
}
    `;
export function useRequestPasswordResetMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RequestPasswordResetDocument, options);
}
export const ResetPasswordDocument = gql `
    mutation ResetPassword($input: ResetPasswordInput) {
  resetPassword(input: $input) {
    status
  }
}
    `;
export function useResetPasswordMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ResetPasswordDocument, options);
}
