export default {
    checkoutMinimum: 5000,
    taxRate: 0,
    ratingsVisibilityThreshold: 100,
    apiKeys: {
        stripe: {
            STRIPE_PUBLIC_KEY: "pk_live_51M0Y9dISRTkwEcZOqStBSe0nwbJzdmMC0CJsE0GdTxPBXG5AMAbU8hzyeyJD9TLODkCiPzshkoWz6RBHTJFgSFcK00fue1sYf8",
        },
        apple: {
            APPLE_CLIENT_ID: "com.siwa.neighbors",
            APPLE_CLIENT_SCOPE: "name email",
            APPLE_REDIRECT_URI: "https://neighbors.co/siwa",
        },
        google: {
            GOOGLE_CLIENT_ID: "238678879802-80mtnttinc4erspgja1tmcicm8cee8f5.apps.googleusercontent.com",
            GOOGLE_SCOPE: "email",
        },
        fb: {
            FB_APP_ID: "5994061027295090",
            FB_ADS_APP_ID: "431058584112062",
            FB_ADS_ACCESS_TOKEN: "EAAVML859dV4BOZCwqfpARTvrMDmMXXZB4VUAbPC6oOrIu33uue3NtH40RQxamXj3P6ntikyPITpw9sUDVYtEkYVz1cQtUyYpxOtZAi5KYkvODhN30XOaQlgWbx0104leBBv3GUDewOB9TUOkXoUZAPweOXcl0wGhtZCC2EsKlVBRgMYnISt9WJK5YOsYX9mXYD9XWIxDsCPpqLUozRBYPPK8QMJTZAPfd5K3CcisuSeTTbs8d3LHOotdsCyMeKt1jVsvkRjwZDZD",
            FB_CONVERSION_API_VERSION: "v18.0",
        },
    },
};
