import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import ButtonStyle from "../../../types/ButtonStyle";
import ElementSize from "../../../types/ElementSize";
import ATCButton from "../../global/ATCButton";
import CloseButton from "../../global/CloseButton";
import ItemCartQuantityWidget from "../../global/ItemCartQuantityWidget";
import MerchantPreview from "../../global/MerchantPreview";
import MerchantTag from "../../global/MerchantTag";
import SectionSeparator from "../../global/SectionSeparator";
import { usePostHog } from "posthog-js/react";
function DishHeader({ dish }) {
    return (_jsx("div", { className: "flex flex-col justify-center items-center bg-[#fbfaf9] md:rounded-t-[32px] py-[40px]", children: _jsx("div", { className: "h-[140px] sm:h-[160px] reg:h-[180px] md:h-[200px] w-full aspect-square flex justify-center items-center object-cover", children: _jsx("img", { src: dish.profile?.displayPicUrlCompressed ?? dish.profile?.displayPicUrl ?? "", className: "object-fit block h-full" }) }) }));
}
function DishDetailsSection({ header, body, capitalize, onClose, }) {
    return (_jsxs("div", { className: "flex flex-col items-stretch gap-[8px]", children: [_jsx("span", { className: "text-black opacity-40 text-[12px] font-bold uppercase", children: header }), _jsx("p", { className: "w-full flex-grow text-black text-[16px] font-medium " +
                    (capitalize ? "capitalize" : "normal-case"), children: body.toLowerCase() })] }));
}
function DishDetails({ dish, onClose }) {
    let allergiesComponents = dish.profile?.nutrition?.allergiesLabel ?? [];
    let allergiesLabel = allergiesComponents.map((x) => x.replace("_", " ").titleCase()).join(", ");
    let dietaryComponent = dish.profile?.nutrition?.dietaryLabel ?? [];
    let dietaryLabel = dietaryComponent.map((x) => x.replace("_", " ").titleCase()).join(", ");
    const ingredients = dish.profile?.nutrition?.ingredients ?? "";
    const displayName = dish.profile?.displayName ?? "";
    let portionLabel = dish.profile?.nutrition?.portionLabel ?? "";
    const portionComponents = portionLabel.split(" ");
    let portionSize = "";
    let portionServing = "";
    if (portionComponents.length == 2) {
        portionSize = portionComponents[0];
        portionServing = portionComponents[1].split("_").join(" ");
        portionLabel = [portionSize, portionServing].join(" ");
    }
    let description = dish.profile?.description ?? "";
    let ingredientsLabel = "";
    if (ingredients && ingredients.length > 0) {
        ingredientsLabel = ingredients.join(", ");
    }
    return (_jsxs("div", { className: "px-[32px] pb-[150px] md:pb-[24px] md:px-0 pt-[24px] h-[100%] flex flex-col gap-[32px] items-stretch md:overflow-y-scroll scrollbar-hide", children: [_jsxs("div", { className: "flex flex-col gap-[19px] items-stretch", children: [_jsx("span", { className: "text-black font-bold text-[20px] pr-[12px]", children: displayName }), description.length > 0 && (_jsx("span", { className: "text-black font-medium text-[16px]", children: description }))] }), ingredientsLabel.length > 0 && (_jsx(DishDetailsSection, { capitalize: true, header: "Main Ingredients", body: ingredientsLabel })), allergiesLabel.length > 0 && (_jsx(DishDetailsSection, { capitalize: true, header: "Allergens", body: allergiesLabel })), dietaryLabel.length > 0 && (_jsx(DishDetailsSection, { capitalize: true, header: "Dietary", body: dietaryLabel })), portionLabel.length > 0 && (_jsx(DishDetailsSection, { capitalize: true, header: "Portion size", body: portionLabel })), _jsx(MerchantPreview, { onClose: onClose, merchant: dish.merchant })] }));
}
function CatalogDishItemDetailDesktop({ dish, onClose, setCart, currentCatalogDate, }) {
    const [dishQuantity, setDishQuantity] = useState(1);
    return (_jsx("div", { onClick: onClose, className: "hidden fixed md:flex justify-center items-center top-0 z-[1000] left-0 w-full h-full bg-[rgba(0,0,0,0.8)]", children: _jsxs("div", { onClick: (e) => e.stopPropagation(), className: "flex flex-col justify-between bg-white rounded-[32px] w-[600px] max-h-[90vh] 2xl:aspect-[120/165] 4xl:h-auto", children: [_jsxs("div", { className: "w-full relative", children: [_jsx("div", { className: "z-[30] inline absolute mt-[24px] px-[24px] w-full", children: _jsxs("div", { className: "w-full flex flex-row justify-between", children: [_jsx(MerchantTag, { onClose: onClose, merchant: dish.merchant }), _jsx(CloseButton, { onClick: onClose })] }) }), _jsx(DishHeader, { dish: dish })] }), _jsx("div", { className: "px-[32px] w-full flex-grow overflow-y-scroll", children: _jsx(DishDetails, { onClose: onClose, dish: dish }) }), currentCatalogDate && (_jsxs("div", { className: "h-[96px] min-h-[96px] px-[32px] flex flex-col justify-center", children: [_jsx(SectionSeparator, {}), _jsxs("div", { className: "h-full flex flex-row justify-between items-center", children: [_jsx(ItemCartQuantityWidget, { forceQuantity: dishQuantity, onQuantityChange: setDishQuantity, bypassCartUpdate: true, size: ElementSize.M, dish: dish, setCart: setCart, deliveryDate: currentCatalogDate }), _jsx(ATCButton, { onClick: () => {
                                        onClose();
                                    }, buttonProps: {
                                        style: ButtonStyle.BLACK,
                                        bypassParentWidth: true,
                                        children: null,
                                    }, dish: dish, setCart: setCart, quantity: dishQuantity, deliveryDate: currentCatalogDate })] })] }))] }) }));
}
function CatalogDishItemDetailMobile({ dish, onClose, setCart, currentCatalogDate, }) {
    const [dishQuantity, setDishQuantity] = useState(1);
    return (_jsxs("div", { className: "w-full h-full md:hidden overflow-hidden flex flex-col bg-white z-[1000]", children: [_jsxs("div", { className: "pt-[20px] pb-[10px] flex flex-row justify-between items-center px-[32px]", children: [_jsx(CloseButton, { onClick: onClose }), _jsx(MerchantTag, { merchant: dish.merchant })] }), _jsx(DishHeader, { dish: dish }), _jsx(DishDetails, { onClose: onClose, dish: dish }), currentCatalogDate && (_jsxs("div", { className: "fixed h-[110px] bottom-0 w-full bg-white", children: [_jsx(SectionSeparator, {}), _jsxs("div", { className: "h-full px-[32px] flex flex-row justify-around gap-[16px] items-center", children: [_jsx("div", { className: "w-[42%] flex flex-row justify-center items-center", children: _jsx(ItemCartQuantityWidget, { forceQuantity: dishQuantity, onQuantityChange: setDishQuantity, bypassCartUpdate: true, size: ElementSize.M, dish: dish, setCart: setCart, deliveryDate: currentCatalogDate }) }), _jsx("div", { className: "w-[58%] flex flex-row justify-center items-center", children: _jsx("div", { className: "min-w-[150px] xs:min-w-[179px]", children: _jsx(ATCButton, { onClick: () => {
                                            onClose();
                                        }, buttonProps: {
                                            style: ButtonStyle.BLACK,
                                            bypassParentWidth: false,
                                            children: null,
                                        }, dish: dish, setCart: setCart, quantity: dishQuantity, deliveryDate: currentCatalogDate }) }) })] })] }))] }));
}
export default function CatalogDishItemDetail({ dish, onClose, setCart, currentCatalogDate, }) {
    const posthog = usePostHog();
    posthog?.capture("open_dish_detail", {
        dishId: dish.id,
        dishName: dish.profile?.displayName ?? "",
        priceCents: dish.priceCents,
        merchantId: dish.merchant.id,
        cuisine: dish.merchant.cuisine ?? undefined,
        item_category: dish.profile?.type ?? undefined,
    });
    return (_jsxs("div", { children: [_jsx(CatalogDishItemDetailDesktop, { setCart: setCart, dish: dish, onClose: onClose, currentCatalogDate: currentCatalogDate }), _jsx(CatalogDishItemDetailMobile, { setCart: setCart, dish: dish, onClose: onClose, currentCatalogDate: currentCatalogDate })] }));
}
