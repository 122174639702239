var ElementSize;
(function (ElementSize) {
    ElementSize["XXS"] = "XXS";
    ElementSize["XS"] = "XS";
    ElementSize["REG"] = "REG";
    ElementSize["S"] = "S";
    ElementSize["M"] = "M";
    ElementSize["L"] = "L";
    ElementSize["XL"] = "XL";
    ElementSize["XXL"] = "XXL";
})(ElementSize || (ElementSize = {}));
export default ElementSize;
