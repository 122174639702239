import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Input from "../../components/global/Input";
import Button from "../../components/global/Button";
import ButtonStyle from "../../types/ButtonStyle";
import { useAdminLoginLazyQuery } from "../../generated/graphql";
export default function AdminLogin({ onSuccess }) {
    const [password, setPassword] = useState(sessionStorage.getItem("admin-token") || "");
    const [loading, setLoading] = useState(false);
    const [adminLogin, adminLoginRes] = useAdminLoginLazyQuery({ fetchPolicy: "network-only" });
    const onLogin = () => {
        if (loading)
            return;
        setLoading(true);
        sessionStorage.setItem("admin-token", password);
        adminLogin()
            .then((val) => {
            const status = val.data?.adminLogin?.status;
            if (status === "OK")
                onSuccess();
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        if (sessionStorage.getItem("admin-token"))
            onLogin();
    }, []);
    return (_jsxs("form", { onSubmit: (e) => {
            e.preventDefault();
            onLogin();
        }, className: "m-4 flex-col max-w-sm items-center flex gap-2", children: [_jsx(Input, { required: true, type: "text", value: password, placeholder: "Enter token...", onInput: (e) => setPassword(e.target.value) }), _jsx(Button, { style: ButtonStyle.BLACK, children: "Use token" }), loading && _jsx("span", { className: "font-mono text-lg", children: "Loading..." })] }));
}
