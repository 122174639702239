import { gql } from "@apollo/client";
export const ADDRESS_INFO_FRAGMENT = gql `
  fragment AddressInfo on Address {
    streetAddress
    unitNumber
    countryCode
    state
    city
    zip
    id
    lat
    long
    formatted
    defaultDeliveryInstructions
  }
`;
export const COUPON_INFO_FRAGMENT = gql `
  fragment CouponInfo on Coupon {
    id
    code
    deduction
    type
    limit
    numClaims
    updatedAt
    createdAt
  }
`;
export const REVIEW_INFO_FRAGMENT = gql `
    fragment ReviewInfo on Review {
        rating
        body
        isDeleted
        isSystemHidden
        createdAt
        merchantOrderId
        user {
            profile {
                givenName
                familyName
            }
        }
    }
`;
export const MERCHANT_INFO_FRAGMENT = gql `
  ${REVIEW_INFO_FRAGMENT}
  fragment MerchantInfo on Merchant {
    id
    hasAddedDish
    cuisine
    email
    phoneNumber
    deliveryDates
    hasSignedGuidelines
    datesAvailable
    cutOffDates
    isOnboardingComplete
    isReadyForApproval
    approvalStatus
    isOutOfArea
    zipCode
    stripeAccountId
    isStripeEnabled
    reviews {
      ...ReviewInfo
    }
    cashouts {
      createdAt
      amountCents
    }
    wallet {
      balanceCents
      clearedBalanceCents
      inTransitCents
    }
    profile {
      aggregateRating
      numReviews
      numWrittenReviews
      givenName
      familyName
      displayPicUrl
      displayPicUrlCompressed
      headerPicUrl
      headerPicUrlCompressed
      bio
      displayName
      name
    }
    certification {
      imageUrl
      expiresAt
    }
    takeRate
    orders {
      earning {
        amountCents
      }
      cartItems {
        id
        quantity
        dish {
          id
          profile {
            id
            displayName
            displayPicUrl
            displayPicUrlCompressed
            type
            description
            nutrition {
              protein
              dietaryLabel
              allergiesLabel
              daysToExpire
              reheatingInstructions
              ingredients
              portionLabel
            }
          }
        }
      }
      order {
        # Cannot use fragments here to avoid cyclic dependencies.
        id
        createdAt
        orderNumber
        receipt {
          subtotalCents
          deliveryFeeCents
          taxCents
          feeCents
          driverTipCents
        }
        user {
          profile {
            givenName
            familyName
          }
        }
        delivery {
          deliveryDate
        }
      }
    }
  }
`;
export const DISH_INFO_FRAGMENT = gql `,
  ${MERCHANT_INFO_FRAGMENT},
  fragment DishInfo on Dish {
    id
    status
    isApproved
    priceCents
    numOrdersPlaced
    spice
    tags
    merchant {
      ...MerchantInfo
    }
    availability {
      id
      datesAvailable
      weeklyLimit
    }
    profile {
      id
      displayName
      displayPicUrl
      displayPicUrlCompressed
      type
      description
      nutrition {
        protein
        dietaryLabel
        allergiesLabel
        daysToExpire
        reheatingInstructions
        ingredients
        portionLabel
      }
    }
    weeklyLimit
  }
`;
export const MERCHANT_ORDER_INFO_FRAGMENT = gql `
  ${DISH_INFO_FRAGMENT}
  fragment MerchantOrderInfo on MerchantOrder {
    id
    createdAt
    updatedAt
    cartItems {
      id
      quantity
      dish {
        ...DishInfo
      }
    }
    order {
      # Cannot use fragments here to avoid cyclic dependencies.
      id
      createdAt
      orderNumber
      receipt {
        subtotalCents
        deliveryFeeCents
        taxCents
        feeCents
        driverTipCents
      }
      user {
        profile {
          givenName
          familyName
        }
      }
      delivery {
        deliveryDate
      }
    }
    earning {
      amountCents
    }
    review {
      id
      rating
      body
    }
    merchant {
      profile {
        displayPicUrl
        displayPicUrlCompressed
        displayName
        name
      }
    }
  }
`;
export const ORDER_INFO_FRAGMENT = gql `
  ${ADDRESS_INFO_FRAGMENT},
  ${MERCHANT_ORDER_INFO_FRAGMENT},
  ${DISH_INFO_FRAGMENT},
  fragment OrderInfo on Order {
    id
    merchantIds
    orderNumber
    merchantOrders {
      ...MerchantOrderInfo
    }
    createdAt
    cartItems {
      id
      quantity
      dish {
        id
        profile {
          id
          displayName
          displayPicUrl
          displayPicUrlCompressed
          type
          description
          nutrition {
            protein
            dietaryLabel
            allergiesLabel
            daysToExpire
            reheatingInstructions
            ingredients
            portionLabel
          }
        }
      }
    }
    currentStage {
      type
      isFulfilled
      isFailed
    }
    receipt {
      subtotalCents
      driverTipCents
      deliveryFeeCents
      taxCents
      feeCents
    }
    delivery {
      deliveryDate
      windowStart
      windowEnd
      deliveryInfo {
        fullName
        phoneContact
        deliveryInstructions
        address {
          ...AddressInfo
        }
      }
    }
    payment {
        amountCents
    }
    cancelation {
      cancelor
      cancelationMessage
      cancelationType
    }
    refund {
      refundedAmountCents
      refundReason
    }
  }
`;
export const USER_INFO_FRAGMENT = gql `
  ${DISH_INFO_FRAGMENT},
  ${ADDRESS_INFO_FRAGMENT},
  ${MERCHANT_ORDER_INFO_FRAGMENT},
  fragment UserInfo on User {
    id
    canMockOrders
    merchant {
      id
    }
    phoneNumber
    smsOrderUpdates
    email
    isEmailVerified
    isPhoneVerified
    zipCode
    isOnboardingComplete
    isDeleted
    isSuspended
    stripeAccountId
    stripeSubscriptionId
    orderHistory {
      orders {
        id
        receipt {
          subtotalCents
          driverTipCents
          deliveryFeeCents
          taxCents
          feeCents    
        }
        currentStage {
          type
          isFulfilled
          isFailed
        }
        createdAt
      }
      merchantOrders {
        ...MerchantOrderInfo
      }
      items {
        quantity
        merchantOrder {
          id
        }
      }
    }
    auth {
      provider
      identifier
      refreshToken
      phoneNumber
    }
    profile {
      givenName
      familyName
      birthday
      countryCode
      createdAt
    }
    cartItems {
      dish {
        ...DishInfo
      }
      quantity
      updatedAt
    }
    paymentMethods {
      id
      createdAt
      updatedAt
      isDefault
      paypalId
      stripeId
      brand
      title
      subtitle
      icon
    }
    lastDeliveryPhone
    lastDeliveryName
    lastDeliveryInstructions
    addresses {
      ...AddressInfo
    }
  }
`;
