import { jsx as _jsx } from "react/jsx-runtime";
import FacebookLogin from "react-facebook-login";
import constants from "../../../lib/util/constants";
import AuthenticateButton, { AuthenticateButtonType } from "../AuthenticateButton";
export default function AuthenticateWithFacebookMobile({ isMerchant, onAccessToken, isLoading, }) {
    return (_jsx(FacebookLogin, { isMobile: false, appId: constants.apiKeys.fb.FB_APP_ID, scope: "public_profile,email", callback: (data) => {
            const res = data;
            const err = data;
            if (res && res.accessToken && res.accessToken.length) {
                onAccessToken(res.accessToken);
            }
            else {
                alert("Facebook sign in error");
                console.log("Sign in with Facebook err: ");
                console.log(err);
            }
        }, render: (renderProps) => (_jsx(AuthenticateButton, { showSpinner: isLoading, type: AuthenticateButtonType.FACEBOOK, onClick: renderProps.onClick })) }));
}
