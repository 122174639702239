import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import { useEffect, useState } from "react";
import AdminLogin from "../../components/admin/AdminLogin";
import { useFetchStatisticsLazyQuery } from "../../generated/graphql";
function getDates(minus) {
    const startDate = moment().subtract(minus, "days").toDate();
    const stopDate = new Date();
    const dates = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dates.push(currentDate.toISOString().split("T").shift());
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dates;
}
export default function Statistics() {
    const [loggedIn, setLoggedIn] = useState(false);
    const onSubmit = () => setLoggedIn(true);
    const [startDate, setStartDate] = useState(getDates(7).shift());
    const [endDate, setEndDate] = useState(getDates(1).pop());
    const [orderBy, setOrderBy] = useState("reach");
    const [orderDirection, setOrderDirection] = useState("DESC");
    const [startDateCompare, setStartDateCompare] = useState("");
    const [endDateCompare, setEndDateCompare] = useState("");
    const [fetchStatistics, fetchStatisticsRes] = useFetchStatisticsLazyQuery();
    const [data, setData] = useState([]);
    useEffect(() => {
        const now = new Date().toISOString().split("T").shift();
        fetchStatistics({
            variables: {
                input: {
                    startDate: startDate || now,
                    endDate: endDate || now,
                    orderBy,
                    orderDirection,
                },
            },
        }).then((r) => {
            setData(r.data?.fetchStatistics?.statistics || []);
        });
        const daysDifference = moment(endDate).diff(moment(startDate), "days");
        setStartDateCompare(moment(startDate)
            .subtract(daysDifference, "days")
            .toDate()
            .toISOString()
            .split("T")
            .shift() || "");
        setEndDateCompare(moment(endDate).subtract(daysDifference, "days").toDate().toISOString().split("T").shift() ||
            "");
    }, [startDate, endDate, orderBy, orderDirection]);
    const THEAD_TD = "border border-black";
    const TBODY_TD = "border border-black";
    if (!loggedIn)
        return _jsx(AdminLogin, { onSuccess: onSubmit });
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex gap-1", children: [_jsxs("label", { className: "flex flex-col", children: [_jsx("span", { children: "Start date" }), _jsx("select", { onInput: (e) => setStartDate(e.target.value), value: startDate, children: getDates(30).map((dateString) => (_jsx("option", { disabled: moment(dateString).isAfter(moment(endDate)), value: dateString, children: dateString }, dateString))) })] }), _jsxs("label", { className: "flex flex-col", children: [_jsx("span", { children: "End date" }), _jsx("select", { onInput: (e) => setEndDate(e.target.value), value: endDate, children: getDates(30).map((dateString) => (_jsx("option", { disabled: moment(dateString).isBefore(moment(startDate)), value: dateString, children: dateString }, dateString))) })] }), _jsxs("span", { children: ["(Comparing to: ", startDateCompare, " - ", endDateCompare, ")"] }), fetchStatisticsRes.loading && _jsx("span", { className: "text-xl font-bold", children: "Loading..." })] }), _jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: [
                                "name",
                                "vendorName",
                                "imageUrl",
                                "reach",
                                "impressions",
                                "clicks",
                                "ctr",
                                "atc",
                                "atcRate",
                                "checkoutsReached",
                                "purchases",
                                "purchaseValue",
                            ].map((x) => (_jsx("td", { onClick: () => {
                                    setOrderBy(x);
                                    setOrderDirection((x) => (x === "ASC" ? "DESC" : "ASC"));
                                }, className: THEAD_TD, children: x }))) }) }), _jsx("tbody", { children: data.map((x) => {
                            return (_jsxs("tr", { children: [_jsx("td", { className: TBODY_TD, children: x.name }), _jsx("td", { className: TBODY_TD, children: x.vendorName }), _jsx("td", { className: TBODY_TD, children: _jsx("img", { src: x.imageUrl || "", className: "w-8 h-8" }) }), _jsxs("td", { className: TBODY_TD, children: [x.values?.reach?.main, " (", x.values?.reach?.compare, ")"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.impressions?.main, " (", x.values?.impressions?.compare, ")"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.clicks?.main, " (", x.values?.clicks?.compare, ")"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.ctr?.main?.toFixed(2), "% (", x.values?.ctr?.compare?.toFixed(2), "%)"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.atc?.main, " (", x.values?.atc?.compare, ")"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.atcRate?.main?.toFixed(2), "% (", x.values?.atcRate?.compare?.toFixed(2), "%)"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.checkoutsReached?.main, " (", x.values?.checkoutsReached?.compare, ")"] }), _jsxs("td", { className: TBODY_TD, children: [x.values?.purchases?.main, " (", x.values?.purchases?.compare, ")"] }), _jsxs("td", { className: TBODY_TD, children: ["$", (x.values?.purchaseValue?.main || 0) / 100, " ($", (x.values?.purchaseValue?.compare || 0) / 100, ")"] })] }, x.key));
                        }) })] })] }));
}
