import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import ElementSize from "../../../types/ElementSize";
export default function Avatar({ image, size }) {
    return (_jsx("div", { className: classNames("rounded-full bg-[#F5F5F5] aspect-square", {
            "w-14": size == ElementSize.M || size == undefined,
            "w-[96px]": size == ElementSize.L,
            "w-[26px]": size == ElementSize.S,
            "w-[40px]": size == ElementSize.REG,
        }), children: image }));
}
