import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as pdfjsLib from "pdfjs-dist";
import printJS from "print-js";
import { useEffect, useState } from "react";
import { printLabel } from "react-dymo-hooks";
import Button from "../../components/global/Button";
import generateOrderLabelPDF, { generateSingleOrderLabelPDFs, } from "../../lib/util/generateOrderLabelPDF";
import generateOrderLabelXML from "../../lib/util/generateOrderLabelXML";
import ButtonStyle from "../../types/ButtonStyle";
import ElementSize from "../../types/ElementSize";
pdfjsLib.GlobalWorkerOptions.workerSrc =
    "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";
const DefaultPrinterNames = ["Inkjet", "Laser printer"];
export default function SingleVendor({ showIrrelevantVendors, merchant, selectedPrinter, updateOrders, }) {
    const [operatingDeliveryDate, setOperatingDeliveryDate] = useState(null);
    useEffect(() => {
        if (operatingDeliveryDate)
            updateOrders(operatingDeliveryDate);
    }, [operatingDeliveryDate]);
    if (merchant.orders.length === 0 && !showIrrelevantVendors)
        return null;
    return (_jsxs("div", { className: "flex items-center gap-2", children: [_jsx("div", { className: "relative", children: _jsx("select", { className: "p-2 min-w-[128px] text-center border rounded shadow-inner", onInput: (e) => {
                        const el = e.target;
                        setOperatingDeliveryDate(el.value);
                    }, children: merchant.merchant.deliveryDates.map((opt) => {
                        return (_jsx("option", { value: opt, children: opt.replace(/T(\d){2}:00:00.000Z/, "") }, merchant.merchant.id + opt));
                    }) }) }), _jsx(Button, { size: ElementSize.S, style: ButtonStyle.BLACK, isEnabled: selectedPrinter != "Dymo" && merchant.orders.length > 0, className: "!px-4 !w-auto whitespace-nowrap", onClick: async () => {
                    if (DefaultPrinterNames.includes(selectedPrinter)) {
                        printJS({
                            printable: URL.createObjectURL(await generateOrderLabelPDF(merchant.orders)),
                            type: "pdf",
                        });
                    }
                    else {
                        let blobs = await generateSingleOrderLabelPDFs(merchant.orders);
                        for (let blob of blobs) {
                            let document = await pdfjsLib.getDocument(URL.createObjectURL(blob)).promise;
                            let page = await document.getPage(1);
                            var scale = 4;
                            var viewport = page.getViewport({
                                scale: scale,
                            });
                            const canvas = window.document.createElement("canvas");
                            var context = canvas.getContext("2d");
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            var renderContext = {
                                canvasContext: context,
                                viewport: viewport,
                            };
                            await page.render(renderContext).promise;
                            let pngData = canvas.toDataURL().split(";base64,")[1];
                            let labelXml = generateOrderLabelXML(pngData);
                            const response = await printLabel(selectedPrinter, labelXml);
                        }
                    }
                }, children: "Print all labels" }), _jsx("span", { className: "font-bold text-lg", children: merchant.merchant.profile.name })] }));
}
