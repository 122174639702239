import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import ExclamationIcon from "../../../assets/icons/exclamation.svg";
import ElementSize from "../../../types/ElementSize";
export default function InputLabel({ htmlFor, text, showRequiredError, labelSize, labelElement, }) {
    if (!labelSize)
        labelSize = ElementSize.M;
    return (_jsxs("div", { className: "flex gap-[12px] whitespace-nowrap items-center", children: [_jsx("label", { htmlFor: htmlFor, className: classNames(`text-[14px] leading-[20px] font-[500]`, {
                    "md:text-[18px] md:leading-[28px]": labelSize === ElementSize.L,
                }), children: labelElement ?? text }), showRequiredError ? (_jsxs("div", { className: classNames("flex gap-[4px] text-red-500 text-[14px] leading-[20px] font-[500] items-center transition-all duration-200", {
                    "opacity-1": showRequiredError,
                    "opacity-0 pointer-events-none": !showRequiredError,
                }), children: [_jsx(ExclamationIcon, { width: "14", height: "14", className: "text-red-500" }), _jsxs("span", { children: [text, " is required"] })] })) : null] }));
}
