import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useRef, useState } from "react";
import { randomString } from "../../../lib/util/utils";
import ElementSize from "../../../types/ElementSize";
import InputLabel from "../InputLabel";
export default function Input({ label, onInput, required, placeholder, value, suffix, suffixElement, size, labelSize, type, showRequiredError, disabled, textbox, labelElement, }) {
    if (!size)
        size = ElementSize.M;
    const [dirty, setDirty] = useState(false);
    const id = useRef(label ? label.toLowerCase().replace(/[^a-z]/g, "_") + "_" + randomString() : randomString());
    const requiredError = dirty && required && !value && showRequiredError;
    return (_jsxs("div", { className: "w-full relative flex gap-[8px] flex-col", children: [label ? (_jsx(InputLabel, { labelSize: labelSize, showRequiredError: requiredError, htmlFor: id.current, text: label, labelElement: labelElement })) : null, !textbox && (_jsx("input", { disabled: disabled, type: type, id: id.current, required: required, onInput: (e) => {
                    setDirty(true);
                    onInput(e);
                }, placeholder: requiredError ? "" : placeholder, value: value, className: classNames("truncate w-full figma-shadow rounded-[12px] resize-none min-w-0 border transition-all duration-200 border-[#D1D5DB] font-[500]", {
                    "bg-[#f1e1e1] border-[#E94848]": requiredError,
                    "pr-[64px]": suffix,
                    "text-[24px] leading-[32px] md:text-[36px] md:leading-[40px] font-bold py-[12px] px-[16px]": size === ElementSize.L,
                    "py-[16px] px-[24px] text-[16px] leading-[24px] md:text-[18px] md:leading-[28px]": size === ElementSize.M,
                }) })), textbox && (_jsx("textarea", { disabled: disabled, id: id.current, required: required, onInput: (e) => {
                    setDirty(true);
                    onInput(e);
                }, placeholder: requiredError ? "" : placeholder, value: value, className: classNames("truncate w-full figma-shadow rounded-[12px] resize-none min-w-0 border transition-all duration-200 border-[#D1D5DB] font-[500]", {
                    "bg-[#f1e1e1] border-[#E94848]": requiredError,
                    "pr-[64px]": suffix,
                    "text-[24px] leading-[32px] md:text-[36px] md:leading-[40px] font-bold py-[12px] px-[16px]": size === ElementSize.L,
                    "py-[16px] px-[24px] text-[16px] leading-[24px] md:text-[18px] md:leading-[28px]": size === ElementSize.M,
                }) })), suffix && !suffixElement ? (_jsx("span", { className: "font-[500] text-[16px] leading-[24px] md:text-[18px] md:leading-[28px] absolute right-[20px] bottom-[18px]", children: suffix })) : null, suffixElement ? (_jsx("span", { className: "leading-[24px] md:text-[18px] md:leading-[28px] absolute right-[20px] bottom-[18px]", children: suffixElement })) : null] }));
}
