import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import ElementSize from "../../../types/ElementSize";
import Avatar from "../Avatar";
import ShorthandRating from "../Reviews/ShorthandRating";
export default function MerchantPreview({ merchant, onClose, }) {
    const bio = merchant.profile?.bio ?? "";
    let name = merchant.profile?.name ?? "";
    const navigate = useNavigate();
    return (_jsxs("div", { onClick: () => {
            navigate("/merchant/" + merchant.id);
            onClose && onClose();
        }, className: "cursor-pointer flex flex-col gap-[18px] items-stretch", children: [_jsxs("div", { className: "flex-grow flex flex-row gap-[16px] items-center", children: [_jsx(Avatar, { size: ElementSize.REG, image: _jsx("img", { className: "w-full h-full block object-cover rounded-full", src: merchant.profile?.displayPicUrlCompressed ?? merchant.profile?.displayPicUrl ?? "" }) }), _jsxs("div", { className: "flex flex-col gap-[9px] items-start", children: [_jsxs("span", { className: "uppercase font-bold text-[12px] text-black opacity-40", children: ["About ", name] }), _jsx(ShorthandRating, { merchant: merchant })] })] }), bio.length > 0 && _jsx("p", { className: "flex-grow font-medium text-[16px] text-[#999999]", children: bio })] }));
}
