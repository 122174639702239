import { jsx as _jsx } from "react/jsx-runtime";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./lib/util/extensions";
import "normalize.css";
const uri = "https://api.neighbors.co/graphql";
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    const adminToken = sessionStorage.getItem("admin-token");
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
            "admin-token": adminToken,
        },
    };
});
const uploadLink = createUploadLink({ uri: uri });
const apollo = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache(),
});
const render = (root) => {
    root.render(_jsx(ApolloProvider, { client: apollo, children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
};
const container = document.getElementById("root");
const root = createRoot(container);
render(root);
