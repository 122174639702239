import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useContext } from "react";
import addDishToCart from "../../../lib/util/cart/addDishToCart";
import decrementDishFromCart from "../../../lib/util/cart/decrementDishFromCart";
import dishQuantityInCart from "../../../lib/util/cart/dishQuantityInCart";
import ElementSize from "../../../types/ElementSize";
import Minus from "../Minus";
import Plus from "../Plus";
import { LocalCartContext } from "../../../contexts";
export default function ItemCartQuantityWidget({ size, setCart, dish, bypassCartUpdate, forceQuantity, onQuantityChange, deliveryDate, }) {
    const cart = useContext(LocalCartContext);
    const quantity = forceQuantity ? forceQuantity : dishQuantityInCart(dish, cart);
    return (_jsxs("div", { className: classNames("rounded-full bg-white flex flex-row items-center shadow-lg shadow-[#0000001b]", {
            "h-[48px] px-[15px] gap-[25px]": size == ElementSize.M,
            "h-[32px] px-[10px] gap-[16.66px]": size == ElementSize.S,
        }), onClick: (e) => {
            e.stopPropagation();
        }, children: [_jsx(Minus, { size: size, onClick: () => {
                    if (onQuantityChange)
                        onQuantityChange(Math.max(quantity - 1, 0));
                    if (!bypassCartUpdate) {
                        decrementDishFromCart(dish, cart, setCart, deliveryDate);
                    }
                } }), _jsx("span", { className: classNames("font-medium text-black text-center", {
                    "min-w-[16px] text-[24px]": size == ElementSize.M,
                    "min-w-[12px] text-[16px]": size == ElementSize.S,
                }), children: quantity }), _jsx(Plus, { size: size, onClick: () => {
                    if (onQuantityChange)
                        onQuantityChange(quantity + 1);
                    if (!bypassCartUpdate) {
                        addDishToCart(dish, cart, setCart, deliveryDate);
                    }
                } })] }));
}
