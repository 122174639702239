import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { LocalCartContext } from "../../../contexts";
import dishQuantityInCart from "../../../lib/util/cart/dishQuantityInCart";
import setDishQuantityInCart from "../../../lib/util/cart/setDishQuantityInCart";
import { formatCurrency } from "../../../lib/util/utils";
import Button from "../Button";
export default function ATCButton({ buttonProps, dish, quantity, setCart, onClick, deliveryDate, }) {
    const cart = useContext(LocalCartContext);
    const priceCents = quantity * dish.priceCents;
    const priceStr = formatCurrency(priceCents / 100, 2);
    let longformCTA = "Add to cart - " + priceStr;
    let shortformCTA = "Add - " + priceStr;
    return (_jsxs("div", { onClick: (e) => {
            const existingQuantity = dishQuantityInCart(dish, cart);
            const newQuantity = Math.max(1, quantity) + existingQuantity;
            e.stopPropagation();
            setDishQuantityInCart(newQuantity, dish, cart, setCart, deliveryDate);
            if (onClick)
                onClick();
        }, children: [_jsx("div", { className: "hidden xs:block", children: _jsx(Button, { ...buttonProps, children: longformCTA }) }), _jsx("div", { className: "block xs:hidden", children: _jsx(Button, { ...buttonProps, children: shortformCTA }) })] }));
}
