import { jsx as _jsx } from "react/jsx-runtime";
import ApplyAsVendor from "../../../components/merchant/Lander/ApplyAsVendor";
export var ApplyViewSource;
(function (ApplyViewSource) {
    ApplyViewSource[ApplyViewSource["LANDER"] = 0] = "LANDER";
    ApplyViewSource[ApplyViewSource["PRIVATE"] = 1] = "PRIVATE";
})(ApplyViewSource || (ApplyViewSource = {}));
export default function ApplyView({ onClose, source, skipOnboarding, onRegister, initialUser, }) {
    return (_jsx("div", { onClick: onClose, className: "fixed bg-black/80 z-[1000] inset-0 overflow-hidden flex items-center justify-center", children: _jsx("div", { onClick: (e) => e.stopPropagation(), className: "w-full md:rounded-[24px] max-md:h-full bg-white overflow-hidden md:px-[32px] md:max-w-[384px] m-auto pt-5 md:pb-[32px]", children: _jsx(ApplyAsVendor, { initialUser: initialUser, onRegister: onRegister, source: source, onClose: onClose, skipOnboarding: skipOnboarding }) }) }));
}
