import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import ElementSize from "../../../types/ElementSize";
import Avatar from "../Avatar";
export default function MerchantTag({ merchant, onClose, }) {
    const navigate = useNavigate();
    return (_jsxs("div", { onClick: () => {
            navigate("/merchant/" + merchant.id);
            onClose && onClose();
        }, className: "cursor-pointer flex flex-row items-center gap-[8px] py-[7px] pl-[7px] pr-[12px] rounded-full bg-white border-[#F5F5F5] border-[2px]", children: [_jsx(Avatar, { size: ElementSize.S, image: _jsx("img", { className: "w-full h-full block object-cover rounded-full", src: merchant.profile?.displayPicUrlCompressed ?? merchant.profile?.displayPicUrl ?? "" }) }), _jsx("span", { className: "text-black font-medium text-[16px]", children: merchant.profile?.name ?? "" })] }));
}
