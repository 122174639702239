export default function decrementDishFromCart(dish, cart, setCart, deliveryDate) {
    const matchingIdx = cart.findIndex((x) => x.dish.id == dish.id);
    if (matchingIdx > -1) {
        let newCart = [...cart];
        newCart[matchingIdx].quantity -= 1;
        newCart[matchingIdx].addedAt = new Date();
        if (newCart[matchingIdx].quantity <= 0) {
            newCart.splice(matchingIdx, 1);
        }
        setCart(newCart);
    }
}
