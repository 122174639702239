export default function RedirectToWebflow({ replace }) {
    if (window.location.href.includes("localhost")) {
        return null;
    }
    if (replace)
        window.location.replace("https://neighbors.co");
    else
        window.location.href = "https://neighbors.co";
    return null;
}
