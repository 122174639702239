import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sha256 } from "js-sha256";
import { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import CloseButton from "../../../assets/icons/cross-gray.svg";
import Button from "../../../components/global/Button";
import Input from "../../../components/global/Input";
import ButtonStyle from "../../../types/ButtonStyle";
import ElementSize from "../../../types/ElementSize";
import { useEnlistMutation } from "../../../generated/graphql";
import constants from "../../../lib/util/constants";
import { setEmailCookie } from "../../../lib/util/emailCookieUtils";
import isValidEmail from "../../../lib/util/validation/isValidEmail";
export default function EnlistForm({ onClose, onEnlist, }) {
    const [email, setEmail] = useState("");
    const [enlist, enlistUserRes] = useEnlistMutation();
    const FB_URI = `https://graph.facebook.com/v15.0/${constants.apiKeys.fb.FB_ADS_APP_ID}/events?access_token=${constants.apiKeys.fb.FB_ADS_ACCESS_TOKEN}`;
    useEffect(() => {
        const current_timestamp = Math.floor(new Date() / 1000);
        fetch(FB_URI, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: [
                    {
                        event_id: "submit.application",
                        event_name: "Submit application",
                        event_time: current_timestamp,
                        action_source: "website",
                        event_source_url: "https://neighbors.co/home/enlist",
                        user_data: {
                            client_user_agent: `${browserName} ${browserVersion}`,
                            em: [null],
                            ph: [null],
                        },
                    },
                ],
            }),
        })
            .catch((error) => { })
            .then((res) => { });
    }, []);
    useEffect(() => {
        if (enlistUserRes.data || enlistUserRes.error) {
            onEnlist();
        }
    }, [enlistUserRes.data, enlistUserRes.error, onEnlist]);
    return (_jsxs("div", { className: "h-[100vh] md:h-auto bg-white md:bg-transparent flex flex-col md:w-[778px] w-full md:flex-row items-stretch md:max-w-[1080px]", children: [_jsx("div", { className: "md:rounded-l-[32px] w-full md:w-1/2 md:float-left bg-[url('./assets/images/consumer-lander-modal-left.png')] bg-cover bg-center bg-no-repeat" }), _jsx("div", { className: "md:hidden w-full h-1/3 max-h-[450px] bg-[url('./assets/images/consumer-lander-modal-left.png')] bg-cover bg-center bg-no-repeat" }), _jsxs("div", { className: "md:h-full w-full md:w-1/2 md:rounded-r-[32px] float-right flex flex-col gap-[30px] p-[32px] bg-white", children: [_jsxs("div", { className: "flex flex-row justify-between", children: [_jsx("span", { className: "font-bold text-[20px] text-black", children: "Get an invite" }), onClose && _jsx(CloseButton, { className: "cursor-pointer z-[10]", onClick: onClose })] }), _jsxs("p", { className: "text-[#999999] font-medium text-[16px]", children: ["Due to high demand, Neighbors", _jsx("br", {}), "is slowly accepting new customers."] }), _jsxs("p", { className: "text-[#999999] font-medium text-[16px]", children: ["Request an invite and we'll notify", _jsx("br", {}), "once your magical food experience is ready."] }), _jsxs("div", { className: "flex flex-col gap-[30px]", children: [_jsx(Input, { placeholder: "john.smith@gmail.com", required: true, onInput: (e) => {
                                    setEmail(e.target.value);
                                }, label: "Email address", value: email, type: "email", showRequiredError: false }), _jsx("div", { className: "pb-[18px] md:pb-0", children: _jsx(Button, { size: ElementSize.L, isEnabled: isValidEmail(email) == true, style: ButtonStyle.BLACK, noBorder: true, showSpinner: enlistUserRes.loading, onClick: () => {
                                        enlist({
                                            variables: {
                                                input: {
                                                    email: email,
                                                },
                                            },
                                        });
                                        setEmailCookie(email);
                                        const current_timestamp = Math.floor(new Date() / 1000);
                                        fetch(FB_URI, {
                                            method: "POST",
                                            headers: {
                                                Accept: "application/json",
                                                "Content-Type": "application/json",
                                            },
                                            body: JSON.stringify({
                                                data: [
                                                    {
                                                        event_id: "complete.registration",
                                                        event_name: "Complete registration",
                                                        event_time: current_timestamp,
                                                        action_source: "website",
                                                        event_source_url: "https://neighbors.co/submit",
                                                        user_data: {
                                                            client_user_agent: `${browserName} ${browserVersion}`,
                                                            em: [sha256(email.trim().toLowerCase())],
                                                            ph: [null],
                                                        },
                                                    },
                                                ],
                                            }),
                                        })
                                            .catch((error) => { })
                                            .then((res) => { });
                                    }, children: "Request" }) })] })] })] }));
}
