import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import mapboxgl from "mapbox-gl";
import React, { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { DishStatus, MerchantApprovalStatus, useAddClickedStatisticMutation, useCurrentMerchantQuery, useCurrentUserQuery, useRemoveDishQuantityInCartMutation, useSetCartMutation, useWeeklyDeliveryDayQuery, } from "./generated/graphql";
import { parseCurrentMerchant, parseCurrentUser, parseWeeklyDeliveryDay } from "./api/parse";
import { AuthenticateViewSource } from "./components/consumer/Authentication/Authenticate";
import LoadingView from "./components/global/LoadingView";
import CongratulationsOnDish from "./components/merchant/AddDishView/CongratulationsOnDish";
import EnlistView from "./pages/consumer/lander/EnlistView";
import Privacy from "./pages/legal/Privacy";
import Terms from "./pages/legal/Terms";
import mapboxAccessToken from "./config/mapboxAccessToken";
import { ExpandedDishContext, LocalCartContext } from "./contexts";
import { ApplyViewSource } from "./pages/merchant/lander/ApplyView";
import CatalogDishItemDetail from "./components/consumer/Catalog/CatalogDishItemDetail";
import RedirectToWebflow from "./components/global/RedirectToWebflow";
import getRemoteCartPayload from "./lib/util/cart/getRemoteCartPayload";
import getCatalogDates from "./lib/util/getCatalogDates";
import getWindowDimensions from "./lib/util/getWindowDimensions";
import jsonReviver from "./lib/util/jsonReviver";
import Statistics from "./pages/admin/Statistics";
import VendorLabelExport from "./pages/admin/VendorLabelExport";
import { PostHogProvider } from "posthog-js/react";
const ConsumerCatalogView = lazy(() => import("./pages/consumer/ConsumerCatalogView"));
const AccountView = lazy(() => import("./pages/consumer/AccountView"));
const CheckoutFlow = lazy(() => import("./pages/consumer/CheckoutFlow"));
const ConsumerOrdersView = lazy(() => import("./pages/consumer/ConsumerOrdersView"));
const ConsumerWalletView = lazy(() => import("./pages/consumer/ConsumerWalletView"));
const OrderDetailView = lazy(() => import("./pages/consumer/OrderDetailView"));
const ConsumerHomeView = lazy(() => import("./pages/consumer/ConsumerHomeView"));
const MerchantView = lazy(() => import("./pages/consumer/MerchantView"));
const ResetPassword = lazy(() => import("./pages/consumer/ResetPassword"));
const AuthenticateView = lazy(() => import("./components/consumer/Authentication/AuthenticateView"));
const MerchantHomeView = lazy(() => import("./pages/merchant/MerchantHomeView"));
const MenuView = lazy(() => import("./pages/merchant/MenuView"));
const OnboardingView = lazy(() => import("./pages/merchant/OnboardingView"));
const OutOfAreaView = lazy(() => import("./pages/merchant/OutOfAreaView"));
const CalendarView = lazy(() => import("./pages/merchant/CalendarView"));
const ProfileView = lazy(() => import("./pages/merchant/ProfileView"));
const ReviewsView = lazy(() => import("./pages/merchant/ReviewsView"));
const PreApprovalCenter = lazy(() => import("./pages/merchant/PreApprovalCenter"));
const MerchantOrdersView = lazy(() => import("./pages/merchant/MerchantOrdersView"));
const MerchantWalletView = lazy(() => import("./pages/merchant/MerchantWalletView"));
const MerchantLanderView = lazy(() => import("./pages/merchant/lander/LanderView"));
const PrivateSignUp = lazy(() => import("./pages/merchant/PrivateSignUp"));
const ApplyView = lazy(() => import("./pages/merchant/lander/ApplyView"));
const enableConsumer = true;
const defaultToConsumerLander = true;
posthog.init("phc_4lESG4Ucw4AMpYDPCm1HfUn1qew8cIjrZwMrkUEkRAy", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
});
mapboxgl.accessToken = mapboxAccessToken;
export default function App() {
    const navigate = useNavigate();
    const [hasLoadedApp, setHasLoadedApp] = useState(false);
    const previousScrollY = useRef(0);
    const [cart, setCart] = useState([]);
    const [expandedDish, setExpandedDish] = useState(null);
    const [addClickedStatistic, _] = useAddClickedStatisticMutation();
    useEffect(() => {
        const storedEncodedCart = localStorage.getItem("cart");
        if (storedEncodedCart) {
            setCart(JSON.parse(storedEncodedCart, jsonReviver));
        }
        if (window.location.href.includes("disable-minimum=true")) {
            sessionStorage.setItem("disable-minimum", "true");
        }
        if (window.location.href.includes("disable-redirects=true")) {
            sessionStorage.setItem("disable-redirects", "true");
        }
    }, []);
    const [hasInitSentry, setHasInitSentry] = useState(false);
    useEffect(() => {
        if (expandedDish) {
            addClickedStatistic({ variables: { input: { dishId: expandedDish.id } } });
            const url = new URL(window.location.href);
            url.searchParams.set("dish", expandedDish.id);
            window.history.pushState(null, "", url.toString());
        }
        else {
            const url = new URL(window.location.href);
            url.searchParams.delete("dish");
            window.history.pushState(null, "", url.toString());
        }
    }, [expandedDish]);
    useEffect(() => {
        const windowSize = getWindowDimensions();
        if (windowSize.width > 818)
            return;
        if (expandedDish) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "auto",
            });
        }
        else {
            window.scrollTo({
                top: previousScrollY.current,
                left: 0,
                behavior: "auto",
            });
        }
    }, [expandedDish]);
    useMemo(() => {
        if (expandedDish) {
            previousScrollY.current = window.scrollY;
        }
    }, [expandedDish]);
    useEffect(() => {
        if (!hasInitSentry) {
            setHasInitSentry(true);
        }
        else {
            return;
        }
        Sentry.init({
            dsn: "https://5f59443cac7464d8eaeb16da5c512b76@o4506595082240000.ingest.sentry.io/4506595375710208",
            integrations: [
                new Sentry.BrowserTracing({
                    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                }),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }, [hasInitSentry]);
    const [hasShownAuthentication, setHasShownAuthentication] = useState(false);
    const [currentCatalogDate, setCurrentCatalogDate] = useState();
    const userRes = useCurrentUserQuery();
    const { user } = parseCurrentUser(userRes);
    const merchantRes = useCurrentMerchantQuery();
    const { merchant } = parseCurrentMerchant(merchantRes);
    const deliveryDayRes = useWeeklyDeliveryDayQuery();
    let { deliveryDay } = parseWeeklyDeliveryDay(deliveryDayRes);
    const [setRemoteCart, setRemoteCartRes] = useSetCartMutation();
    const [removeDishFromCart, removeDishFromCartRes] = useRemoveDishQuantityInCartMutation();
    const catalogDates = getCatalogDates(deliveryDay || 0);
    useEffect(() => {
        if (!deliveryDay || !catalogDates.length)
            return;
        if (!currentCatalogDate && catalogDates.length >= 2) {
            setCurrentCatalogDate(catalogDates[0]);
        }
        if (!currentCatalogDate) {
            return;
        }
        if (!hasLoadedApp &&
            userRes.called &&
            merchantRes.called &&
            !userRes.loading &&
            !merchantRes.loading &&
            currentCatalogDate) {
            setHasLoadedApp(true);
            if (user) {
                const items = user?.cartItems ?? [];
                let newCart = [];
                items.forEach((x) => {
                    if (x?.dish) {
                        newCart.push({
                            dish: x.dish,
                            quantity: x.quantity,
                            addedAt: x.updatedAt,
                            deliveryDate: currentCatalogDate,
                        });
                    }
                });
                updateCartLocal(newCart);
                posthog.identify(user.id, {
                    canMockOrders: user.canMockOrders,
                    isEmailVerified: user.isEmailVerified,
                    isOnboardingComplete: user.isOnboardingComplete,
                    isPhoneVerified: user.isPhoneVerified,
                    isSuspended: user.isSuspended,
                    smsOrderUpdates: user.smsOrderUpdates,
                    stripeSubscriptionId: user.stripeSubscriptionId,
                    stripeAccountId: user.stripeAccountId,
                    merchantId: merchant?.id,
                }, { email: user.email });
                if (merchant?.id) {
                    posthog.group("merchant", merchant?.id);
                }
            }
        }
    }, [deliveryDay, catalogDates, currentCatalogDate, hasLoadedApp, userRes, merchantRes]);
    function updateCartLocal(cart) {
        setCart(cart);
        localStorage.setItem("cart", JSON.stringify(cart));
        for (const cartItem of cart) {
            if (cartItem.dish.status !== DishStatus.Live) {
                removeDishFromCart({
                    variables: {
                        input: {
                            merchantId: cartItem.dish.merchant.id,
                            dishId: cartItem.dish.id,
                        },
                    },
                }).then((x) => {
                    window.location.reload();
                });
            }
        }
    }
    function updateCartRemoteAndLocal(cart) {
        updateCartLocal(cart);
        if (user) {
            setRemoteCart({
                variables: {
                    input: getRemoteCartPayload(cart),
                },
            });
        }
    }
    const defaultRoutes = [];
    let merchantRoutes = [];
    let consumerRoutes = [
        _jsx(Route, { path: "/home", element: _jsx(RedirectToWebflow, { replace: true }) }),
        _jsx(Route, { path: "/submit", element: _jsx(Navigate, { to: "/home", replace: true }) }),
    ];
    let legalRoutes = [
        _jsx(Route, { path: "/legal/terms", element: _jsx(Terms, {}) }),
        _jsx(Route, { path: "/legal/privacy", element: _jsx(Privacy, {}) }),
    ];
    const adminRoutes = [
        _jsx(Route, { path: "/admin/vendor/labels", element: _jsx(VendorLabelExport, {}) }),
        _jsx(Route, { path: "/admin/statistics", element: _jsx(Statistics, {}) }),
    ];
    if (!hasLoadedApp && localStorage.getItem("token") != null) {
        return (_jsx("div", { className: "w-[100vw] h-[100vh] flex flex-col items-center justify-center", children: _jsx(LoadingView, {}) }));
    }
    if (merchant) {
        if (merchant.isOutOfArea) {
            merchantRoutes = [
                _jsx(Route, { path: "/vendor/*", element: _jsx(Navigate, { to: "/vendor/out-of-area", replace: true }) }),
                _jsx(Route, { path: "/vendor/out-of-area", element: _jsx(OutOfAreaView, {}) }),
            ];
        }
        else if (!merchant.isOnboardingComplete) {
            merchantRoutes = [
                _jsx(Route, { path: "/vendor/*", element: _jsx(Navigate, { to: "/vendor/onboarding", replace: true }) }),
                _jsx(Route, { path: "/vendor/onboarding", element: _jsx(OnboardingView, { initialStep: 1, onCompletion: () => {
                            window.location.href = "/vendor";
                        } }) }),
            ];
        }
        else {
            merchantRoutes = [
                _jsx(Route, { path: "/vendor/*", element: _jsx(Navigate, { to: "/vendor/home", replace: true }) }),
                _jsx(Route, { path: "/vendor/home", element: merchant.approvalStatus === MerchantApprovalStatus.Approved ||
                        merchant.isReadyForApproval ? (_jsx(MerchantHomeView, {})) : (_jsx(PreApprovalCenter, { showUserGreeting: true })) }),
                _jsx(Route, { path: "/vendor/help", element: _jsx(PreApprovalCenter, {}) }),
                _jsx(Route, { path: "/vendor/orders/*", element: _jsx(MerchantOrdersView, {}) }),
                _jsx(Route, { path: "/vendor/menu/*", element: _jsx(MenuView, {}) }),
                _jsx(Route, { path: "/vendor/profile", element: _jsx(ProfileView, {}) }),
                _jsx(Route, { path: "/vendor/wallet", element: _jsx(MerchantWalletView, {}) }),
                _jsx(Route, { path: "/vendor/calendar", element: _jsx(CalendarView, {}) }),
                _jsx(Route, { path: "/vendor/reviews", element: _jsx(ReviewsView, {}) }),
                _jsx(Route, { path: "/vendor/congratulations", element: _jsx(CongratulationsOnDish, { name: merchant.profile?.givenName }) }),
            ];
        }
    }
    else {
        merchantRoutes = [
            _jsxs(Route, { path: "/vendor", element: _jsx(MerchantLanderView, { isAuthenticated: user != null && user != undefined }), children: [_jsx(Route, { path: "apply", element: _jsx(ApplyView, { initialUser: user ?? null, source: ApplyViewSource.LANDER, onRegister: () => {
                                window.location.href = "/vendor/home";
                            }, skipOnboarding: false, onClose: () => {
                                navigate("/vendor");
                            } }) }), _jsx(Route, { path: "login", element: _jsx(AuthenticateView, { allowClose: true, noSignUp: true, initialSource: AuthenticateViewSource.SIGN_IN, isMerchant: true, onComplete: () => {
                                window.location.href = "/vendor";
                            }, onClose: () => {
                                navigate("/vendor");
                            } }) }), _jsx(Route, { path: "private", element: _jsx(PrivateSignUp, { initialUser: user ?? null, onRegister: () => {
                                window.location.href = "/vendor/home";
                            } }) }), _jsx(Route, { path: "/vendor/*", element: _jsx(Navigate, { to: "/vendor", replace: true }) }), ","] }),
        ];
    }
    if (enableConsumer) {
        consumerRoutes = consumerRoutes.concat([
            _jsx(Route, { path: "/*", element: _jsx(Navigate, { to: "/home", replace: true }) }),
            _jsx(Route, { path: "/home/*", element: _jsx(ConsumerHomeView, {}), children: _jsx(Route, { path: "enlist", element: _jsx(EnlistView, { onEnlist: () => {
                            navigate("/catalog");
                        }, onClose: () => {
                            window.location.href = "/";
                        } }) }) }),
            _jsx(Route, { path: "/catalog/*", element: _jsx(ConsumerCatalogView, { expandedDish: expandedDish, setExpandedDish: setExpandedDish, catalogDates: catalogDates.length > 0 ? [catalogDates[0]] : [], currentCatalogDate: currentCatalogDate || new Date(), setCurrentCatalogDate: setCurrentCatalogDate, setCart: (cart) => {
                        updateCartRemoteAndLocal(cart);
                    }, hasShownAuthentication: hasShownAuthentication, setHasShownAuthentication: setHasShownAuthentication }) }),
            _jsx(Route, { path: "/merchant/:id/*", element: _jsx(MerchantView, { expandedDish: expandedDish, setExpandedDish: setExpandedDish, catalogDates: catalogDates.length > 0 ? [catalogDates[0]] : [], currentCatalogDate: currentCatalogDate || new Date(), setCurrentCatalogDate: setCurrentCatalogDate, setCart: (cart) => {
                        updateCartRemoteAndLocal(cart);
                    } }) }),
            _jsx(Route, { path: "/checkout/*", element: _jsx(CheckoutFlow, { onPlaceOrder: () => { }, setCart: (cart) => {
                        updateCartRemoteAndLocal(cart);
                    }, onFinishedPlacingOrder: () => {
                        updateCartLocal([]);
                    } }) }),
            _jsx(Route, { path: "/account/*", element: _jsx(AccountView, { setCart: setCart }) }),
            _jsx(Route, { path: "/orders/*", element: _jsx(ConsumerOrdersView, { deliveryDate: currentCatalogDate || new Date(), setCart: updateCartRemoteAndLocal }) }),
            _jsx(Route, { path: "/order/*", element: _jsx(OrderDetailView, { setCart: setCart }) }),
            _jsx(Route, { path: "/wallet/*", element: _jsx(ConsumerWalletView, { setCart: setCart }) }),
        ]);
    }
    else if (defaultToConsumerLander) {
        consumerRoutes.push(_jsx(Route, { path: "/*", element: _jsx(Navigate, { to: "/home", replace: true }) }));
    }
    else {
        consumerRoutes.push(_jsx(Route, { path: "/*", element: _jsx(Navigate, { to: "/vendor", replace: true }) }));
    }
    const allRoutes = enableConsumer
        ? [...defaultRoutes, ...consumerRoutes, ...merchantRoutes, ...legalRoutes, ...adminRoutes]
        : [...defaultRoutes, ...merchantRoutes, ...legalRoutes, ...adminRoutes];
    return (_jsx(PostHogProvider, { client: posthog, children: _jsx(LocalCartContext.Provider, { value: cart, children: _jsx(ExpandedDishContext.Provider, { value: { setExpandedDish }, children: _jsxs(Suspense, { fallback: _jsx(LoadingView, {}), children: [expandedDish && (_jsx(CatalogDishItemDetail, { dish: expandedDish, onClose: () => setExpandedDish(null), setCart: updateCartRemoteAndLocal, currentCatalogDate: currentCatalogDate })), _jsxs(Routes, { children: [allRoutes.map((route, index) => (_jsx(React.Fragment, { children: route }, index + Math.random()))), _jsx(Route, { path: "/reset", element: _jsx(ResetPassword, {}) })] })] }) }) }) }));
}
