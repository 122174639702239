import { jsx as _jsx } from "react/jsx-runtime";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import constants from "../../../lib/util/constants";
import AuthenticateButton, { AuthenticateButtonType } from "../AuthenticateButton";
export default function AuthenticateWithGoogle({ isMerchant, onAccessToken, isLoading, }) {
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: constants.apiKeys.google.GOOGLE_CLIENT_ID,
                scope: constants.apiKeys.google.GOOGLE_SCOPE,
            });
        }
        gapi.load("client:auth2", start);
    }, []);
    return (_jsx(GoogleLogin, { clientId: constants.apiKeys.google.GOOGLE_CLIENT_ID, render: (renderProps) => (_jsx(AuthenticateButton, { showSpinner: isLoading, type: AuthenticateButtonType.GOOGLE, onClick: () => {
                renderProps.onClick();
            } })), onSuccess: (data) => {
            const res = data;
            if (res && res.accessToken) {
                onAccessToken(res.accessToken);
            }
            else {
                alert("Google sign in error");
            }
        }, onFailure: (error) => {
            alert("Google sign in error");
        }, cookiePolicy: "single_host_origin" }));
}
