import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as pdfjsLib from "pdfjs-dist";
import printJS from "print-js";
import { useState } from "react";
import AdminLogin from "../../components/admin/AdminLogin";
import SingleVendor from "../../components/admin/SingleVendor";
import Button from "../../components/global/Button";
import { useAllMerchantsLazyQuery, useOrdersSummaryLazyQuery, } from "../../generated/graphql";
import { generateMultiOrderLabelPDF } from "../../lib/util/generateOrderLabelPDF";
import ButtonStyle from "../../types/ButtonStyle";
pdfjsLib.GlobalWorkerOptions.workerSrc =
    "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";
const DefaultPrinterNames = ["Inkjet", "Laser printer"];
export default function VendorLabelExport() {
    const [merchants, setMerchants] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [allMerchantsQuery, allMerchantsQueryRes] = useAllMerchantsLazyQuery({
        fetchPolicy: "network-only",
    });
    const [ordersSummaryQuery, ordersSummaryQueryRes] = useOrdersSummaryLazyQuery({
        fetchPolicy: "network-only",
    });
    const [selectedPrinter, setSelectedPrinter] = useState(DefaultPrinterNames[0]);
    const [showIrrelevantVendors, setShowIrrelevantVendors] = useState(true);
    const [maxDeliveryDate, setMaxDeliveryDate] = useState(new Date("2000-01-01").getTime());
    async function fetchOrders(merchant, operatingDeliveryDate) {
        console.log(`fetching orders for ${merchant.profile?.name} (${operatingDeliveryDate})`);
        if (!operatingDeliveryDate)
            operatingDeliveryDate = merchant.deliveryDates[0];
        if (!operatingDeliveryDate) {
            return [];
        }
        const response = await ordersSummaryQuery({
            variables: {
                input: {
                    deliveryDate: operatingDeliveryDate.toString(),
                    merchantId: merchant.id,
                },
            },
        });
        const orders = response.data?.ordersSummary?.orders;
        return orders;
    }
    const onSubmit = () => {
        setLoggedIn(true);
        allMerchantsQuery().then(async (response) => {
            const sortedMerchants = [...response.data.allMerchants.allMerchants].sort((merchant1, merchant2) => merchant1.profile.name.localeCompare(merchant2.profile.name));
            const newMerchants = [];
            for (const merchant of sortedMerchants) {
                const orders = await fetchOrders(merchant);
                newMerchants.push({
                    merchant: merchant,
                    orders: orders,
                });
            }
            setMerchants(newMerchants);
            let newMaxDeliveryDate = maxDeliveryDate;
            for (const merchant of sortedMerchants) {
                const newDate = new Date(merchant.deliveryDates[0]).getTime();
                if (newDate > newMaxDeliveryDate) {
                    newMaxDeliveryDate = newDate;
                    console.log("new max delivery date:");
                    console.log(merchant.deliveryDates[0]);
                }
            }
            setMaxDeliveryDate(newMaxDeliveryDate);
        });
    };
    const filteredMerchants = showIrrelevantVendors
        ? merchants
        : merchants.filter((x) => new Date(x.merchant.deliveryDates[0]).getTime() === maxDeliveryDate);
    async function massPrint() {
        printJS({
            printable: URL.createObjectURL(await generateMultiOrderLabelPDF(filteredMerchants)),
            type: "pdf",
        });
    }
    if (!loggedIn)
        return _jsx(AdminLogin, { onSuccess: onSubmit });
    return (_jsxs("div", { className: "flex flex-col gap-2 m-4", children: [_jsx("div", { className: "max-w-xs", children: _jsxs(Button, { style: ButtonStyle.BLACK, onClick: () => {
                        setShowIrrelevantVendors((x) => !x);
                    }, children: [!showIrrelevantVendors ? "Show" : "Hide", " irrelevant vendors"] }) }), _jsx("div", { className: "max-w-xs", children: _jsxs(Button, { style: ButtonStyle.BLACK, onClick: massPrint, children: ["Print (", showIrrelevantVendors ? "with" : "without", " irrelevant vendors)"] }) }), merchants.length === 0 && _jsx("span", { className: "font-mono text-lg", children: "Loading..." }), _jsx("div", { className: "flex flex-col gap-2", children: filteredMerchants.map((m) => {
                    return (_jsx(SingleVendor, { merchant: m, updateOrders: (date) => {
                            fetchOrders(m.merchant, date).then((orders) => {
                                if (!orders)
                                    return;
                                const newMerchants = [...merchants];
                                for (let i = 0; i < newMerchants.length; i++) {
                                    const currentMerchant = newMerchants[i];
                                    if (m.merchant.id === currentMerchant.merchant.id) {
                                        const copy = structuredClone(currentMerchant);
                                        copy.orders = orders;
                                        newMerchants[i] = copy;
                                    }
                                }
                                setMerchants(newMerchants);
                            });
                        }, selectedPrinter: selectedPrinter, showIrrelevantVendors: showIrrelevantVendors }, m.merchant.id));
                }) })] }));
}
