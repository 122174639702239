export default function addDishToCart(dish, cart, setCart, deliveryDate) {
    const matchingIdx = cart.findIndex((x) => x.dish.id == dish.id);
    if (matchingIdx > -1) {
        let newCart = [...cart];
        newCart[matchingIdx].quantity += 1;
        newCart[matchingIdx].addedAt = new Date();
        setCart(newCart);
    }
    else {
        setCart([
            {
                dish: dish,
                quantity: 1,
                addedAt: new Date(),
                deliveryDate: deliveryDate,
            },
        ].concat(cart));
    }
}
