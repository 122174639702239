import { wrapDayIndex } from "./calendar";
export function days() {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
}
export default function getCatalogDates(deliveryDay) {
    const today = new Date();
    const dayName = today.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
        weekday: "long",
    });
    const dayIndex = days().indexOf(dayName);
    let year = today.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
    });
    const yearNumber = Number(year);
    let month = today.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
        month: "numeric",
    });
    const monthIndex = Number(month) - 1;
    let date = Number(today.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
        day: "numeric",
    }));
    let upcomingCatalogDate;
    let nextCatalogDate;
    const lastPreOrderDay = wrapDayIndex(deliveryDay - 2);
    let incrementByWeek = dayIndex > lastPreOrderDay;
    upcomingCatalogDate = new Date(Date.UTC(yearNumber, monthIndex, date + deliveryDay - dayIndex + (incrementByWeek ? 7 : 0), 8, 0, 0, 0));
    nextCatalogDate = new Date(Date.UTC(yearNumber, monthIndex, date + 7 + deliveryDay - dayIndex + (incrementByWeek ? 7 : 0), 8, 0, 0, 0));
    return [upcomingCatalogDate, nextCatalogDate];
}
