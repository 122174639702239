import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
export default function Privacy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
        });
    }, []);
    return _jsx("div", { dangerouslySetInnerHTML: { __html: privacyHTML } });
}
const privacyHTML = `
<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.3.5.2 (Linux)"/>
	<meta name="created" content="00:00:00"/>
	<meta name="changed" content="00:00:00"/>
	<style type="text/css">
		@page { size: 8.5in 11in; margin-left: 0.38in; margin-right: 0.5in; margin-top: 0.5in; margin-bottom: 0.5in }
		p { line-height: 115%; margin-bottom: 0.1in; background: transparent }
		a:link { color: #000080; so-language: zxx; text-decoration: underline }
    body {
      padding: 25px;
    }
	</style>
</head>

<body lang="en-US" link="#000080" vlink="#800000" dir="ltr"><p align="center" style="line-height: 100%; margin-bottom: 0in">
  <font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="4" style="font-size: 14pt"><span style="text-decoration: none"><b>Privacy
  Policy-United States</b></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="4" style="font-size: 14pt"><span style="text-decoration: none"><b>Neighbors</b></span></font></font></font></p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Last
  modified: November 8, 2022</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>INTRODUCTION</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>Neighbors
  Technology Inc</b></span></font></font></font><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
  and any of its affiliated entities (collectively “Neighbors,”
  “We,” “Us,” or “Our”) respects your privacy and is
  committed to protecting it through our compliance with this policy.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">This
  Privacy Policy (“Policy”) describes how we collect and use the
  personal information you provide to us on Our Website,
  </span></span></font></font></font><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://neighbors.co/</span></u></font></font></font><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">,
  and other sites. We may own or administer under this domain
  (collectively, the “Website”), how we use that information,
  including with whom we share it, and your choices regarding our use
  of that information. We also describe how you can contact us with
  questions and requests pertaining to your privacy. This Policy does
  not cover data obtained offline.</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><span style="text-decoration: none">
  </span></font><br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Please
  note that the products and services offered via the Website are
  intended for purchase only by users who reside in the United States.
  If you visit Our Website from outside the United States, please note
  that the United States has data protection laws that may not be
  consistent with those of other countries and that information
  collected about you in connection with your visit to our Websites
  will be processed in accordance with United States law. Your use of
  each Website is subject to our</span></span></font></font></font><a href="https://neighbors.co/legal/terms"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal"> Terms
  and Conditions</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">.</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><span style="text-decoration: none"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="font-weight: normal">By
  using the Website, you agree to the collection and use of your
  personal information in accordance with this policy.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>COLLECTION
  AND USE OF PERSONAL AND NON-PERSONAL INFORMATION</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  collect personal and non-personal information from you to provide and
  improve our products and services. “Personal Information” means
  information that personally identifies you, such as your name, email
  address, shipping and billing address, phone number, and credit card
  number or other financial information used to pay or process payment.
  “Non-Personal Information” means information that relates to you
  but does not directly identify you such as type of technology or
  browser you are using to access our Website (mobile, desktop, tablet,
  etc.), or other information about you but that cannot readily be used
  to uniquely identify you.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  may collect the following personal information about you: </span></span></font></font></font>
  </p>
  <ul>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Contact
    Information such as your name, email address, and phone number</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Unique
    Identifiers such as user name, account information, and password</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Information
    provided to us through social media networks when you access our
    Website via said networks (this could include your name, profile
    picture, and other personal information, as well as non-personal
    information) or engage with us on social media. </span></span></font></font></font>
    </p>
  </ul>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  collect the following non-personal information about you:</span></span></font></font></font></p>
  <ul>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Information
    about your computer or device, including IP address, time and date
    of visit, browser type and version, operating system, device make
    and model</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
    pages of our Website you visit, the time spent on our Website,
    referring and exit pages</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Contact
    information of friends or other people you would like us to contact
    or connect with</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Information
    provided to us through social media networks when you access our
    Website via said networks (this could include your “likes” and
    other online engagement activity, location, friends list, and other
    information depending on the social network)</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Non-personal
    information that we ask for from you and that you provide to us in
    order to find products or services, make purchases, or improve our
    offerings.    </span></span></font></font></font>
    </p>
  </ul>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  obtain personal and non-personal information in the following ways:</span></span></font></font></font></p>
  <ul>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Directly
    from you, including via the Website, email, text, and other
    electronic messages between you and us, and information from any
    social media account you may use to connect with Us;</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Indirectly
    from you, via automated means (such as our servers logging your IP
    address and related information, and information pulled from any
    connected social media accounts); and,</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">From
    third parties and through technologies like cookies and web beacons.
    </span></span></font></font></font>
    </p>
  </ul>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>INFORMATION
  WE COLLECT BY AUTOMATED MEANS</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">When
  you visit our Website(s), view or click on our online advertisements
  (including our advertisements on third party Websites), or visit our
  social media pages, we also collect certain information about you,
  your device, your online activity, and your usage of the Website by
  automated means via third-party technologies such as cookies, web
  server logs and web beacons. For example, when you visit our Website,
  we may collect your IP address, your unique device identifier (or
  other device identifier) and/or geolocation data in order to offer
  you certain features or functionalities. As set forth in more detail
  below, we may also collect information about your usage and browsing
  habits using various web-based technologies, including cookies and
  web beacons. We also may use these technologies to collect
  information about your online activities over time and across
  third-party Websites or other online services (behavioral tracking).
  While the information we collect automatically may only be
  statistical data and may not include personal information, we may
  maintain it or associate it with personal information we collect in
  other ways or receive from third parties for any of the purposes
  expressed in this policy. </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>TECHNOLOGIES
  WE USE: COOKIES, WEB SERVER LOGS AND WEB BEACONS</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Cookies
  are small text files that Websites send to your computer or other
  Internet-connected device to uniquely identify your browser or to
  store information or settings in your browser. Your browser may tell
  you how to be notified when you receive certain types of cookies and
  how to restrict or disable certain cookies. Please note, however,
  that without cookies you may not be able to use all of the features
  of the Website.</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><span style="text-decoration: none"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="font-weight: normal">In
  conjunction with obtaining information through cookies, our web
  servers may log details such as your operating system type, browser
  type, domain, and other system settings, as well as the language your
  system uses and the country and time zone in which your device is
  located. The web server logs also may record information such as the
  address of the web page that linked you to our site and the IP
  address of the device you use to connect to the Internet.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
  control which web servers collect this information, we may place tags
  on our web pages, emails, advertisements, or content called “web
  beacons.” These are computer instructions that link web pages to
  particular web servers and their cookies. Web beacons are sometimes
  known as clear gifs, pixel tags, and single-pixel gifs and track
  engagement, visitor counts, and other statistics. </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>THIRD
  PARTY WEB ANALYTICS SERVICES</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  may use third party web analytics services on this site, or our
  social network pages, such as Google Analytics. The service providers
  that administer these services use technologies such as cookies, web
  server logs and web beacons to help us analyze how visitors use the
  site.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
  information collected through these means (including IP address) is
  disclosed to these service providers, who use the information to
  evaluate use of the Website. You may deactivate the ability of these
  analytics services to analyze your browsing activities on this site.
  These providers of third-party web analytics services have their own
  terms and conditions and privacy policies. We do not control what any
  third party providers do with information they collect.</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  also use the following cookies on the Website, though such cookies
  may or may not be active at any given time: </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Google
  AdWords: </span></span></font></font></font><a href="https://policies.google.com/technologies/ads?hl=en-US"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://policies.google.com/technologies/ads?hl=en-US</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
  </span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Facebook:
  </span></span></font></font></font><a href="https://www.facebook.com/policies/cookies"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://www.facebook.com/policies/cookies</span></u></font></font></font></a></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Next
  Door
  </span></span></font></font></font><a href="https://help.nextdoor.com/s/article/Personalized-Ads-and-Privacy-Controls?language=en_US"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://help.nextdoor.com/s/article/Personalized-Ads-and-Privacy-Controls?language=en_US</span></u></font></font></font></a></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">TikTok:
  </span></span></font></font></font><a href="https://www.tiktok.com/legal/privacy-policy-us?lang=en"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://www.tiktok.com/legal/privacy-policy-us?lang=en</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
  </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">The
  above may not be an exhaustive list of cookies/cookie providers. You
  can restrict and/or delete cookies through your web browser’s
  settings, however, disabling or removing cookies may impact the
  functionality or features of the Website. </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>TARGETED
  ADVERTISING</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  also may contract with third-party advertising networks that collect
  information about you through the use of cookies, web server logs and
  web beacons on our Websites and emails; on third-party Websites and
  emails; and on our advertising placed on third-party Websites. They
  use this information to provide advertisements about products and
  services tailored to your interests (including for companies not
  affiliated with us). You may see these advertisements on our Website
  and other Websites or on social media. This process also helps us
  manage and track the effectiveness of our marketing efforts. To learn
  more about advertising networks and to opt out of interest-based
  advertising, visit the Digital Advertising Alliance
  at&nbsp;</span></span></font></font></font><a href="http://www.aboutads.info/choices"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">www.aboutads.info/choices</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">&nbsp;or
  the Network Advertising Initiative
  at&nbsp;</span></span></font></font></font><a href="http://www.networkadvertising.org/choices"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">www.networkadvertising.org/choices</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">.
  </span></span></font></font></font><font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Depending
  on your permissions, we may receive your Personal Information from
  your Internet service and mobile device providers. Users of mobile
  devices who do not want to receive interest-based advertising may
  opt-out in several ways. Learn more about your choices for mobile
  devices by visiting&nbsp;</span></span></font></font></font><a href="http://www.aboutads.info/appchoices"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">http://www.aboutads.info/appchoices</span></u></font></font></font></a><font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">.
  Each operating system, (iOS for Apple phones, Android for Android
  devices, and Windows for Microsoft devices) provides its own
  instructions on how to prevent the delivery of tailored
  in-application advertisements. You should view your device or system
  “Settings” to determine how you can opt out of use of your device
  ID for “cross-app” personalized advertising.</span></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>DO
  NOT TRACK REQUESTS</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">There
  is no industry standard approach to sending, processing, and
  addressing Do Not Track requests from browsers, and please note that
  our Website does not respond to these Do Not Track requests at this
  time. More information on “Do Not Track” requests is available
  here: </span></span></font></font></font><a href="https://allaboutdnt.com/"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">https://allaboutdnt.com/</span></u></font></font></font></a></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>HOW
  WE USE AND SHARE INFORMATION</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  use the information we obtain to provide you with the Website; our
  products and services; identify and communicate with you (including
  marketing and promotional communication) through various channels and
  mediums, including when you return to the Website; respond to your
  requests and inquiries; notify you about the Website our services,
  including your order or your account; service your purchase orders;
  and monitor, analyze, and improve the Website and our products and
  services. We also use the information to provide a personalized
  experience on the Website, and tailor ads displayed to you on our
  Website and elsewhere. We use information to operate, evaluate, and
  improve the Website and our business, and to comply with legal
  requirements, relevant industry standards, our policies, and
  enforcing this policy and our Terms of Service. We may use your
  personal information if we need to collect a debt from you, as well.
  If we use your information in other ways, we will provide notice to
  you at the time we collect that information. </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  may disclose anonymized, pseudoanonymized, and/or aggregated
  information about our users or visitors to the Website, and
  information that does not directly and personally identify any
  individual.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  may disclose Personal Information that we collect or you provide as
  described in this Policy:</span></span></font></font></font></p>
  <ul>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Within
    our Company and to our subsidiaries and affiliates.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    contractors, service providers, and other third parties we use to
    support our business and who are bound by contractual obligations to
    keep personal information confidential and use it only for the
    purposes for which we disclose it to them. This includes for
    purposes of receiving and processing your financial information once
    or on a recurring or subscription basis for payment of goods or
    services. </span></span></font></font></font>
    </p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    communicate with you.    For example, we may send you text
    messages or other notifications about the services provided or the
    goods provided.    We may also contact you with promotional
    offerings or other communications that may be of interest to you. If
    we send you marketing emails or texts about services that may
    interest you, each email or text will contain instructions
    permitting you to “opt-out” of receiving future email or text
    marketing communications.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">For
    our everyday business operations such as auditing, administration of
    the services, forum management, fulfillment, and analytics. Your
    information may also be used to improve the content and/or
    functionality of the Services.&nbsp;</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    a buyer or other successor in the event of a merger, divestiture,
    restructuring, reorganization, dissolution, or other sale or
    transfer of some or all of our business assets, whether as a going
    concern or as part of bankruptcy, liquidation, or similar
    proceeding, in which personal information held by Us about our
    Website users is among the assets transferred.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    fulfill the purpose for which you provide it.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    comply with certain laws and regulations. Our services may be
    subject to certain laws and regulations which may require us to
    process your Personal Information. For example, we process your
    Personal Information to pay our taxes, to fulfill our business
    obligations, ensure compliance with employment and recruitment laws
    or as necessary to manage risk as required under applicable law.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    personalize your experience. For example, we may personalize the
    content and features you see when visiting our Services. In
    addition, we may personalize advertisements, marketing
    communications, and recommendations to make them more tailored to
    your interests.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
    protect you, others, and our business, including, without
    limitation, using information for fraud prevention, for enforcement
    of our Terms of Service, to comply with the law, and to protect
    against security risks.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">For
    any other purpose disclosed by us when you provide the information.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">With
    your consent.</span></span></font></font></font></p>
    <li><p align="justify" style="line-height: 100%; margin-bottom: 0in">
    <font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
    we believe disclosure is necessary or appropriate to protect the
    rights, property, or safety of Us, our customers, or others. This
    includes exchanging information with other companies and
    organizations for the purposes of fraud protection and credit risk
    reduction.</span></span></font></font></font></p>
  </ul>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-left: 0.5in; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">If
  you do not wish to have your email address/contact information used
  by Us to promote our own or third parties' products or services, you
  can opt-out by checking the relevant box located on the form on which
  we collect your data or by clicking the “Unsubscribe” link in any
  email we send you. If we have sent you a promotional email, you may
  send us a return email asking to be omitted from future email
  distributions. This opt out does not apply to information provided to
  Us as a result of a product purchase, warranty registration, product
  service experience or other transactions. Also, you may still receive
  certain operational emails regarding your account, orders, or other
  non-promotional materials. </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">California
  residents may have additional personal information rights and
  choices. Please know we do not sell your personal information. </span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>TRANSFER
  OF PERSONAL INFORMATION TO OTHER JURISDICTIONS</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">This
  Website is intended for use in the United States of America, and your
  personal information may be transferred and proceed there as part of
  our normal business operations. Data protection laws vary from
  country to country, and may be different from the country from where
  you access the Website. By using our Website or services, you consent
  to the transfer and processing of personal information by Us, our
  related entities, and our third party service providers who perform
  services on our behalf based on our instructions.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>ACCESSING
  AND CORRECTING YOUR INFORMATION</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
  can review and change your personal information by logging into the
  Website and visiting your account page. You may also send us an email
  at chris@neighbors.co to request access to, correct or delete any
  personal information that you have provided to us. We may not be able
  to delete your personal information except by also deleting your user
  account. We may not accommodate a request to change information if we
  believe the change would violate any law or legal requirement or
  cause the information to be incorrect. If we cannot verify that the
  request for information, or to change or delete information, can from
  you and you are authorized to make such a request, we may not be able
  to comply with your request.    </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
  may also contact us by mail at the address at the bottom of this
  Privacy Policy. </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>HOW
  WE PROTECT INFORMATION WE COLLECT</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  build security into our system to help protect data from unauthorized
  access, disclosure, alteration, and destruction. We’re always
  looking for threats and take reasonable steps to respond to those
  threats, protecting the information users share with us.  Of
  course, no method of Internet transmission or data storage can
  provide guaranteed protection to your information.  If anything
  should ever happen to your information, we’ll let you know as soon
  as we can and try our best to make it right.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Please
  recognize that protecting your Personal Information is also your
  responsibility. We urge you to take every precaution to protect your
  information when you are on the Internet, or when you communicate
  with us and with others through the Internet. Change your passwords
  often, use a combination of letters and numbers, and make sure you
  use a secure browser. If you have reason to believe that your
  interaction with us is no longer secure (for example, if you feel
  that the security of your account might have been compromised), or if
  you suspect someone else is using your account, please let us know
  immediately. </span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>CHILDREN’S
  PRIVACY</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">This
  Website is not directed at anyone under the age of eighteen (or age
  of majority in your jurisdiction) and we do not knowingly collect
  personal information from minors. If we become aware that we have
  inadvertently received personal information from a minor visitor on
  the site, we will delete the information from our records. Any
  visitors to the site who are under 16 must have parental or guardian
  consent. You may request to review, change or delete your personal
  information or that of your minor child. To make such a request,
  please email chris@neighbors.co.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>LINKS
  TO OTHER SITES</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Our
  Website may provide links to other Websites for your convenience and
  information. These Websites may operate independently from us. Linked
  sites may have their own privacy notices or policies, which we
  strongly suggest you review if you visit any linked Websites. To the
  extent any linked Websites you visit are not owned or controlled by
  us, we are not responsible for the sites’ content, any use of the
  sites, or the privacy practices of the sites.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>SOCIAL
  MEDIA USAGE</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">You
  may choose to enable or log in to our services via various online
  services, including social networking services like Facebook and
  Google. Our services also may enable you to access social networking
  services such as Facebook, Twitter, or Instagram (collectively,
  “Social Network”) directly or indirectly through our services.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#131313"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">When
  you link a Social Network account to Us or log into Our services
  using your Social Network account, We may collect relevant Personal
  Information necessary to enable Our services to access that Social
  Network and your information contained within that Social Network. We
  also may share your information with the operator of that Social
  Network to facilitate or enhance the services of that Social Network
  to you. A Social Network may provide Us with access to certain
  information that you have provided to them, and We will use, store,
  and disclose such information in accordance with this Policy.
  Additionally, a Social Network may collect Personal Information and
  information about your use of Our site or services automatically. The
  manner in which a Social Network collects, uses, stores, and
  discloses your information is governed by the policies of such third
  parties and We shall have no liability or responsibility for the
  privacy practices or other actions of any Social Network that may be
  enabled within our Services.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>CHANGES
  TO OUR PRIVACY POLICY</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">We
  reserve the right to make changes to this policy at any time, so
  please review it frequently. It is our policy to post any changes we
  make to our privacy policy on this page. Changes to this policy will
  take effect immediately unless otherwise noted. If we make material
  changes to how we treat your personal information, we will notify you
  by email to the email we have on file. The date the privacy policy
  was last revised is identified at the top of the page. You are
  responsible for ensuring we have an up-to-date, active, and
  deliverable email address for you, and for periodically visiting our
  Website and this privacy policy to check for any changes. Your
  continued use of the Website after being notified of, or becoming
  aware of, a chance in this policy, is your affirmation that you have
  read and understood this policy, agree to it, and agree to be bound
  by it.</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPS-BoldMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><b>CONTACT
  INFORMATION</b></span></font></font></font></p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">To
  ask questions or comment about this privacy policy and our privacy
  practices, please email </span></span></font></font></font><a href="mailto:chris@neighbors.co"><font color="#0000ff"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><u><span style="font-weight: normal">chris@neighbors.co</span></u></font></font></font></a><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">
  to be removed from our mailing list(s). You may also reach us by mail
  at: </span></span></font></font></font>
  </p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">Neighbors
  </span></span></font></font></font>
  </p>
  <p align="justify" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="TimesNewRomanPSMT, serif"><font size="3" style="font-size: 12pt"><span style="text-decoration: none"><span style="font-weight: normal">November
  26, 2022</span></span></font></font></font></p>
  <p align="justify" style="font-weight: normal; line-height: 100%; margin-bottom: 0in; text-decoration: none">
  <br/>
  
  </p>
  <p style="line-height: 115%; margin-bottom: 0.14in"><br/>
  <br/>
  
  </p>
  <p align="center" style="line-height: 100%; margin-bottom: 0in; text-decoration: none; page-break-before: always">
  <br/>
</body>
</html>`;
