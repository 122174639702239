export default function setDishQuantityInCart(quantity, dish, cart, setCart, deliveryDate) {
    const matchingIdx = cart.findIndex((x) => x.dish.id == dish.id);
    if (matchingIdx > -1) {
        let newCart = [...cart];
        newCart[matchingIdx].quantity = quantity;
        newCart[matchingIdx].addedAt = new Date();
        if (newCart[matchingIdx].quantity <= 0) {
            newCart.splice(matchingIdx, 1);
        }
        setCart(newCart);
    }
    else if (quantity > 0) {
        setCart([
            {
                dish: dish,
                quantity: quantity,
                addedAt: new Date(),
                deliveryDate: deliveryDate,
            },
        ].concat(cart));
    }
}
