import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { CURRENT_MERCHANT, CURRENT_USER } from "../../../api";
import { parseAuthenticateUser, parseRegisterUser } from "../../../api/parse";
import CloseButton from "../../../assets/icons/cross-gray.svg";
import { useAuthenticateUserMutation, useRegisterUserMutation, useRequestPasswordResetMutation, useSetCartMutation, } from "../../../generated/graphql";
import getRemoteCartPayload from "../../../lib/util/cart/getRemoteCartPayload";
import { getEmailCookie } from "../../../lib/util/emailCookieUtils";
import isValidEmail from "../../../lib/util/validation/isValidEmail";
import ButtonStyle from "../../../types/ButtonStyle";
import ElementSize from "../../../types/ElementSize";
import AuthenticateWithApple from "../../global/AuthenticateWithApple";
import AuthenticateWithFacebook from "../../global/AuthenticateWithFacebook";
import AuthenticateWithGoogle from "../../global/AuthenticateWithGoogle";
import Button from "../../global/Button";
import DualInputContainer from "../../global/DualInputContainer";
import Input from "../../global/Input";
import FormWrapper from "../../merchant/DishDetailView/FormWrapper";
import { LocalCartContext } from "../../../contexts";
export var AuthenticateViewSource;
(function (AuthenticateViewSource) {
    AuthenticateViewSource["SIGN_UP"] = "Sign up";
    AuthenticateViewSource["SIGN_IN"] = "Sign in";
    AuthenticateViewSource["RESET"] = "Reset Password";
})(AuthenticateViewSource || (AuthenticateViewSource = {}));
function ThirdPartyButtonStack({ isMerchant, onInput, isFacebookLoading, isGoogleLoading, isAppleLoading, }) {
    return (_jsxs("div", { className: "px-[32px] md:px-0 flex flex-col gap-[20px] items-stretch", children: [_jsx(AuthenticateWithGoogle, { onAccessToken: (googleToken) => {
                    onInput({
                        input: { googleToken: googleToken, isMerchant: isMerchant },
                    });
                }, isMerchant: isMerchant, isLoading: isGoogleLoading }), _jsx(AuthenticateWithFacebook, { isMerchant: isMerchant, onAccessToken: (fbToken) => {
                    onInput({ input: { fbToken: fbToken, isMerchant: isMerchant } });
                }, isLoading: isFacebookLoading }), _jsx(AuthenticateWithApple, { isMerchant: isMerchant, onAuthorizationPayload: (payload) => {
                    onInput({ input: { ...payload, isMerchant: isMerchant } });
                }, isLoading: isAppleLoading })] }));
}
function Separator() {
    return (_jsxs("div", { className: "px-[32px] md:px-0 flex flex-row items-center justify-items-stretch gap-[15px]", children: [_jsx("div", { className: "h-[1px] bg-[#999999] flex-grow" }), _jsx("div", { className: "text-[#999999] text-[14px] font-bold", children: "or" }), _jsx("div", { className: "h-[1px] bg-[#999999] flex-grow" })] }));
}
function SwitchSourceAffordance({ source, onClick, }) {
    return (_jsxs("div", { className: "px-[32px] md:px-0", children: [source == AuthenticateViewSource.SIGN_UP && (_jsxs("span", { className: "text-[14px] font-medium text-[#999999]", children: ["Already have an account?", " ", _jsx("span", { className: "text-black cursor-pointer", onClick: onClick, children: "Sign in" })] })), source == AuthenticateViewSource.SIGN_IN && (_jsx("div", { className: "flex flex-col gap-[6px]", children: _jsxs("span", { className: "text-[14px] font-medium text-[#999999]", children: ["Don't have an account?", " ", _jsx("a", { href: "/quiz", className: "text-black cursor-pointer", children: "Sign up" })] }) }))] }));
}
export default function Authenticate({ initialSource, onClose, isMerchant, showClose, noSignUp, onComplete, inCheckout, }) {
    const [source, setSource] = useState(noSignUp ? AuthenticateViewSource.SIGN_IN : initialSource);
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });
    const [forgotPasssordEmail, setForgotPasswordEmail] = useState("");
    const [registerUser, registerUserRes] = useRegisterUserMutation();
    const [authenticateUser, authenticateUserRes] = useAuthenticateUserMutation();
    const [setRemoteCart, setRemoteCartRes] = useSetCartMutation();
    const [cartToSave, setCartToSave] = useState([]);
    const [isFacebookLoading, setIsFacebookLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isAppleLoading, setIsAppleLoading] = useState(false);
    const [requestPasswordReset, requestPasswordResetRes] = useRequestPasswordResetMutation();
    useEffect(() => {
        const emailFromCookie = getEmailCookie();
        setForm((prevForm) => ({ ...prevForm, email: emailFromCookie || "" }));
    }, []);
    useEffect(() => {
        if (isFacebookLoading || isAppleLoading || isGoogleLoading) {
            setIsFacebookLoading(false);
            setIsGoogleLoading(false);
            setIsAppleLoading(false);
        }
    }, [authenticateUserRes.data, authenticateUserRes.error]);
    const { user: authenticatedUser } = parseAuthenticateUser(authenticateUserRes);
    const { user: registeredUser } = parseRegisterUser(registerUserRes);
    const cart = useContext(LocalCartContext);
    if (!isMerchant && cart.length && !cartToSave.length) {
        setCartToSave(cart);
    }
    useEffect(() => {
        if (authenticateUserRes.error || registerUserRes.error) {
            alert(authenticateUserRes.error?.message ?? registerUserRes.error?.message);
        }
    }, [authenticateUserRes.error, registerUserRes.error]);
    if (!isMerchant && (authenticatedUser || registeredUser)) {
        if (cartToSave.length) {
            setRemoteCart({
                variables: {
                    input: getRemoteCartPayload(cartToSave),
                },
            }).then((res) => {
                onComplete();
            });
        }
        else {
            onComplete();
        }
    }
    else if (onComplete && (authenticateUserRes.data || registerUserRes.data)) {
        onComplete();
    }
    if (source == AuthenticateViewSource.RESET) {
        return (_jsxs("div", { className: "md:max-w-[746px] flex flex-col items-stretch pt-[32px] pb-[25px]", children: [_jsxs("div", { className: "px-[32px] md:px-0 pb-[12px] flex flex-row justify-between", children: [_jsx("span", { className: "font-bold text-[20px] text-black", children: "Reset password" }), showClose && _jsx(CloseButton, { className: "cursor-pointer z-[10]", onClick: onClose })] }), _jsx("div", { children: _jsx(FormWrapper, { py: 0, mobilePy: 0, secondaryTitle: "We will send a link to reset your password to the email address associated with your account.", children: _jsxs("div", { className: "flex flex-col gap-[20px] w-full", children: [_jsx(Input, { placeholder: "john.smith@gmail.com", required: true, onInput: (e) => {
                                        setForgotPasswordEmail(e.target.value);
                                    }, label: "Email address", value: forgotPasssordEmail, type: "email", showRequiredError: false }), _jsx(Button, { isEnabled: isValidEmail(forgotPasssordEmail) ?? false, size: ElementSize.L, to: "", style: ButtonStyle.BLACK, noBorder: true, showSpinner: requestPasswordResetRes.loading, onClick: () => {
                                        requestPasswordReset({
                                            variables: {
                                                input: {
                                                    email: forgotPasssordEmail,
                                                },
                                            },
                                        }).then((res) => {
                                            if (res.data?.requestPasswordReset?.status == "OK" &&
                                                (res.errors?.length ?? 0) == 0) {
                                                alert("If you have an account with us, please look out for an email in your inbox with instructions to reset your password.");
                                                setForgotPasswordEmail("");
                                                window.location.reload();
                                            }
                                            else {
                                                alert("There was an error. Please try again later.");
                                            }
                                            onComplete();
                                        });
                                    }, children: "Send link" })] }) }) })] }));
    }
    let buttonCopy = "";
    if (source == AuthenticateViewSource.SIGN_UP) {
        buttonCopy = "Sign up";
    }
    else {
        buttonCopy = "Sign in";
    }
    if (inCheckout) {
        buttonCopy = buttonCopy + " to checkout";
    }
    const submitForm = (event) => {
        event.preventDefault();
        if (source == AuthenticateViewSource.SIGN_UP) {
            registerUser({
                variables: {
                    input: {
                        givenName: form.firstName,
                        familyName: form.lastName,
                        email: form.email,
                        password: form.password,
                        isMerchant: isMerchant,
                    },
                },
                refetchQueries: [{ query: CURRENT_MERCHANT }],
            });
        }
        else {
            authenticateUser({
                variables: {
                    input: {
                        email: form.email,
                        password: form.password,
                        clearCart: !isMerchant && cart.length > 0,
                    },
                },
                refetchQueries: [{ query: CURRENT_USER }, { query: CURRENT_MERCHANT }],
            });
        }
    };
    return (_jsxs("div", { className: "max-w-[746px] flex flex-col items-stretch pt-[32px] pb-[25px]", children: [_jsxs("div", { className: "px-[32px] md:px-0 pb-[30px] flex flex-row justify-between", children: [_jsx("span", { className: "font-bold text-[20px] text-black", children: buttonCopy }), showClose && _jsx(CloseButton, { className: "cursor-pointer z-[10]", onClick: onClose })] }), _jsx("div", { className: "pb-[27px]", children: _jsx(ThirdPartyButtonStack, { onInput: (input) => {
                        authenticateUser({
                            variables: input,
                            refetchQueries: [{ query: CURRENT_USER }, { query: CURRENT_MERCHANT }],
                        });
                        if (input.input?.appleToken) {
                            setIsAppleLoading(true);
                        }
                        else if (input.input?.fbToken) {
                            setIsFacebookLoading(true);
                        }
                        else if (input.input?.googleToken) {
                            setIsGoogleLoading(true);
                        }
                    }, isFacebookLoading: isFacebookLoading, isGoogleLoading: isGoogleLoading, isAppleLoading: isAppleLoading, isMerchant: isMerchant }) }), _jsx(Separator, {}), _jsx("div", { children: _jsx(FormWrapper, { onSubmit: submitForm, py: 0, mobilePy: 0, children: _jsxs("div", { className: "flex flex-col gap-[20px] w-full", children: [source == AuthenticateViewSource.SIGN_UP && (_jsxs(DualInputContainer, { retainOnMobile: true, children: [_jsx(Input, { placeholder: "John", required: true, onInput: (e) => {
                                            setForm((f) => ({
                                                ...f,
                                                firstName: e.target.value,
                                            }));
                                        }, label: "First name", value: form.firstName, showRequiredError: false }), _jsx(Input, { placeholder: "Smith", required: true, onInput: (e) => {
                                            setForm((f) => ({
                                                ...f,
                                                lastName: e.target.value,
                                            }));
                                        }, label: "Last name", value: form.lastName, showRequiredError: false })] })), _jsx(Input, { placeholder: "john.smith@gmail.com", required: true, onInput: (e) => {
                                    setForm((f) => ({
                                        ...f,
                                        email: e.target.value,
                                    }));
                                }, label: "Email", value: form.email, type: "email", showRequiredError: false }), _jsxs("div", { className: "flex flex-col items-stretch gap-[6px]", children: [_jsx(Input, { placeholder: "**********", required: true, onInput: (e) => {
                                            setForm((f) => ({
                                                ...f,
                                                password: e.target.value,
                                            }));
                                        }, label: "Password", value: form.password, type: "password", showRequiredError: false }), _jsx("span", { className: "cursor-pointer text-[14px] mt-1 font-medium text-[#999999]", onClick: () => {
                                            setSource(AuthenticateViewSource.RESET);
                                        }, children: "Forgot password?" })] }), _jsx(Button, { size: ElementSize.L, to: "", style: ButtonStyle.BLACK, noBorder: true, showSpinner: registerUserRes.loading || authenticateUserRes.loading || setRemoteCartRes.loading, children: buttonCopy })] }) }) }), !noSignUp && (_jsx("div", { className: "pt-[18px] md:pt-[21px]", children: _jsx(SwitchSourceAffordance, { source: source, onClick: () => {
                        if (source == AuthenticateViewSource.SIGN_UP) {
                            setSource(AuthenticateViewSource.SIGN_IN);
                        }
                        else {
                            setSource(AuthenticateViewSource.SIGN_UP);
                        }
                    } }) }))] }));
}
